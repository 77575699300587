import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoOptionSets$DropdownsApi from '../apis/XanoOptionSets$DropdownsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const AddNewInstitutionScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [ID_state, setID_state] = React.useState(0);
  const [INPUT_City, setINPUT_City] = React.useState('');
  const [INPUT_InstitutionNname, setINPUT_InstitutionNname] =
    React.useState('');
  const [INPUT_InstitutionState, setINPUT_InstitutionState] =
    React.useState('');
  const [INPUT_Location, setINPUT_Location] = React.useState('');
  const [INPUT_addLocation, setINPUT_addLocation] = React.useState('');
  const [INPUT_instituionName, setINPUT_instituionName] = React.useState('');
  const [PLACEHOLDER_FindLocation, setPLACEHOLDER_FindLocation] =
    React.useState('Find your Location');
  const [PLACEHOLDER_InstitutionName, setPLACEHOLDER_InstitutionName] =
    React.useState('Enter your Institution Name');
  const [isEditingLocation, setIsEditingLocation] = React.useState(false);
  const [searchLocationValue, setSearchLocationValue] = React.useState('');
  const [show_addInstitution, setShow_addInstitution] = React.useState(false);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* institution */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['Strong Inverse'],
            flex: 1,
            minHeight: 500,
            position: 'absolute',
            width: '100%',
            zIndex: 9999,
          },
          dimensions.width
        )}
      >
        {/* back navigation */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          {/* Back */}
          <IconButton
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
            size={32}
            icon={'AntDesign/arrowleft'}
          />
        </View>
        {/* HEADING Add Your Institution */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['h2'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['h2'].style, {
              marginLeft: 20,
              marginTop: 10,
            }),
            dimensions.width
          )}
        >
          {'Add Your Institution'}
        </Text>
        {/* Institution Input Block */}
        <View
          style={StyleSheet.applyWidth(
            { marginLeft: 20, marginRight: 20, marginTop: 20 },
            dimensions.width
          )}
        >
          {/* LABEL Add Institution Name */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['h3'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['h3'].style,
              dimensions.width
            )}
          >
            {'Institution Name'}
          </Text>
          {/* input_addInstitutionName */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newInputAddInstitutionNameValue => {
              try {
                setINPUT_instituionName(newInputAddInstitutionNameValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            {...GlobalStyles.TextInputStyles(theme)['InputField'].props}
            placeholder={PLACEHOLDER_InstitutionName.toString()}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextInputStyles(theme)['InputField'].style,
              dimensions.width
            )}
            value={INPUT_instituionName}
          />
          {/* Location */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 8 }, dimensions.width)}
          >
            {/* Location Add Block */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
                dimensions.width
              )}
            >
              {/* LABEL Location */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['label'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['label'].style,
                  dimensions.width
                )}
              >
                {'Location*'}
              </Text>

              <Pressable
                onPress={() => {
                  try {
                    navigation.navigate('AddNewLocationScreen', {
                      newCompany: null,
                      newLocation: searchLocationValue,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Add Location */}
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['icon_secondary'].props}
                  name={'AntDesign/plus'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['icon_secondary'].style,
                    dimensions.width
                  )}
                />
              </Pressable>
            </View>
            {/* Find Location */}
            <View>
              {/* Input Location */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newInputLocationValue => {
                  try {
                    const valuewa3DkLjQ = newInputLocationValue;
                    setSearchLocationValue(valuewa3DkLjQ);
                    const newLocationResult = valuewa3DkLjQ;
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onChangeTextDelayed={newInputLocationValue => {
                  try {
                    if (newInputLocationValue?.length > 0) {
                    } else {
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                onFocus={() => {
                  try {
                    setIsEditingLocation(true);
                    setSearchLocationValue('');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['InputField'].props}
                placeholder={PLACEHOLDER_FindLocation.toString()}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['InputField'].style,
                    { borderRadius: 18 }
                  ),
                  dimensions.width
                )}
                value={searchLocationValue}
              />
              <>
                {!(searchLocationValue?.length && isEditingLocation) ? null : (
                  <XanoOptionSets$DropdownsApi.FetchDropDownLocationGET
                    handlers={{
                      onData: fetchData => {
                        try {
                          console.log();
                        } catch (err) {
                          console.error(err);
                        }
                      },
                    }}
                    search={searchLocationValue}
                  >
                    {({ loading, error, data, refetchDropDownLocation }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {/* MESSAGE Add Your Location */}
                          <>
                            {fetchData?.dd_location?.length ? null : (
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {
                                  'You will need to add your location, click on +'
                                }
                              </Text>
                            )}
                          </>
                          <>
                            {INPUT_Location ? null : (
                              <SimpleStyleFlatList
                                data={fetchData?.dd_location}
                                horizontal={false}
                                inverted={false}
                                keyExtractor={(listData, index) =>
                                  listData?.id ??
                                  listData?.uuid ??
                                  index.toString()
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={'aSZfqSFe'}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listData = item;
                                  return (
                                    <Pressable
                                      onPress={() => {
                                        try {
                                          setSearchLocationValue(
                                            listData?.city
                                          );
                                          setID_state(listData?.id);
                                          setIsEditingLocation(false);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      {/* Location to Select */}
                                      <>
                                        {!listData?.city ? null : (
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.city}
                                            {', '}
                                            {listData?._os_states_usa?.state}
                                          </Text>
                                        )}
                                      </>
                                    </Pressable>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                              />
                            )}
                          </>
                        </>
                      );
                    }}
                  </XanoOptionSets$DropdownsApi.FetchDropDownLocationGET>
                )}
              </>
            </View>
          </View>
        </View>
        {/* BUTTON _SAVE INSTITUTION */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              margin: 20,
            },
            dimensions.width
          )}
        >
          {/* Button_Save */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  const newInstitutionResult = (
                    await XanoOptionSets$DropdownsApi.addANewInstitutionPOST(
                      Constants,
                      {
                        city: INPUT_City,
                        instituon_name: INPUT_instituionName,
                        state_id: ID_state,
                      }
                    )
                  )?.json;
                  navigation.navigate('JobzStoryEducationAddScreen', {
                    ID_institution: newInstitutionResult,
                  });
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button_Secondary'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['Button_Secondary'].style,
                { fontFamily: 'Poppins_700Bold', height: null, maxWidth: 60 }
              ),
              dimensions.width
            )}
            title={'Save'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AddNewInstitutionScreen);
