import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XANOJobzR8terApi from '../apis/XANOJobzR8terApi.js';
import BackNavigationBlock from '../components/BackNavigationBlock';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  StarRating,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const JobzR8terRecruiterScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [show_findRecruiter, setShow_findRecruiter] = React.useState(false);
  const [show_question, setShow_question] = React.useState(false);
  const [show_screenContainer, setShow_screenContainer] = React.useState(true);
  const [starRatingValue, setStarRatingValue] = React.useState(0);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'SELECTED',
        value: '',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <BackNavigationBlock />
      {/* View  */}
      <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
        {/* SCREEN CONTAINER */}
        <>
          {!(show_screenContainer === true) ? null : (
            <View
              style={StyleSheet.applyWidth(
                { justifyContent: 'space-between' },
                dimensions.width
              )}
            >
              {/* Heading */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                    marginLeft: 20,
                    marginRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* jobR8ter */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['h2'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['h2'].style,
                    dimensions.width
                  )}
                >
                  {'jobzR8ter'}
                </Text>
                {/* Search Ratings */}
                <IconButton
                  onPress={() => {
                    try {
                      setShow_findRecruiter(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  icon={'MaterialCommunityIcons/book-search-outline'}
                />
              </View>
              {/* RATING CONTAINER */}
              <View
                style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
              >
                {/* Heading */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginLeft: 20, marginRight: 20 },
                    dimensions.width
                  )}
                >
                  {/* Description */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['h2'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['h2'].style,
                      dimensions.width
                    )}
                  >
                    {'Ratings by Jobseekers'}
                  </Text>
                </View>

                <SimpleStyleScrollView
                  bounces={true}
                  horizontal={false}
                  keyboardShouldPersistTaps={'never'}
                  nestedScrollEnabled={false}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  style={StyleSheet.applyWidth(
                    { maxHeight: 630, minHeight: 630 },
                    dimensions.width
                  )}
                >
                  {/* ALL RATINGS */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginTop: 10 },
                      dimensions.width
                    )}
                  >
                    <XANOJobzR8terApi.FetchGetRatingsForLoggedInRecruiterGET
                      userId={30}
                    >
                      {({
                        loading,
                        error,
                        data,
                        refetchGetRatingsForLoggedInRecruiter,
                      }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <SimpleStyleFlatList
                            data={fetchData}
                            horizontal={false}
                            inverted={false}
                            keyExtractor={(listData, index) =>
                              listData?.id ?? listData?.uuid ?? index.toString()
                            }
                            keyboardShouldPersistTaps={'never'}
                            listKey={'MajdU3rD'}
                            nestedScrollEnabled={false}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item, index }) => {
                              const listData = item;
                              return (
                                <>
                                  {/* LIST CONTAINER */}
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'outline_block'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'outline_block'
                                        ].style,
                                        {
                                          alignItems: 'flex-start',
                                          flexDirection: 'column',
                                          marginBottom: 10,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {/* Star Rating */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          alignSelf: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <StarRating
                                        activeColor={theme.colors.primary}
                                        inactiveColor={theme.colors.divider}
                                        isEditable={true}
                                        maxStars={5}
                                        starSize={16}
                                        defaultValue={listData?.combined_score}
                                      />
                                    </View>
                                    {/* Rating Body */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          alignSelf: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Words to Describe */}
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            { marginTop: 10 }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Words used to describe you . . .'}
                                      </Text>
                                      {/* Qualities */}
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)['h3']
                                          .props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)['h3']
                                              .style,
                                            { marginBottom: 10, marginTop: -10 }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {null}
                                        {'\nDependable, Flexible, Honest'}
                                      </Text>
                                      {/* While you worked */}
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Rating while you were working at:'}
                                      </Text>
                                      {/* Company */}
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)['h2']
                                          .props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['h2']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {listData?._company?.company_name}
                                      </Text>
                                      {/* Rated By */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexDirection: 'row' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Rated By */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Rated by:  '}
                                          {null}
                                        </Text>
                                        {/* Jobseeker */}
                                        <>
                                          {listData?.anonymous ? null : (
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                listData?._userJobseeker
                                                  ?.first_name
                                              }
                                            </Text>
                                          )}
                                        </>
                                        {/* Anonymous */}
                                        <>
                                          {!listData?.anonymous ? null : (
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Anonymous'}
                                            </Text>
                                          )}
                                        </>
                                      </View>
                                    </View>
                                    {/* Question */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { alignSelf: 'center' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Button Question */}
                                      <Button
                                        iconPosition={'left'}
                                        onPress={() => {
                                          try {
                                            setShow_question(true);
                                            setShow_screenContainer(false);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        {...GlobalStyles.ButtonStyles(theme)[
                                          'button_CLEAR'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.ButtonStyles(theme)[
                                            'button_CLEAR'
                                          ].style,
                                          dimensions.width
                                        )}
                                        title={'Question?'}
                                      />
                                    </View>
                                  </View>
                                </>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                          />
                        );
                      }}
                    </XANOJobzR8terApi.FetchGetRatingsForLoggedInRecruiterGET>
                  </View>
                </SimpleStyleScrollView>
              </View>
            </View>
          )}
        </>
      </View>
      {/* QUESTION CONTAINER */}
      <>
        {!(show_question === true) ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['outline_block'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['outline_block'].style,
                { flex: null, position: 'absolute', top: 80 }
              ),
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Text'].style,
                dimensions.width
              )}
            >
              {
                'If you think this rating is not valid or presents you in a less than favorable way, we are happy to reach out directly with the person who rated you this way and determine if this rating is valid.'
              }
            </Text>
            {/* BUTTON CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginTop: 20 },
                dimensions.width
              )}
            >
              {/* Button Email */}
              <Button
                iconPosition={'left'}
                {...GlobalStyles.ButtonStyles(theme)['Button_Secondary'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ButtonStyles(theme)['Button_Secondary'].style,
                  dimensions.width
                )}
                title={'Send Email to Us'}
              />
              {/* Button Cancel */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setShow_question(false);
                    setShow_screenContainer(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button_Cancel'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ButtonStyles(theme)['Button_Cancel'].style,
                  dimensions.width
                )}
                title={'Cancel'}
              />
            </View>
          </View>
        )}
      </>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(JobzR8terRecruiterScreen);
