import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzChatApi from '../apis/XanoJobzChatApi.js';
import * as XanoJobzConnectApi from '../apis/XanoJobzConnectApi.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import convertTime from '../global-functions/convertTime';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  SimpleStyleSwipeableList,
  SwipeableItem,
  SwipeableItemButton,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const JobzConnectActivityScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [showEducationSelected, setShowEducationSelected] =
    React.useState(false);
  const [showRight, setShowRight] = React.useState(false);
  const [showWrong, setShowWrong] = React.useState(false);
  const [show_connect_sent, setShow_connect_sent] = React.useState(false);
  const [show_connects_favorered, setShow_connects_favorered] =
    React.useState(false);
  const [show_connects_received, setShow_connects_received] =
    React.useState(true);
  const [show_jobzSelected, setShow_jobzSelected] = React.useState(false);
  const [show_screenContainer, setShow_screenContainer] = React.useState(true);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setShow_connects_received(true);
      setShow_connect_sent(false);
      setGlobalVariableValue({
        key: 'CONNECT',
        value: 'recieved',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
    >
      {/* back navigation */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
          },
          dimensions.width
        )}
      >
        {/* Back */}
        <IconButton
          onPress={() => {
            try {
              if (navigation.canGoBack()) {
                navigation.popToTop();
              }
              navigation.replace('JobzConnectScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          icon={'AntDesign/arrowleft'}
        />
      </View>

      <View
        style={StyleSheet.applyWidth(
          { flex: 1, minWidth: '100%' },
          dimensions.width
        )}
      >
        {/* SCREEN CONTAINER */}
        <>
          {!(show_screenContainer === true) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  justifyContent: [
                    { minWidth: Breakpoints.Mobile, value: 'space-between' },
                    { minWidth: Breakpoints.Tablet, value: 'flex-start' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Heading 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                    marginLeft: 20,
                    marginRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* jobzConnect Activity */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['h2'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['h2'].style,
                    dimensions.width
                  )}
                >
                  {'jobzConnect - Activity'}
                </Text>
                {/* Show Details */}
                <IconButton
                  onPress={() => {
                    try {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('JobzConnectActivityDetailScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  size={32}
                  icon={'Ionicons/information-circle-outline'}
                />
              </View>
              {/* Connection Container */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                <SimpleStyleScrollView
                  bounces={true}
                  horizontal={false}
                  keyboardShouldPersistTaps={'never'}
                  nestedScrollEnabled={false}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  style={StyleSheet.applyWidth(
                    { marginLeft: 10, maxHeight: 590, minHeight: 590 },
                    dimensions.width
                  )}
                >
                  {/* Headings */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'column',
                        marginLeft: 20,
                        marginRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {/* All Matches */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { marginBottom: 10, marginRight: 20 }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'These are the people who have matched by both parties. '
                      }
                    </Text>
                  </View>
                  {/* ALL MATCHES */}
                  <View>
                    <XanoJobzConnectApi.FetchGetAllMatchesForUserGET>
                      {({
                        loading,
                        error,
                        data,
                        refetchGetAllMatchesForUser,
                      }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* Swipeable List Recieved */}
                            <SimpleStyleSwipeableList
                              data={fetchData?.matchResults}
                              disableScrollWhenSwiping={true}
                              estimatedItemSize={50}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(
                                swipeableListRecievedData,
                                index
                              ) =>
                                swipeableListRecievedData?.id ??
                                swipeableListRecievedData?.uuid ??
                                index.toString()
                              }
                              keyboardShouldPersistTaps={'never'}
                              listComponent={'FlatList'}
                              listKey={'9cQnFl2D'}
                              nestedScrollEnabled={false}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const swipeableListRecievedData = item;
                                return (
                                  <SwipeableItem
                                    closeOnPress={true}
                                    friction={20}
                                    swipeActivationPercentage={80}
                                    swipeToClosePercent={50}
                                    swipeToOpenPercent={50}
                                    {...GlobalStyles.SwipeableItemStyles(theme)[
                                      'Swipeable Item'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.SwipeableItemStyles(theme)[
                                        'Swipeable Item'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {/* Swipeable Item Button UnMatch */}
                                    <SwipeableItemButton
                                      closeOnPress={true}
                                      revealSwipeDirection={'left'}
                                      backgroundColor={theme.colors['Error']}
                                      icon={'AntDesign/closecircleo'}
                                      title={'Unmatch'}
                                    />
                                    {/* Swipeable Item Button Match */}
                                    <SwipeableItemButton
                                      closeOnPress={true}
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            const ID_chatroom = (
                                              await XanoJobzChatApi.jOBZCHATGetAllChatsFromASingleChatroomGET(
                                                Constants,
                                                {
                                                  chatroomID:
                                                    swipeableListRecievedData?.id,
                                                }
                                              )
                                            )?.json;
                                            navigation.navigate(
                                              'JobzChatChatsScreen',
                                              { ID_chatroom: ID_chatroom }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      revealSwipeDirection={'left'}
                                      backgroundColor={
                                        theme.colors['Secondary']
                                      }
                                      icon={
                                        'Ionicons/chatbubble-ellipses-outline'
                                      }
                                      title={'Chat'}
                                    />
                                    {/* Recieved Main Container */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginTop: 0 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Recieved Container */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'flex-start',
                                            flex: 1,
                                            flexDirection: 'row',
                                            marginBottom: 10,
                                            marginLeft: 20,
                                            marginRight: 20,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Left Column */}
                                        <View>
                                          <Pressable
                                            onPress={() => {
                                              try {
                                                if (
                                                  swipeableListRecievedData?.primary_role ===
                                                  1
                                                ) {
                                                  navigation.navigate(
                                                    'JobzConnectJobseekerUserProfileScreen',
                                                    {
                                                      ID_selectedUser:
                                                        swipeableListRecievedData?.id,
                                                    }
                                                  );
                                                } else {
                                                  navigation.navigate(
                                                    'JobzConnectRecruiterUserProfileScreen',
                                                    {
                                                      ID_selectedUser:
                                                        swipeableListRecievedData?.id,
                                                    }
                                                  );
                                                }
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                          >
                                            <Image
                                              resizeMode={'cover'}
                                              {...GlobalStyles.ImageStyles(
                                                theme
                                              )['Image'].props}
                                              source={{
                                                uri: `${swipeableListRecievedData?._image_of_user?.image?.url}`,
                                              }}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ].style,
                                                dimensions.width
                                              )}
                                            />
                                          </Pressable>
                                        </View>
                                        {/* Right Column */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flex: 1,
                                              flexDirection: 'column',
                                              paddingLeft: 20,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Name */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flexDirection: 'row' },
                                              dimensions.width
                                            )}
                                          >
                                            {/* First Name */}
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['h2'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h2'].style,
                                                  { marginRight: 4 }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                swipeableListRecievedData?.first_name
                                              }
                                            </Text>
                                            {/* Last Name */}
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['h2'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'h2'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                swipeableListRecievedData?.last_name
                                              }
                                            </Text>
                                          </View>
                                          {/* Demo User */}
                                          <View>
                                            {/* Demo */}
                                            <>
                                              {!swipeableListRecievedData?.demo ? null : (
                                                <Text
                                                  accessible={true}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'DEMO USER'}
                                                </Text>
                                              )}
                                            </>
                                          </View>
                                          {/* Role */}
                                          <View>
                                            {/* Role */}
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['h3'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'h3'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                swipeableListRecievedData
                                                  ?._os_externalrole
                                                  ?.external_role
                                              }
                                            </Text>
                                          </View>
                                          {/* Status */}
                                          <View>
                                            {/* Status */}
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                swipeableListRecievedData?.status
                                              }
                                            </Text>
                                          </View>
                                          {/* LABEL Sent Date */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flexDirection: 'row', gap: 8 },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Received DAte */}
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'First Connected Date:'}
                                            </Text>
                                            {/* Recieved Date */}
                                            <View>
                                              {/* Date */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['h3 bold'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h3 bold'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {convertTime(
                                                  swipeableListRecievedData?.created_at
                                                )}
                                              </Text>
                                            </View>
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  </SwipeableItem>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                            />
                          </>
                        );
                      }}
                    </XanoJobzConnectApi.FetchGetAllMatchesForUserGET>
                  </View>
                </SimpleStyleScrollView>
              </View>
            </View>
          )}
        </>
      </View>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(JobzConnectActivityScreen);
