import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/Draftbit.js';
import LinkingConfiguration from './LinkingConfiguration.js';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';
import * as GlobalVariables from './config/GlobalVariableContext';
import Breakpoints from './utils/Breakpoints';
import useWindowDimensions from './utils/useWindowDimensions';

import AddNewCompanyScreen from './screens/AddNewCompanyScreen';
import AddNewInstitutionScreen from './screens/AddNewInstitutionScreen';
import AddNewLocationScreen from './screens/AddNewLocationScreen';
import AddNewStudyTitleScreen from './screens/AddNewStudyTitleScreen';
import JobzChatChatsScreen from './screens/JobzChatChatsScreen';
import JobzChatScreen from './screens/JobzChatScreen';
import JobzConnectActivityDetailScreen from './screens/JobzConnectActivityDetailScreen';
import JobzConnectActivityScreen from './screens/JobzConnectActivityScreen';
import JobzConnectJobseekerUserProfileScreen from './screens/JobzConnectJobseekerUserProfileScreen';
import JobzConnectRecruiterUserProfileScreen from './screens/JobzConnectRecruiterUserProfileScreen';
import JobzConnectScreen from './screens/JobzConnectScreen';
import JobzForumScreen from './screens/JobzForumScreen';
import JobzFunnelScreen from './screens/JobzFunnelScreen';
import JobzMaverickScreen from './screens/JobzMaverickScreen';
import JobzPrepScreen from './screens/JobzPrepScreen';
import JobzR8terInterimScreen from './screens/JobzR8terInterimScreen';
import JobzR8terListScreen from './screens/JobzR8terListScreen';
import JobzR8terRecruiterScreen from './screens/JobzR8terRecruiterScreen';
import JobzR8terScreen from './screens/JobzR8terScreen';
import JobzStoryAddOneScreen from './screens/JobzStoryAddOneScreen';
import JobzStoryAddScreen from './screens/JobzStoryAddScreen';
import JobzStoryAddTwoScreen from './screens/JobzStoryAddTwoScreen';
import JobzStoryEducationAddScreen from './screens/JobzStoryEducationAddScreen';
import JobzStoryEducationUpdateExistingScreen from './screens/JobzStoryEducationUpdateExistingScreen';
import JobzStorySummaryScreen from './screens/JobzStorySummaryScreen';
import JobzStoryUpdateExisitngScreen from './screens/JobzStoryUpdateExisitngScreen';
import JobzTalkScreen from './screens/JobzTalkScreen';
import JobzTargetScreen from './screens/JobzTargetScreen';
import JobzTrustScreen from './screens/JobzTrustScreen';
import JobzVaultScreen from './screens/JobzVaultScreen';
import JobzZoneJobDetailScreen from './screens/JobzZoneJobDetailScreen';
import JobzZoneScreen from './screens/JobzZoneScreen';
import KitchenJobseekerScreen from './screens/KitchenJobseekerScreen';
import KitchenRecruiterScreen from './screens/KitchenRecruiterScreen';
import LoginScreen from './screens/LoginScreen';
import Onboarding1JobseekerScreen from './screens/Onboarding1JobseekerScreen';
import Onboarding1RecruiterScreen from './screens/Onboarding1RecruiterScreen';
import Onboarding2JobseekerScreen from './screens/Onboarding2JobseekerScreen';
import Onboarding2RecruiterScreen from './screens/Onboarding2RecruiterScreen';
import Onboarding3JobseekerScreen from './screens/Onboarding3JobseekerScreen';
import Onboarding3RecruiterScreen from './screens/Onboarding3RecruiterScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';
import PlanOptionsScreen from './screens/PlanOptionsScreen';
import ProfileJobseekerScreen from './screens/ProfileJobseekerScreen';
import SignUpJobseekerScreen from './screens/SignUpJobseekerScreen';
import SignUpRecrutierScreen from './screens/SignUpRecrutierScreen';
import SuccessScreen from './screens/SuccessScreen';
import SupportTicketScreen from './screens/SupportTicketScreen';
import ZJobzChatDetailScreen from './screens/ZJobzChatDetailScreen';
import ZSideNavBarScreen from './screens/ZSideNavBarScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

export default function RootAppNavigator() {
  const Constants = GlobalVariables.useValues();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#ffffffff',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="LoginScreen"
        screenOptions={({ navigation }) => ({
          headerBackImage:
            Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
          headerShown: false,
          headerStyle: { backgroundColor: 'transparent' },
          headerTintColor: theme.colors['Primary'],
          headerTitleStyle: { fontFamily: 'Poppins_400Regular', fontSize: 12 },
        })}
      >
        <Stack.Screen
          name="KitchenJobseekerScreen"
          component={KitchenJobseekerScreen}
          options={({ navigation }) => ({
            title: 'Kitchen Jobseeker',
          })}
        />
        <Stack.Screen
          name="JobzMaverickScreen"
          component={JobzMaverickScreen}
          options={({ navigation }) => ({
            title: 'jobz Maverick',
          })}
        />
        <Stack.Screen
          name="SupportTicketScreen"
          component={SupportTicketScreen}
          options={({ navigation }) => ({
            title: 'Support Ticket',
          })}
        />
        <Stack.Screen
          name="JobzFunnelScreen"
          component={JobzFunnelScreen}
          options={({ navigation }) => ({
            title: 'jobzFunnel',
          })}
        />
        <Stack.Screen
          name="JobzPrepScreen"
          component={JobzPrepScreen}
          options={({ navigation }) => ({
            title: 'jobzPrep',
          })}
        />
        <Stack.Screen
          name="JobzTalkScreen"
          component={JobzTalkScreen}
          options={({ navigation }) => ({
            title: 'jobzTalk',
          })}
        />
        <Stack.Screen
          name="JobzTargetScreen"
          component={JobzTargetScreen}
          options={({ navigation }) => ({
            headerShown: false,
            headerTintColor: theme.colors['Primary'],
            headerTitleStyle: { fontFamily: 'Poppins_400Regular' },
            title: 'jobzTarget',
          })}
        />
        <Stack.Screen
          name="JobzTrustScreen"
          component={JobzTrustScreen}
          options={({ navigation }) => ({
            title: 'jobzTrust',
          })}
        />
        <Stack.Screen
          name="JobzVaultScreen"
          component={JobzVaultScreen}
          options={({ navigation }) => ({
            headerShown: false,
            headerTintColor: theme.colors['Primary'],
            headerTitleStyle: { fontFamily: 'Poppins_400Regular' },
            title: 'jobzVault',
          })}
        />
        <Stack.Screen
          name="JobzStoryAddScreen"
          component={JobzStoryAddScreen}
          options={({ navigation }) => ({
            title: 'jobzStory - Add',
          })}
        />
        <Stack.Screen
          name="JobzR8terInterimScreen"
          component={JobzR8terInterimScreen}
          options={({ navigation }) => ({
            title: 'jobzR8ter interim',
          })}
        />
        <Stack.Screen
          name="Onboarding1JobseekerScreen"
          component={Onboarding1JobseekerScreen}
          options={({ navigation }) => ({
            headerRight: ({ tintColor }) => (
              <View
                style={[styles.headerContainer, styles.headerContainerRight]}
              >
                <Icon
                  name="MaterialIcons/search"
                  size={Platform.OS === 'ios' ? 21 : 24}
                  color={tintColor}
                  style={[styles.headerIcon, styles.headerIconRight]}
                />
              </View>
            ),
            headerShown: false,
            headerTitleStyle: { fontFamily: 'Poppins_400Regular' },
            title: 'onboarding_1 Jobseeker',
          })}
        />
        <Stack.Screen
          name="Onboarding2JobseekerScreen"
          component={Onboarding2JobseekerScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'onboarding_2 Jobseeker',
          })}
        />
        <Stack.Screen
          name="LoginScreen"
          component={LoginScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Login',
          })}
        />
        <Stack.Screen
          name="Onboarding3JobseekerScreen"
          component={Onboarding3JobseekerScreen}
          options={({ navigation }) => ({
            title: 'onboarding_3 Jobseeker',
          })}
        />
        <Stack.Screen
          name="SuccessScreen"
          component={SuccessScreen}
          options={({ navigation }) => ({
            headerShown: false,
            title: 'Success',
          })}
        />
        <Stack.Screen
          name="JobzZoneScreen"
          component={JobzZoneScreen}
          options={({ navigation }) => ({
            title: 'jobzZone',
          })}
        />
        <Stack.Screen
          name="ProfileJobseekerScreen"
          component={ProfileJobseekerScreen}
          options={({ navigation }) => ({
            title: 'Profile - Jobseeker',
          })}
        />
        <Stack.Screen
          name="JobzZoneJobDetailScreen"
          component={JobzZoneJobDetailScreen}
          options={({ navigation }) => ({
            title: 'jobzZone - Job Detail',
          })}
        />
        <Stack.Screen
          name="JobzStoryAddOneScreen"
          component={JobzStoryAddOneScreen}
          options={({ navigation }) => ({
            title: 'jobzStory - Add One',
          })}
        />
        <Stack.Screen
          name="JobzStoryAddTwoScreen"
          component={JobzStoryAddTwoScreen}
          options={({ navigation }) => ({
            title: 'jobzStory - Add Two',
          })}
        />
        <Stack.Screen
          name="PlanOptionsScreen"
          component={PlanOptionsScreen}
          options={({ navigation }) => ({
            title: 'Plan Options',
          })}
        />
        <Stack.Screen
          name="SignUpJobseekerScreen"
          component={SignUpJobseekerScreen}
          options={({ navigation }) => ({
            title: 'Sign up Jobseeker',
          })}
        />
        <Stack.Screen
          name="JobzChatChatsScreen"
          component={JobzChatChatsScreen}
          options={({ navigation }) => ({
            title: 'jobzChat - Chats',
          })}
        />
        <Stack.Screen
          name="JobzStoryEducationAddScreen"
          component={JobzStoryEducationAddScreen}
          options={({ navigation }) => ({
            title: 'jobzStory Education - Add',
          })}
        />
        <Stack.Screen
          name="JobzForumScreen"
          component={JobzForumScreen}
          options={({ navigation }) => ({
            title: 'jobzForum',
          })}
        />
        <Stack.Screen
          name="JobzConnectScreen"
          component={JobzConnectScreen}
          options={({ navigation }) => ({
            title: 'jobzConnect',
          })}
        />
        <Stack.Screen
          name="JobzConnectJobseekerUserProfileScreen"
          component={JobzConnectJobseekerUserProfileScreen}
          options={({ navigation }) => ({
            title: 'jobzConnect - Jobseeker User Profile',
          })}
        />
        <Stack.Screen
          name="JobzConnectRecruiterUserProfileScreen"
          component={JobzConnectRecruiterUserProfileScreen}
          options={({ navigation }) => ({
            title: 'jobzConnect - Recruiter User Profile',
          })}
        />
        <Stack.Screen
          name="PasswordResetScreen"
          component={PasswordResetScreen}
          options={({ navigation }) => ({
            title: 'Password Reset',
          })}
        />
        <Stack.Screen
          name="JobzConnectActivityScreen"
          component={JobzConnectActivityScreen}
          options={({ navigation }) => ({
            title: 'jobzConnect - Activity ',
          })}
        />
        <Stack.Screen
          name="ZSideNavBarScreen"
          component={ZSideNavBarScreen}
          options={({ navigation }) => ({
            title: 'z_Side Nav Bar',
          })}
        />
        <Stack.Screen
          name="JobzStoryUpdateExisitngScreen"
          component={JobzStoryUpdateExisitngScreen}
          options={({ navigation }) => ({
            title: 'jobzStory - Update Exisitng',
          })}
        />
        <Stack.Screen
          name="AddNewLocationScreen"
          component={AddNewLocationScreen}
          options={({ navigation }) => ({
            title: 'Add - New Location',
          })}
        />
        <Stack.Screen
          name="AddNewInstitutionScreen"
          component={AddNewInstitutionScreen}
          options={({ navigation }) => ({
            title: 'Add - New Institution',
          })}
        />
        <Stack.Screen
          name="AddNewStudyTitleScreen"
          component={AddNewStudyTitleScreen}
          options={({ navigation }) => ({
            title: 'Add - New Study Title',
          })}
        />
        <Stack.Screen
          name="JobzStoryEducationUpdateExistingScreen"
          component={JobzStoryEducationUpdateExistingScreen}
          options={({ navigation }) => ({
            title: 'jobzStory Education - Update Existing',
          })}
        />
        <Stack.Screen
          name="AddNewCompanyScreen"
          component={AddNewCompanyScreen}
          options={({ navigation }) => ({
            title: 'Add - New Company',
          })}
        />
        <Stack.Screen
          name="JobzStorySummaryScreen"
          component={JobzStorySummaryScreen}
          options={({ navigation }) => ({
            title: 'jobzStory Summary',
          })}
        />
        <Stack.Screen
          name="JobzChatScreen"
          component={JobzChatScreen}
          options={({ navigation }) => ({
            title: 'jobzChat ',
          })}
        />
        <Stack.Screen
          name="JobzConnectActivityDetailScreen"
          component={JobzConnectActivityDetailScreen}
          options={({ navigation }) => ({
            title: 'jobzConnect - Activity  Detail',
          })}
        />
        <Stack.Screen
          name="ZJobzChatDetailScreen"
          component={ZJobzChatDetailScreen}
          options={({ navigation }) => ({
            title: 'z_jobzChat - Detail',
          })}
        />
        <Stack.Screen
          name="Onboarding1RecruiterScreen"
          component={Onboarding1RecruiterScreen}
          options={({ navigation }) => ({
            title: 'onboarding_1 Recruiter',
          })}
        />
        <Stack.Screen
          name="Onboarding2RecruiterScreen"
          component={Onboarding2RecruiterScreen}
          options={({ navigation }) => ({
            title: 'onboarding_2 Recruiter',
          })}
        />
        <Stack.Screen
          name="Onboarding3RecruiterScreen"
          component={Onboarding3RecruiterScreen}
          options={({ navigation }) => ({
            title: 'onboarding_3 Recruiter',
          })}
        />
        <Stack.Screen
          name="SignUpRecrutierScreen"
          component={SignUpRecrutierScreen}
          options={({ navigation }) => ({
            title: 'Sign up Recrutier',
          })}
        />
        <Stack.Screen
          name="KitchenRecruiterScreen"
          component={KitchenRecruiterScreen}
          options={({ navigation }) => ({
            title: 'Kitchen Recruiter',
          })}
        />
        <Stack.Screen
          name="JobzR8terScreen"
          component={JobzR8terScreen}
          options={({ navigation }) => ({
            title: 'jobzR8ter',
          })}
        />
        <Stack.Screen
          name="JobzR8terListScreen"
          component={JobzR8terListScreen}
          options={({ navigation }) => ({
            title: 'jobzR8ter List',
          })}
        />
        <Stack.Screen
          name="JobzR8terRecruiterScreen"
          component={JobzR8terRecruiterScreen}
          options={({ navigation }) => ({
            title: 'jobzR8ter Recruiter',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerContainerRight: Platform.select({ ios: { marginRight: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
  headerIconRight: Platform.select({ ios: { marginLeft: 6 } }),
});
