import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzStoryApi from '../apis/XanoJobzStoryApi.js';
import * as XanoOptionSets$DropdownsApi from '../apis/XanoOptionSets$DropdownsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import getPickerOptions from '../global-functions/getPickerOptions';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  DatePicker,
  Icon,
  IconButton,
  Link,
  Picker,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleKeyboardAwareScrollView,
  Switch,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const JobzStoryUpdateExisitngScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [ID_company, setID_company] = React.useState(0);
  const [ID_jobzstory, setID_jobzstory] = React.useState(0);
  const [input_CompanyName, setInput_CompanyName] = React.useState('');
  const [input_EndDate, setInput_EndDate] = React.useState(0);
  const [input_ExternalTitle, setInput_ExternalTitle] = React.useState('');
  const [input_Industry, setInput_Industry] = React.useState('');
  const [input_InternalTitle, setInput_InternalTitle] = React.useState('');
  const [input_JobLevel, setInput_JobLevel] = React.useState('');
  const [input_JobType, setInput_JobType] = React.useState('');
  const [input_LastSalary, setInput_LastSalary] = React.useState('');
  const [input_NoPeopleManaged, setInput_NoPeopleManaged] = React.useState('');
  const [input_ReasonForLeaving, setInput_ReasonForLeaving] =
    React.useState('');
  const [input_StartDate, setInput_StartDate] = React.useState(0);
  const [input_TeamSize, setInput_TeamSize] = React.useState('');
  const [input_TopCompanyContribution, setInput_TopCompanyContribution] =
    React.useState('');
  const [input_TopJobContribution, setInput_TopJobContribution] =
    React.useState('');
  const [input_TopTeamContribution, setInput_TopTeamContribution] =
    React.useState('');
  const [isEditingCompany, setIsEditingCompany] = React.useState(false);
  const [label_FindCompanyName, setLabel_FindCompanyName] = React.useState('');
  const [newEndDate, setNewEndDate] = React.useState(0);
  const [newStartDate, setNewStartDate] = React.useState(0);
  const [searchCompanyValue, setSearchCompanyValue] = React.useState('');
  const [show_change_dates, setShow_change_dates] = React.useState(false);
  const [show_view_Container, setShow_view_Container] = React.useState(true);
  const [sv_ContractRole, setSv_ContractRole] = React.useState(false);
  const [sv_DisplaySalary, setSv_DisplaySalary] = React.useState(false);
  const [sv_ManagesOthers, setSv_ManagesOthers] = React.useState(false);
  const [sv_PresentRole, setSv_PresentRole] = React.useState(false);
  const [sv_displaySalary, setSv_displaySalary] = React.useState(false);
  const [sv_manageOthers, setSv_manageOthers] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if ('NewCompanyResponse'?.company_name?.length) {
        setSearchCompanyValue('NewCompanyResponse'?.company_name);
        setID_company('NewCompanyResponse'?.id);
      } else {
        setSearchCompanyValue(input_CompanyName);
      }

      setID_jobzstory(props.route?.params?.ID_jobzStory ?? 46);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { flex: 1, justifyContent: 'space-evenly' },
        dimensions.width
      )}
    >
      {/* SCREEN CONTAINER */}
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, justifyContent: 'space-between' },
          dimensions.width
        )}
      >
        {/* back navigation */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          {/* Back */}
          <IconButton
            onPress={() => {
              try {
                navigation.navigate('JobzStorySummaryScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            size={32}
            icon={'AntDesign/arrowleft'}
          />
        </View>
        {/* VIEW CONTAINER */}
        <>
          {!(show_view_Container === true) ? null : (
            <View
              style={StyleSheet.applyWidth(
                { justifyContent: 'space-evenly' },
                dimensions.width
              )}
            >
              <SimpleStyleKeyboardAwareScrollView
                enableAutomaticScroll={false}
                enableOnAndroid={false}
                enableResetScrollToCoords={false}
                keyboardShouldPersistTaps={'never'}
                showsVerticalScrollIndicator={true}
                viewIsInsideTabBar={false}
              >
                {/* CONTAINER */}
                <View>
                  {/* inputs */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 20, paddingRight: 20 },
                      dimensions.width
                    )}
                  >
                    {/* Heading */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['h2'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['h2'].style,
                        dimensions.width
                      )}
                    >
                      {'Update your information'}
                    </Text>

                    <XanoJobzStoryApi.FetchGetASingleJobzStoryGET
                      handlers={{
                        onData: fetchData => {
                          try {
                            console.log(fetchData);
                            setID_jobzstory(
                              props.route?.params?.ID_jobzStory ?? 46
                            );
                            setInput_InternalTitle(fetchData?.internal_title);
                            setInput_ExternalTitle(fetchData?.external_title);
                            setSearchCompanyValue(
                              fetchData?._company?.company_name
                            );
                            setSv_ContractRole(fetchData?.contract);
                            setSv_PresentRole(fetchData?.present_role);
                            setInput_StartDate(new Date(fetchData?.start_date));
                            setInput_EndDate(new Date(fetchData?.end_date));
                            setInput_JobType(fetchData?._os_jobtype?.id);
                            setInput_JobLevel(fetchData?._os_joblevel?.id);
                            setInput_Industry(fetchData?._os_industry?.id);
                            setSv_ManagesOthers(fetchData?.manages_others);
                            setInput_NoPeopleManaged(
                              fetchData?._os_nopeoplemanaged?.id
                            );
                            setInput_TeamSize(fetchData?._os_teamsize?.id);
                            setSv_DisplaySalary(fetchData?.display_salary);
                            setInput_LastSalary(fetchData?.salary);
                            setInput_TopJobContribution(
                              fetchData?.top_job_contribution
                            );
                            setInput_TopTeamContribution(
                              fetchData?.top_team_contribution
                            );
                            setInput_TopCompanyContribution(
                              fetchData?.top_company_contribution
                            );
                            setInput_ReasonForLeaving(
                              fetchData?.reason_for_leaving
                            );
                            setID_company(fetchData?._company?.id);
                          } catch (err) {
                            console.error(err);
                          }
                        },
                      }}
                      jobzstory_id={props.route?.params?.ID_jobzStory ?? 46}
                    >
                      {({
                        loading,
                        error,
                        data,
                        refetchGetASingleJobzStory,
                      }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* Internal Title  */}
                            <View>
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['h3'].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['h3'].style,
                                  dimensions.width
                                )}
                              >
                                {'Internal Title'}
                              </Text>
                              {/* Input Internal Title */}
                              <TextInput
                                autoCapitalize={'none'}
                                autoCorrect={true}
                                changeTextDelay={500}
                                onChangeText={newInputInternalTitleValue => {
                                  try {
                                    setInput_InternalTitle(
                                      newInputInternalTitleValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                placeholder={'Enter a value...'}
                                webShowOutline={true}
                                {...GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ].props}
                                editable={true}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextInputStyles(theme)[
                                    'Text Input'
                                  ].style,
                                  dimensions.width
                                )}
                                value={input_InternalTitle}
                              />
                            </View>
                            {/* External Title  */}
                            <View>
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['h3'].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['h3'].style,
                                  dimensions.width
                                )}
                              >
                                {'External Title'}
                              </Text>
                              {/* Input External Title */}
                              <TextInput
                                autoCapitalize={'none'}
                                autoCorrect={true}
                                changeTextDelay={500}
                                onChangeText={newInputExternalTitleValue => {
                                  try {
                                    setInput_ExternalTitle(
                                      newInputExternalTitleValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                placeholder={'Enter a value...'}
                                webShowOutline={true}
                                {...GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ].props}
                                editable={true}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextInputStyles(theme)[
                                    'Text Input'
                                  ].style,
                                  dimensions.width
                                )}
                                value={input_ExternalTitle}
                              />
                            </View>
                            {/* Company Name */}
                            <View>
                              {/* Company Add Block */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* LABEL Company Name */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['label']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['label']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {'Company*'}
                                </Text>

                                <Pressable
                                  onPress={() => {
                                    try {
                                      navigation.navigate(
                                        'AddNewCompanyScreen',
                                        {
                                          newCompanyResponse:
                                            searchCompanyValue,
                                        }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  {/* Add Company */}
                                  <Icon
                                    size={24}
                                    {...GlobalStyles.IconStyles(theme)[
                                      'icon_secondary'
                                    ].props}
                                    name={'AntDesign/plus'}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.IconStyles(theme)[
                                        'icon_secondary'
                                      ].style,
                                      dimensions.width
                                    )}
                                  />
                                </Pressable>
                              </View>
                              {/* Find Company */}
                              <View>
                                {/* Input Company Name */}
                                <TextInput
                                  autoCapitalize={'none'}
                                  autoCorrect={true}
                                  changeTextDelay={500}
                                  onChangeText={newInputCompanyNameValue => {
                                    try {
                                      const valueqxTVkbfu =
                                        newInputCompanyNameValue;
                                      setSearchCompanyValue(valueqxTVkbfu);
                                      const newCompanyResult = valueqxTVkbfu;
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  onChangeTextDelayed={newInputCompanyNameValue => {
                                    try {
                                      if (
                                        newInputCompanyNameValue?.length > 0
                                      ) {
                                      } else {
                                      }
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  onFocus={() => {
                                    try {
                                      setIsEditingCompany(true);
                                      setSearchCompanyValue('');
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  webShowOutline={true}
                                  {...GlobalStyles.TextInputStyles(theme)[
                                    'InputField'
                                  ].props}
                                  placeholder={label_FindCompanyName.toString()}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextInputStyles(theme)[
                                        'InputField'
                                      ].style,
                                      { borderRadius: 18 }
                                    ),
                                    dimensions.width
                                  )}
                                  value={searchCompanyValue}
                                />
                                <>
                                  {!(
                                    searchCompanyValue?.length &&
                                    isEditingCompany
                                  ) ? null : (
                                    <XanoOptionSets$DropdownsApi.FetchDropDownCompanyGET
                                      handlers={{
                                        onData: fetchData => {
                                          try {
                                            console.log();
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        },
                                      }}
                                      search={searchCompanyValue}
                                    >
                                      {({
                                        loading,
                                        error,
                                        data,
                                        refetchDropDownCompany,
                                      }) => {
                                        const fetchData = data?.json;
                                        if (loading) {
                                          return <ActivityIndicator />;
                                        }

                                        if (
                                          error ||
                                          data?.status < 200 ||
                                          data?.status >= 300
                                        ) {
                                          return <ActivityIndicator />;
                                        }

                                        return (
                                          <>
                                            {/* MESSAGE Add Your Company */}
                                            <>
                                              {fetchData?.dd_company
                                                ?.length ? null : (
                                                <Text
                                                  accessible={true}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    'You will need to add your company, click on +'
                                                  }
                                                </Text>
                                              )}
                                            </>
                                            <>
                                              {!(
                                                searchCompanyValue?.length > 2
                                              ) ? null : (
                                                <SimpleStyleFlatList
                                                  data={fetchData?.dd_company}
                                                  horizontal={false}
                                                  inverted={false}
                                                  keyExtractor={(
                                                    listData,
                                                    index
                                                  ) =>
                                                    listData?.id ??
                                                    listData?.uuid ??
                                                    index.toString()
                                                  }
                                                  keyboardShouldPersistTaps={
                                                    'never'
                                                  }
                                                  listKey={'hKbHY05Q'}
                                                  nestedScrollEnabled={false}
                                                  numColumns={1}
                                                  onEndReachedThreshold={0.5}
                                                  renderItem={({
                                                    item,
                                                    index,
                                                  }) => {
                                                    const listData = item;
                                                    return (
                                                      <Pressable
                                                        onPress={() => {
                                                          try {
                                                            setSearchCompanyValue(
                                                              listData?.company_name
                                                            );
                                                            setInput_CompanyName(
                                                              'newCompanyResult'
                                                            );
                                                            setID_company(
                                                              listData?.id
                                                            );
                                                            setIsEditingCompany(
                                                              false
                                                            );
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        }}
                                                      >
                                                        {/* Company Name to Select */}
                                                        <Text
                                                          accessible={true}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )['Text'].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            listData?.company_name
                                                          }
                                                          {', '}
                                                          {listData?.Location}
                                                        </Text>
                                                      </Pressable>
                                                    );
                                                  }}
                                                  showsHorizontalScrollIndicator={
                                                    true
                                                  }
                                                  showsVerticalScrollIndicator={
                                                    true
                                                  }
                                                />
                                              )}
                                            </>
                                          </>
                                        );
                                      }}
                                    </XanoOptionSets$DropdownsApi.FetchDropDownCompanyGET>
                                  )}
                                </>
                              </View>
                            </View>
                            {/* Contract */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginTop: 6,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['h3'].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['h3'].style,
                                  dimensions.width
                                )}
                              >
                                {'Contract Role\n'}
                              </Text>
                              {/* Contract */}
                              <Switch
                                onValueChange={newContractValue => {
                                  try {
                                    setSv_ContractRole(newContractValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                value={sv_ContractRole}
                              />
                            </View>
                            {/* Present Role */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginTop: 6,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['h3'].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['h3'].style,
                                  dimensions.width
                                )}
                              >
                                {'Present Role\n'}
                              </Text>
                              {/* Present Role */}
                              <Switch
                                onValueChange={newPresentRoleValue => {
                                  try {
                                    setSv_PresentRole(newPresentRoleValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                value={sv_PresentRole}
                              />
                            </View>
                            {/* Start and End Dates */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingRight: 20 },
                                dimensions.width
                              )}
                            >
                              {/* Start Date */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { paddingTop: 6 },
                                  dimensions.width
                                )}
                              >
                                {/* Start Date Update Link */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flexDirection: 'row' },
                                    dimensions.width
                                  )}
                                >
                                  {/* LABEL Start Date */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['h3']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['h3']
                                          .style,
                                        { marginTop: null }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {'Start Date\n'}
                                  </Text>
                                  {/* Link  Updates Dates */}
                                  <Link
                                    accessible={true}
                                    onPress={() => {
                                      try {
                                        setShow_change_dates(true);
                                        setShow_view_Container(false);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    {...GlobalStyles.LinkStyles(theme)['Link']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.LinkStyles(theme)['Link']
                                          .style,
                                        {
                                          fontFamily: 'Poppins_400Regular',
                                          fontSize: 14,
                                          paddingLeft: 20,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'Update Dates'}
                                  />
                                </View>
                                {/* Date Picker Start Date */}
                                <DatePicker
                                  autoDismissKeyboard={true}
                                  hideLabel={false}
                                  label={'Date'}
                                  leftIconMode={'inset'}
                                  mode={'date'}
                                  onDateChange={newDatePickerStartDateValue => {
                                    try {
                                      setInput_StartDate(
                                        newDatePickerStartDateValue
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  type={'solid'}
                                  {...GlobalStyles.DatePickerStyles(theme)[
                                    'date_picker'
                                  ].props}
                                  date={input_StartDate}
                                  disabled={true}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.DatePickerStyles(theme)[
                                      'date_picker'
                                    ].style,
                                    dimensions.width
                                  )}
                                />
                              </View>
                              {/* End Date */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginTop: 6, paddingBottom: 6 },
                                  dimensions.width
                                )}
                              >
                                {/* LABEL End Date */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['h3']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['h3'].style,
                                    dimensions.width
                                  )}
                                >
                                  {'End Date\n'}
                                </Text>
                                {/* Date Picker End Date */}
                                <DatePicker
                                  autoDismissKeyboard={true}
                                  label={'Date'}
                                  leftIconMode={'inset'}
                                  mode={'date'}
                                  onDateChange={newDatePickerEndDateValue => {
                                    try {
                                      setInput_EndDate(
                                        newDatePickerEndDateValue
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  type={'solid'}
                                  {...GlobalStyles.DatePickerStyles(theme)[
                                    'date_picker'
                                  ].props}
                                  date={input_EndDate}
                                  disabled={true}
                                  hideLabel={true}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.DatePickerStyles(theme)[
                                        'date_picker'
                                      ].style,
                                      { width: '100%' }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              </View>
                            </View>
                            {/* Dropdown Option Block */}
                            <View
                              style={StyleSheet.applyWidth(
                                { gap: 10 },
                                dimensions.width
                              )}
                            >
                              <XanoOptionSets$DropdownsApi.FetchOPTIONSETGetJobzStoryPickerOptionsGET
                                handlers={{
                                  onData: fetchData => {
                                    try {
                                      const jobTypeResult = getPickerOptions(
                                        fetchData?.dd_jobType,
                                        'job_type'
                                      );
                                      setGlobalVariableValue({
                                        key: 'JOB_TYPE_OPTIONS',
                                        value: jobTypeResult,
                                      });
                                      const jobLevelResult = getPickerOptions(
                                        fetchData?.dd_jobLevel,
                                        'job_level'
                                      );
                                      setGlobalVariableValue({
                                        key: 'JOB_LEVEL_OPTIONS',
                                        value: jobLevelResult,
                                      });
                                      const industryResult = getPickerOptions(
                                        fetchData?.dd_industry,
                                        'industry'
                                      );
                                      setGlobalVariableValue({
                                        key: 'INDUSTY_OPTIONS',
                                        value: industryResult,
                                      });
                                      const noManagedResult = getPickerOptions(
                                        fetchData?.dd_noPeopleManaged,
                                        'no_of_people_managed'
                                      );
                                      setGlobalVariableValue({
                                        key: 'NO_MANAGED_OPTIONS',
                                        value: noManagedResult,
                                      });
                                      const teamSizeResult = getPickerOptions(
                                        fetchData?.dd_teamSize,
                                        'team_size'
                                      );
                                      setGlobalVariableValue({
                                        key: 'TEAM_SIZE_OPTIONS',
                                        value: teamSizeResult,
                                      });
                                      setGlobalVariableValue({
                                        key: 'PV_JOB_TYPE',
                                        value: fetchData?._os_jobtype?.job_type,
                                      });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  },
                                }}
                              >
                                {({
                                  loading,
                                  error,
                                  data,
                                  refetchOPTIONSETGetJobzStoryPickerOptions,
                                }) => {
                                  const fetchData = data?.json;
                                  if (loading) {
                                    return <ActivityIndicator />;
                                  }

                                  if (
                                    error ||
                                    data?.status < 200 ||
                                    data?.status >= 300
                                  ) {
                                    return <ActivityIndicator />;
                                  }

                                  return (
                                    <>
                                      {/* Job Type */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginTop: 10 },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'h3 bold'
                                              ].style,
                                              { marginBottom: 4 }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Job Type'}
                                        </Text>
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background
                                          }
                                          dropDownBorderColor={
                                            theme.colors.divider
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.strong
                                          }
                                          iconSize={24}
                                          leftIconMode={'inset'}
                                          onValueChange={newPickerValue => {
                                            try {
                                              setInput_JobType(newPickerValue);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          type={'solid'}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'picker'
                                          ].props}
                                          mode={'dropdown-modal'}
                                          options={
                                            Constants['JOB_TYPE_OPTIONS']
                                          }
                                          placeholder={'Please choose'}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'picker'
                                            ].style,
                                            dimensions.width
                                          )}
                                          value={input_JobType}
                                        />
                                      </View>
                                      {/* Job Level */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { minWidth: '100%' },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'h3 bold'
                                              ].style,
                                              { marginBottom: 4 }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Job Level\n'}
                                        </Text>
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background
                                          }
                                          dropDownBorderColor={
                                            theme.colors.divider
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.strong
                                          }
                                          iconSize={24}
                                          leftIconMode={'inset'}
                                          onValueChange={newPickerValue => {
                                            try {
                                              setInput_JobLevel(newPickerValue);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          type={'solid'}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'picker'
                                          ].props}
                                          mode={'dropdown-modal'}
                                          options={
                                            Constants['JOB_LEVEL_OPTIONS']
                                          }
                                          placeholder={'Please choose'}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'picker'
                                            ].style,
                                            dimensions.width
                                          )}
                                          value={input_JobLevel}
                                        />
                                      </View>
                                      {/* Industry */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { minWidth: '100%', zIndex: 99 },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'h3 bold'
                                              ].style,
                                              { marginBottom: 4 }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Industry'}
                                        </Text>
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background
                                          }
                                          dropDownBorderColor={
                                            theme.colors.divider
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.strong
                                          }
                                          iconSize={24}
                                          leftIconMode={'inset'}
                                          onValueChange={newPickerValue => {
                                            try {
                                              setInput_Industry(newPickerValue);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          type={'solid'}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'picker'
                                          ].props}
                                          mode={'dropdown-modal'}
                                          options={Constants['INDUSTY_OPTIONS']}
                                          placeholder={'Please choose'}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'picker'
                                            ].style,
                                            dimensions.width
                                          )}
                                          value={input_Industry}
                                        />
                                      </View>
                                      {/* Manage Others */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            flex: 1,
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'h3 bold'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Do you manage others?'}
                                        </Text>
                                        {/* Present Role */}
                                        <Switch
                                          onValueChange={newPresentRoleValue => {
                                            try {
                                              setSv_manageOthers(
                                                newPresentRoleValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          value={sv_manageOthers}
                                        />
                                      </View>
                                      {/* No of People Managed */}
                                      <>
                                        {!sv_manageOthers ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { minWidth: '100%', zIndex: 99 },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['h3 bold'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h3 bold'].style,
                                                  { marginBottom: 4 }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'No of People Managed'}
                                            </Text>
                                            <Picker
                                              autoDismissKeyboard={true}
                                              dropDownBackgroundColor={
                                                theme.colors.background
                                              }
                                              dropDownBorderColor={
                                                theme.colors.divider
                                              }
                                              dropDownBorderRadius={8}
                                              dropDownBorderWidth={1}
                                              dropDownTextColor={
                                                theme.colors.strong
                                              }
                                              iconSize={24}
                                              leftIconMode={'inset'}
                                              onValueChange={newPickerValue => {
                                                try {
                                                  setInput_NoPeopleManaged(
                                                    newPickerValue
                                                  );
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                              selectedIconColor={
                                                theme.colors.strong
                                              }
                                              selectedIconName={'Feather/check'}
                                              selectedIconSize={20}
                                              type={'solid'}
                                              {...GlobalStyles.PickerStyles(
                                                theme
                                              )['picker'].props}
                                              mode={'dropdown-modal'}
                                              options={
                                                Constants['NO_MANAGED_OPTIONS']
                                              }
                                              placeholder={'Please choose'}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.PickerStyles(
                                                    theme
                                                  )['picker'].style,
                                                  {
                                                    color:
                                                      theme.colors['Light'],
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                              value={input_NoPeopleManaged}
                                            />
                                          </View>
                                        )}
                                      </>
                                      {/* Team Size */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { minWidth: '100%', zIndex: 99 },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'h3 bold'
                                              ].style,
                                              { marginBottom: 4 }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Team Size'}
                                        </Text>
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background
                                          }
                                          dropDownBorderColor={
                                            theme.colors.divider
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.strong
                                          }
                                          iconSize={24}
                                          leftIconMode={'inset'}
                                          onValueChange={newPickerValue => {
                                            try {
                                              setInput_TeamSize(newPickerValue);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          type={'solid'}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'picker'
                                          ].props}
                                          mode={'dropdown-modal'}
                                          options={
                                            Constants['TEAM_SIZE_OPTIONS']
                                          }
                                          placeholder={'Please choose'}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'picker'
                                            ].style,
                                            dimensions.width
                                          )}
                                          value={input_TeamSize}
                                        />
                                      </View>
                                      {/* Display Salary */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            flex: 1,
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'h3 bold'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'h3 bold'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Display Salary?'}
                                        </Text>
                                        {/* Display Salary */}
                                        <Switch
                                          onValueChange={newDisplaySalaryValue => {
                                            try {
                                              setSv_displaySalary(
                                                newDisplaySalaryValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          value={sv_displaySalary}
                                        />
                                      </View>
                                    </>
                                  );
                                }}
                              </XanoOptionSets$DropdownsApi.FetchOPTIONSETGetJobzStoryPickerOptionsGET>
                            </View>
                            {/* Last Salary */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 6 },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['h3'].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['h3'].style,
                                    { marginTop: null }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Last Salary'}
                              </Text>
                              {/* Input Last Salary */}
                              <TextInput
                                autoCapitalize={'none'}
                                autoCorrect={true}
                                changeTextDelay={500}
                                onChangeText={newInputLastSalaryValue => {
                                  try {
                                    setInput_LastSalary(
                                      newInputLastSalaryValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                placeholder={'Enter a value...'}
                                webShowOutline={true}
                                {...GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextInputStyles(theme)[
                                    'Text Input'
                                  ].style,
                                  dimensions.width
                                )}
                                value={input_LastSalary}
                              />
                            </View>
                            {/* Top Job Contribution */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginTop: 6 },
                                dimensions.width
                              )}
                            >
                              {/* Label */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['h3 bold']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['h3 bold']
                                    .style,
                                  dimensions.width
                                )}
                              >
                                {'Top Job Contriubution'}
                              </Text>
                              {/* Input Top Job Contribution */}
                              <TextInput
                                autoCapitalize={'none'}
                                autoCorrect={true}
                                changeTextDelay={500}
                                onChangeText={newInputTopJobContributionValue => {
                                  try {
                                    setInput_TopJobContribution(
                                      newInputTopJobContributionValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                placeholder={'Enter a value...'}
                                webShowOutline={true}
                                {...GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextInputStyles(theme)[
                                      'Text Input'
                                    ].style,
                                    {
                                      borderRadius: 18,
                                      fontSize: 12,
                                      marginTop: 8,
                                    }
                                  ),
                                  dimensions.width
                                )}
                                value={input_TopJobContribution}
                              />
                            </View>
                            {/* Top Team Contribution */}
                            <View>
                              {/* Label */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['h3 bold']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['h3 bold']
                                    .style,
                                  dimensions.width
                                )}
                              >
                                {'Top Team Contriubution'}
                              </Text>
                              {/* Input Top Team Contribution */}
                              <TextInput
                                autoCapitalize={'none'}
                                autoCorrect={true}
                                changeTextDelay={500}
                                onChangeText={newInputTopTeamContributionValue => {
                                  try {
                                    setInput_TopTeamContribution(
                                      newInputTopTeamContributionValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                placeholder={'Enter a value...'}
                                webShowOutline={true}
                                {...GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextInputStyles(theme)[
                                      'Text Input'
                                    ].style,
                                    {
                                      borderRadius: 18,
                                      fontSize: 12,
                                      marginTop: 8,
                                    }
                                  ),
                                  dimensions.width
                                )}
                                value={input_TopTeamContribution}
                              />
                            </View>
                            {/* Top Company Contribution */}
                            <View>
                              {/* Label */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['h3 bold']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['h3 bold']
                                    .style,
                                  dimensions.width
                                )}
                              >
                                {'Top Company Contribution'}
                              </Text>
                              {/* Input Top Company Contribution */}
                              <TextInput
                                autoCapitalize={'none'}
                                autoCorrect={true}
                                changeTextDelay={500}
                                onChangeText={newInputTopCompanyContributionValue => {
                                  try {
                                    setInput_TopCompanyContribution(
                                      newInputTopCompanyContributionValue
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                placeholder={'Enter a value...'}
                                webShowOutline={true}
                                {...GlobalStyles.TextInputStyles(theme)[
                                  'Text Input'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextInputStyles(theme)[
                                      'Text Input'
                                    ].style,
                                    {
                                      borderRadius: 18,
                                      fontSize: 12,
                                      marginTop: 8,
                                    }
                                  ),
                                  dimensions.width
                                )}
                                value={input_TopCompanyContribution}
                              />
                            </View>
                            {/* Reason for Leaving */}
                            <View
                              style={StyleSheet.applyWidth(
                                { minHeight: 200 },
                                dimensions.width
                              )}
                            >
                              {/* Label */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['h3 bold']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['h3 bold']
                                      .style,
                                    { marginBottom: 10 }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Reason For Leaving'}
                              </Text>

                              <View
                                style={StyleSheet.applyWidth(
                                  { flex: 1, flexDirection: 'column' },
                                  dimensions.width
                                )}
                              >
                                {/* Text Area Reason for Leaving */}
                                <TextInput
                                  autoCorrect={true}
                                  changeTextDelay={500}
                                  multiline={true}
                                  numberOfLines={4}
                                  onChangeText={newTextAreaReasonForLeavingValue => {
                                    try {
                                      setInput_ReasonForLeaving(
                                        newTextAreaReasonForLeavingValue
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  textAlignVertical={'top'}
                                  webShowOutline={true}
                                  {...GlobalStyles.TextInputStyles(theme)[
                                    'Text Area'
                                  ].props}
                                  placeholder={input_ReasonForLeaving}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextInputStyles(theme)[
                                      'Text Area'
                                    ].style,
                                    dimensions.width
                                  )}
                                  value={input_ReasonForLeaving}
                                />
                              </View>
                            </View>
                          </>
                        );
                      }}
                    </XanoJobzStoryApi.FetchGetASingleJobzStoryGET>
                  </View>
                </View>
                {/* UPDATE BUTTON */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  {/* Button_Update */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          (
                            await XanoJobzStoryApi.updateExistingJobzStoryPATCH(
                              Constants,
                              {
                                company_id: ID_company,
                                contract: sv_ContractRole,
                                display_salary: sv_DisplaySalary,
                                end_date: input_EndDate,
                                external_title: input_ExternalTitle,
                                industry_id: input_Industry,
                                internal_title: input_InternalTitle,
                                joblevel_id: input_JobLevel,
                                jobtype_id: input_JobType,
                                jobzstory_id:
                                  props.route?.params?.ID_jobzStory ?? 46,
                                manages_others: sv_ManagesOthers,
                                nopeoplemanaged_id: input_NoPeopleManaged,
                                present_role: sv_PresentRole,
                                reason_for_leaving: input_ReasonForLeaving,
                                salary: input_LastSalary,
                                start_date: input_StartDate,
                                teamsize_id: input_TeamSize,
                                top_compamy_contribution:
                                  input_TopCompanyContribution,
                                top_job_contribution: input_TopJobContribution,
                                top_team_contribution:
                                  input_TopTeamContribution,
                              }
                            )
                          )?.json;
                          navigation.navigate('JobzStorySummaryScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                        .style,
                      dimensions.width
                    )}
                    title={'Update Information'}
                  />
                  {/* Button Cancel */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.goBack();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['button_CLEAR'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['button_CLEAR'].style,
                      dimensions.width
                    )}
                    title={'Cancel'}
                  />
                </View>
              </SimpleStyleKeyboardAwareScrollView>
            </View>
          )}
        </>
        {/* Change Dates */}
        <>
          {!(show_change_dates === true) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  borderColor: theme.colors['Divider'],
                  borderRadius: 18,
                  borderWidth: 1,
                  flex: 1,
                  marginLeft: 20,
                  marginRight: 20,
                  maxHeight: 300,
                  maxWidth: 300,
                  paddingLeft: 20,
                  paddingRight: 20,
                  position: 'absolute',
                  top: 80,
                },
                dimensions.width
              )}
            >
              {/* Start and End Dates */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingRight: 20 },
                  dimensions.width
                )}
              >
                {/* Start Date */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingTop: 6 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['h3'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['h3'].style,
                        { marginTop: null }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Start Date\n'}
                  </Text>
                  {/* Date Picker Start Date */}
                  <DatePicker
                    autoDismissKeyboard={true}
                    disabled={false}
                    label={'Date'}
                    leftIconMode={'inset'}
                    mode={'date'}
                    onDateChange={newDatePickerStartDateValue => {
                      try {
                        const valuegQNzytBj = newDatePickerStartDateValue;
                        setInput_StartDate(valuegQNzytBj);
                        const newStartDate = valuegQNzytBj;
                        setNewStartDate(newDatePickerStartDateValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    type={'solid'}
                    {...GlobalStyles.DatePickerStyles(theme)['date_picker']
                      .props}
                    date={input_StartDate}
                    hideLabel={true}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.DatePickerStyles(theme)['date_picker'].style,
                      dimensions.width
                    )}
                  />
                </View>
                {/* End Date */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 6, paddingBottom: 6 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['h3'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['h3'].style,
                      dimensions.width
                    )}
                  >
                    {'EndDate\n'}
                  </Text>
                  {/* Date Picker End Date */}
                  <DatePicker
                    autoDismissKeyboard={true}
                    disabled={false}
                    label={'Date'}
                    leftIconMode={'inset'}
                    mode={'date'}
                    onDateChange={newDatePickerEndDateValue => {
                      try {
                        const valuefXEdfviH = newDatePickerEndDateValue;
                        setInput_EndDate(valuefXEdfviH);
                        const newEndDate = valuefXEdfviH;
                        setNewEndDate(newDatePickerEndDateValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    type={'solid'}
                    {...GlobalStyles.DatePickerStyles(theme)['date_picker']
                      .props}
                    date={input_EndDate}
                    hideLabel={true}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.DatePickerStyles(theme)['date_picker'].style,
                      dimensions.width
                    )}
                  />
                </View>
                {/* Update Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginTop: 10 },
                    dimensions.width
                  )}
                >
                  {/* Button_Update_Dates */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          /* hidden 'Set Variable' action */
                          /* hidden 'Set Variable' action */
                          (
                            await XanoJobzStoryApi.updateStartAndEndDatesInJobzStoryPATCH(
                              Constants,
                              {
                                end_date: input_EndDate,
                                jobzStory_id:
                                  props.route?.params?.ID_jobzStory ?? 46,
                                start_date: input_StartDate,
                              }
                            )
                          )?.json;
                          setShow_view_Container(true);
                          setShow_change_dates(false);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                          .style,
                        { marginBottom: 20, maxWidth: null, minWidth: null }
                      ),
                      dimensions.width
                    )}
                    title={'Update Dates'}
                  />
                </View>
              </View>
            </View>
          )}
        </>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzStoryUpdateExisitngScreen);
