import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzStoryApi from '../apis/XanoJobzStoryApi.js';
import * as XanoOptionSets$DropdownsApi from '../apis/XanoOptionSets$DropdownsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import getPickerOptions from '../global-functions/getPickerOptions';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  DatePicker,
  Icon,
  Picker,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const JobzStoryEducationUpdateExistingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [EducationLevelOptions, setEducationLevelOptions] = React.useState('');
  const [ID_education, setID_education] = React.useState(0);
  const [ID_educationLevel, setID_educationLevel] = React.useState(0);
  const [ID_insitution, setID_insitution] = React.useState(0);
  const [ID_studyTitle, setID_studyTitle] = React.useState(0);
  const [LABELStudyTitle, setLABELStudyTitle] = React.useState(
    'What was the title of your study'
  );
  const [LABEL_EducationLevel, setLABEL_EducationLevel] = React.useState(
    'what level of education did you achieve'
  );
  const [LABEL_FindInsitutionName, setLABEL_FindInsitutionName] =
    React.useState('Find your Institution');
  const [LABEL_FindLocation, setLABEL_FindLocation] = React.useState('');
  const [LABEL_InstitutionName, setLABEL_InstitutionName] = React.useState(
    'what was the name of your educational institution'
  );
  const [datepicker_GraduationDate, setDatepicker_GraduationDate] =
    React.useState(0);
  const [input_InstitutionName, setInput_InstitutionName] = React.useState('');
  const [input_StudyTitle, setInput_StudyTitle] = React.useState('');
  const [isEditingInstituion, setIsEditingInstituion] = React.useState(false);
  const [isEdittingStudy, setIsEdittingStudy] = React.useState(false);
  const [pv_EducationLevel, setPv_EducationLevel] = React.useState('');
  const [searchInstitutionValue, setSearchInstitutionValue] =
    React.useState('');
  const [searchStudyValue, setSearchStudyValue] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setID_education(props.route?.params?.ID_education ?? 1);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={true}>
      {/* Heading Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['Primary 4'],
            justifyContent: 'space-around',
          },
          dimensions.width
        )}
      >
        {/* Heading  */}
        <View
          style={StyleSheet.applyWidth(
            { margin: 20, marginBottom: 20 },
            dimensions.width
          )}
        >
          {/* Heading */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['h2'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['h2'].style, {
                alignSelf: 'flex-start',
              }),
              dimensions.width
            )}
          >
            {'Edit your Education'}
          </Text>
          {/* description */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Text'].style,
              dimensions.width
            )}
          >
            {'Edit the information for your education history.'}
          </Text>
        </View>
      </View>
      {/* BODY CONTAINER */}
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, justifyContent: 'space-between' },
          dimensions.width
        )}
      >
        <XanoJobzStoryApi.FetchGetSingleEducationHistoryGET
          education_history_id={ID_education}
          handlers={{
            onData: fetchData => {
              try {
                const valuehDesLnwl = props.route?.params?.ID_education ?? 1;
                setID_education(valuehDesLnwl);
                const selected_educationID = valuehDesLnwl;
                setSearchInstitutionValue(
                  fetchData?._institution?.institution_name
                );
                setPv_EducationLevel(
                  fetchData?._os_educationlevel?.education_level
                );
                setSearchStudyValue(fetchData?._study_title?.study_title);
                setDatepicker_GraduationDate(
                  new Date(fetchData?.year_completed)
                );
                setID_insitution(fetchData?._institution?.id);
                setID_studyTitle(fetchData?._study_title?.id);
                setID_educationLevel(fetchData?._os_educationlevel?.id);
              } catch (err) {
                console.error(err);
              }
            },
          }}
        >
          {({ loading, error, data, refetchGetSingleEducationHistory }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                {/* Main Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { gap: 10, margin: 20 },
                    dimensions.width
                  )}
                >
                  {/* Input Instituion Name */}
                  <View>
                    {/* Institution Add Block */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* LABEL Instituion Name */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Institution*'}
                      </Text>

                      <Pressable
                        onPress={() => {
                          try {
                            navigation.navigate('AddNewInstitutionScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        {/* Add Instituion */}
                        <Icon
                          size={24}
                          {...GlobalStyles.IconStyles(theme)['icon_secondary']
                            .props}
                          name={'AntDesign/plus'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.IconStyles(theme)['icon_secondary']
                              .style,
                            dimensions.width
                          )}
                        />
                      </Pressable>
                    </View>
                    {/* Find Insitution */}
                    <View>
                      {/* Input Instituion Name */}
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newInputInstituionNameValue => {
                          try {
                            setSearchInstitutionValue(
                              newInputInstituionNameValue
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        onChangeTextDelayed={newInputInstituionNameValue => {
                          try {
                            if (newInputInstituionNameValue?.length > 0) {
                            } else {
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        onFocus={() => {
                          try {
                            setIsEditingInstituion(true);
                            setSearchInstitutionValue('');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Enter a value...'}
                        webShowOutline={true}
                        {...GlobalStyles.TextInputStyles(theme)['InputField']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['InputField']
                              .style,
                            { borderRadius: 18 }
                          ),
                          dimensions.width
                        )}
                        value={searchInstitutionValue}
                      />
                      <>
                        {!(
                          searchInstitutionValue?.length && isEditingInstituion
                        ) ? null : (
                          <XanoOptionSets$DropdownsApi.FetchDropDownInstituionGET
                            handlers={{
                              onData: fetchData => {
                                try {
                                  console.log();
                                } catch (err) {
                                  console.error(err);
                                }
                              },
                            }}
                            search={searchInstitutionValue}
                          >
                            {({
                              loading,
                              error,
                              data,
                              refetchDropDownInstituion,
                            }) => {
                              const fetchData = data?.json;
                              if (loading) {
                                return <ActivityIndicator />;
                              }

                              if (
                                error ||
                                data?.status < 200 ||
                                data?.status >= 300
                              ) {
                                return <ActivityIndicator />;
                              }

                              return (
                                <>
                                  {/* MESSAGE Add Your Institution */}
                                  <>
                                    {fetchData?.dd_institution
                                      ?.length ? null : (
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          'You will need to add your institution, click on +'
                                        }
                                      </Text>
                                    )}
                                  </>
                                  <>
                                    {!(
                                      searchInstitutionValue?.length > 2
                                    ) ? null : (
                                      <SimpleStyleFlatList
                                        data={fetchData?.dd_institution}
                                        horizontal={false}
                                        inverted={false}
                                        keyExtractor={(listData, index) =>
                                          listData?.id ??
                                          listData?.uuid ??
                                          index.toString()
                                        }
                                        keyboardShouldPersistTaps={'never'}
                                        listKey={'PzqcaH7A'}
                                        nestedScrollEnabled={false}
                                        numColumns={1}
                                        onEndReachedThreshold={0.5}
                                        renderItem={({ item, index }) => {
                                          const listData = item;
                                          return (
                                            <Pressable
                                              onPress={() => {
                                                try {
                                                  const valuepRrozgX7 =
                                                    listData?.institution_name;
                                                  setSearchInstitutionValue(
                                                    valuepRrozgX7
                                                  );
                                                  const newInstitutionResult =
                                                    valuepRrozgX7;
                                                  setInput_InstitutionName(
                                                    newInstitutionResult
                                                  );
                                                  setID_insitution(
                                                    listData?.id
                                                  );
                                                  setIsEditingInstituion(false);
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                            >
                                              {/* InstiutionName to Select */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.institution_name}
                                              </Text>
                                            </Pressable>
                                          );
                                        }}
                                        showsHorizontalScrollIndicator={true}
                                        showsVerticalScrollIndicator={true}
                                      />
                                    )}
                                  </>
                                </>
                              );
                            }}
                          </XanoOptionSets$DropdownsApi.FetchDropDownInstituionGET>
                        )}
                      </>
                    </View>
                  </View>
                  {/* Input Study Title */}
                  <View>
                    {/* Study Title Add Block */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* LABEL Study Title */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Study Title*'}
                      </Text>

                      <Pressable
                        onPress={() => {
                          try {
                            navigation.navigate('AddNewStudyTitleScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        {/* Add Study Title */}
                        <Icon
                          size={24}
                          {...GlobalStyles.IconStyles(theme)['icon_secondary']
                            .props}
                          name={'AntDesign/plus'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.IconStyles(theme)['icon_secondary']
                              .style,
                            dimensions.width
                          )}
                        />
                      </Pressable>
                    </View>
                    {/* Find Study Title */}
                    <View>
                      {/* Input Study Title */}
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newInputStudyTitleValue => {
                          try {
                            setSearchStudyValue(newInputStudyTitleValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        onChangeTextDelayed={newInputStudyTitleValue => {
                          try {
                            if (newInputStudyTitleValue?.length > 0) {
                            } else {
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        onFocus={() => {
                          try {
                            setIsEdittingStudy(true);
                            setSearchStudyValue('');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Enter a value...'}
                        webShowOutline={true}
                        {...GlobalStyles.TextInputStyles(theme)['InputField']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['InputField']
                              .style,
                            { borderRadius: 18 }
                          ),
                          dimensions.width
                        )}
                        value={searchStudyValue}
                      />
                      <>
                        {!(
                          searchStudyValue?.length && isEdittingStudy
                        ) ? null : (
                          <XanoOptionSets$DropdownsApi.FetchDropDownStudyTitleGET
                            handlers={{
                              onData: fetchData => {
                                try {
                                  console.log();
                                } catch (err) {
                                  console.error(err);
                                }
                              },
                            }}
                            search={searchStudyValue}
                          >
                            {({
                              loading,
                              error,
                              data,
                              refetchDropDownStudyTitle,
                            }) => {
                              const fetchData = data?.json;
                              if (loading) {
                                return <ActivityIndicator />;
                              }

                              if (
                                error ||
                                data?.status < 200 ||
                                data?.status >= 300
                              ) {
                                return <ActivityIndicator />;
                              }

                              return (
                                <>
                                  {/* MESSAGE Add Your Study Title */}
                                  <>
                                    {fetchData?.dd_studyTitle?.length ? null : (
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          'You will need to add your study title, click on +'
                                        }
                                      </Text>
                                    )}
                                  </>
                                  <>
                                    {!(searchStudyValue?.length > 2) ? null : (
                                      <SimpleStyleFlatList
                                        data={fetchData?.dd_studyTitle}
                                        horizontal={false}
                                        inverted={false}
                                        keyExtractor={(listData, index) =>
                                          listData?.id ??
                                          listData?.uuid ??
                                          index.toString()
                                        }
                                        keyboardShouldPersistTaps={'never'}
                                        listKey={'QhTGTdP3'}
                                        nestedScrollEnabled={false}
                                        numColumns={1}
                                        onEndReachedThreshold={0.5}
                                        renderItem={({ item, index }) => {
                                          const listData = item;
                                          return (
                                            <Pressable
                                              onPress={() => {
                                                try {
                                                  const valueGTaDju73 =
                                                    listData?.study_title;
                                                  setSearchStudyValue(
                                                    valueGTaDju73
                                                  );
                                                  const newStudyResult =
                                                    valueGTaDju73;
                                                  setInput_StudyTitle(
                                                    newStudyResult
                                                  );
                                                  setID_studyTitle(
                                                    listData?.id
                                                  );
                                                  setIsEdittingStudy(false);
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                            >
                                              {/* Study Title to Select */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {listData?.study_title}
                                              </Text>
                                            </Pressable>
                                          );
                                        }}
                                        showsHorizontalScrollIndicator={true}
                                        showsVerticalScrollIndicator={true}
                                      />
                                    )}
                                  </>
                                </>
                              );
                            }}
                          </XanoOptionSets$DropdownsApi.FetchDropDownStudyTitleGET>
                        )}
                      </>
                    </View>
                  </View>
                  {/* Input Education Level */}
                  <View>
                    {/* LABEL Education Level */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['label'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['label'].style,
                        dimensions.width
                      )}
                    >
                      {'Education Level*'}
                    </Text>

                    <XanoOptionSets$DropdownsApi.FetchOPTIONSETGetEducationLevelsGET
                      handlers={{
                        onData: fetchData => {
                          try {
                            const educationLevelResult = getPickerOptions(
                              fetchData?.dd_educationLevel,
                              'education_level'
                            );
                            setEducationLevelOptions(educationLevelResult);
                          } catch (err) {
                            console.error(err);
                          }
                        },
                      }}
                    >
                      {({
                        loading,
                        error,
                        data,
                        refetchOPTIONSETGetEducationLevels,
                      }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <Picker
                            autoDismissKeyboard={true}
                            dropDownBackgroundColor={theme.colors.background}
                            dropDownBorderColor={theme.colors.divider}
                            dropDownBorderRadius={8}
                            dropDownBorderWidth={1}
                            dropDownTextColor={theme.colors.strong}
                            iconSize={24}
                            leftIconMode={'inset'}
                            onValueChange={newPickerValue => {
                              try {
                                const valuePGcfB2v1 = newPickerValue;
                                setPv_EducationLevel(valuePGcfB2v1);
                                const newEducationLevelResult = valuePGcfB2v1;
                                const valueTvBctWFO = newEducationLevelResult;
                                setID_educationLevel(valueTvBctWFO);
                                const aaaa = valueTvBctWFO;
                                console.log(aaaa);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            selectedIconColor={theme.colors.strong}
                            selectedIconName={'Feather/check'}
                            selectedIconSize={20}
                            type={'solid'}
                            {...GlobalStyles.PickerStyles(theme)['picker']
                              .props}
                            mode={'dropdown-modal'}
                            options={EducationLevelOptions}
                            placeholder={'Please choose'}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.PickerStyles(theme)['picker'].style,
                              dimensions.width
                            )}
                            value={pv_EducationLevel}
                          />
                        );
                      }}
                    </XanoOptionSets$DropdownsApi.FetchOPTIONSETGetEducationLevelsGET>
                  </View>
                  {/* Graduation Date */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginRight: 20 },
                      dimensions.width
                    )}
                  >
                    {/* LABEL Graduation Date */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['label'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['label'].style,
                        dimensions.width
                      )}
                    >
                      {'Graduation Date*'}
                    </Text>
                    <DatePicker
                      autoDismissKeyboard={true}
                      disabled={false}
                      label={'Date'}
                      leftIconMode={'inset'}
                      mode={'date'}
                      onDateChange={newDatePickerValue => {
                        try {
                          setDatepicker_GraduationDate(newDatePickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      type={'solid'}
                      {...GlobalStyles.DatePickerStyles(theme)['date_picker']
                        .props}
                      date={datepicker_GraduationDate}
                      hideLabel={true}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.DatePickerStyles(theme)['date_picker']
                          .style,
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
              </>
            );
          }}
        </XanoJobzStoryApi.FetchGetSingleEducationHistoryGET>
      </View>
      {/* Bottom Button */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-end',
            margin: 20,
          },
          dimensions.width
        )}
      >
        {/* Button_Update */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            const handler = async () => {
              try {
                const jobzStoryResponse = (
                  await XanoJobzStoryApi.updateEducationRecordPATCH(Constants, {
                    education_history_id: ID_education,
                    instition_id: ID_insitution,
                    os_educationLevel_id: ID_educationLevel,
                    study_title_id: ID_studyTitle,
                    year_completed: datepicker_GraduationDate,
                  })
                )?.json;
                navigation.navigate('JobzStorySummaryScreen');
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button_Secondary'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button_Secondary'].style,
              { fontFamily: 'Poppins_700Bold' }
            ),
            dimensions.width
          )}
          title={'Update Information'}
        />
        {/* Button_Cancel */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          {...GlobalStyles.ButtonStyles(theme)['Button_Cancel'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['Button_Cancel'].style,
              {
                borderRadius: 100,
                fontFamily: 'Poppins_700Bold',
                maxWidth: [
                  { minWidth: Breakpoints.Laptop, value: 300 },
                  { minWidth: Breakpoints.Mobile, value: 300 },
                ],
                minWidth: [
                  { minWidth: Breakpoints.Laptop, value: 300 },
                  { minWidth: Breakpoints.Mobile, value: 300 },
                ],
              }
            ),
            dimensions.width
          )}
          title={'Cancel'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzStoryEducationUpdateExistingScreen);
