import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const createNewEducationHistoryPOST = async (
  Constants,
  { education_level, institution, study_title, year_completed },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/education/addNew`;
  const options = {
    body: JSON.stringify({
      os_educationlevel_id: education_level,
      institution_id: institution,
      study_title_id: study_title,
      year_completed: year_completed,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateNewEducationHistoryPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryCreateNewEducationHistoryPOST', args],
    () => createNewEducationHistoryPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobzStoryCreateNewEducationHistoryPOSTS',
        ]),
    }
  );
};

export const FetchCreateNewEducationHistoryPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  education_level,
  institution,
  study_title,
  year_completed,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCreateNewEducationHistoryPOST(
    { education_level, institution, study_title, year_completed },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreateNewEducationHistory: refetch,
  });
};

export const createNewJobzStoryPOST = async (
  Constants,
  {
    company,
    contract,
    end_date,
    external_title,
    internal_title,
    present_role,
    start_date,
  },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/jobzstory/addNew`;
  const options = {
    body: JSON.stringify({
      contract: contract,
      external_title: external_title,
      internal_title: internal_title,
      start_date: start_date,
      end_date: end_date,
      company_id: company,
      present_role: present_role,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateNewJobzStoryPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryCreateNewJobzStoryPOST', args],
    () => createNewJobzStoryPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoJobzStoryCreateNewJobzStoryPOSTS']),
    }
  );
};

export const FetchCreateNewJobzStoryPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  company,
  contract,
  end_date,
  external_title,
  internal_title,
  present_role,
  start_date,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCreateNewJobzStoryPOST(
    {
      company,
      contract,
      end_date,
      external_title,
      internal_title,
      present_role,
      start_date,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateNewJobzStory: refetch });
};

export const deleteEducationHistoryDELETE = async (
  Constants,
  { education_history_id },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/education/${encodeQueryParam(
    education_history_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteEducationHistoryDELETE = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryDeleteEducationHistoryDELETE', args],
    () => deleteEducationHistoryDELETE(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobzStoryDeleteEducationHistoryDELETES',
        ]),
    }
  );
};

export const deleteAJobzStoryDELETE = async (
  Constants,
  { jobzstory_id },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/jobzstory/${encodeQueryParam(
    jobzstory_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteAJobzStoryDELETE = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryDeleteAJobzStoryDELETE', args],
    () => deleteAJobzStoryDELETE(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoJobzStoryDeleteAJobzStoryDELETES']),
    }
  );
};

export const getEducationHistoriesForSingleUserGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/education/education_histories_for_single_user`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetEducationHistoriesForSingleUserGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryGetEducationHistoriesForSingleUserGET', args],
    () => getEducationHistoriesForSingleUserGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobzStoryGetEducationHistoriesForSingleUserGETS',
        ]),
    }
  );
};

export const FetchGetEducationHistoriesForSingleUserGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetEducationHistoriesForSingleUserGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetEducationHistoriesForSingleUser: refetch,
  });
};

export const getSingleEducationHistoryGET = async (
  Constants,
  { education_history_id },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/education/education_history/${encodeQueryParam(
    education_history_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetSingleEducationHistoryGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryGetSingleEducationHistoryGET', args],
    () => getSingleEducationHistoryGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobzStoryGetSingleEducationHistoryGETS',
        ]),
    }
  );
};

export const FetchGetSingleEducationHistoryGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  education_history_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSingleEducationHistoryGET(
    { education_history_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetSingleEducationHistory: refetch,
  });
};

export const getASingleJobzStoryGET = async (
  Constants,
  { jobzstory_id },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/jobzstory/${encodeQueryParam(
    jobzstory_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetASingleJobzStoryGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryGetASingleJobzStoryGET', args],
    () => getASingleJobzStoryGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoJobzStoryGetASingleJobzStoryGETS']),
    }
  );
};

export const FetchGetASingleJobzStoryGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  jobzstory_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetASingleJobzStoryGET(
    { jobzstory_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetASingleJobzStory: refetch,
  });
};

export const getJobzStoriesForASingleUserGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/jobzstories_single_user`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetJobzStoriesForASingleUserGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryGetJobzStoriesForASingleUserGET', args],
    () => getJobzStoriesForASingleUserGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobzStoryGetJobzStoriesForASingleUserGETS',
        ]),
    }
  );
};

export const FetchGetJobzStoriesForASingleUserGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetJobzStoriesForASingleUserGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetJobzStoriesForASingleUser: refetch,
  });
};

export const jOBZSTORYUpdateOnePATCH = async (
  Constants,
  {
    display_salary,
    industry,
    job_level,
    job_type,
    jobzstory_id,
    manages_others,
    no_people_managed,
    salary,
    team_size,
  },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/jobzstory/update_one`;
  const options = {
    body: JSON.stringify({
      job_type: job_type,
      job_level: job_level,
      industry: industry,
      manage_others: manages_others,
      no_people_managed: no_people_managed,
      team_size: team_size,
      display_salary: display_salary,
      salary: salary,
      jobzstory_id: jobzstory_id,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useJOBZSTORYUpdateOnePATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryJOBZSTORYUpdateOnePATCH', args],
    () => jOBZSTORYUpdateOnePATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobzStoryJOBZSTORYUpdateOnePATCHES',
        ]),
    }
  );
};

export const jOBZSTORYUpdateTwoPATCH = async (
  Constants,
  {
    jobzstory_id,
    reason_for_leaving,
    top_company_contribution,
    top_job_contribution,
    top_team_contribution,
  },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/jobzstory/update_two`;
  const options = {
    body: JSON.stringify({
      top_job_contribution: top_job_contribution,
      top_team_contribution: top_team_contribution,
      top_company_contribution: top_company_contribution,
      reason_for_leaving: reason_for_leaving,
      jobzstory_id: jobzstory_id,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useJOBZSTORYUpdateTwoPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryJOBZSTORYUpdateTwoPATCH', args],
    () => jOBZSTORYUpdateTwoPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobzStoryJOBZSTORYUpdateTwoPATCHES',
        ]),
    }
  );
};

export const updateEducationRecordPATCH = async (
  Constants,
  {
    education_history_id,
    instition_id,
    os_educationLevel_id,
    study_title_id,
    year_completed,
  },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/education`;
  const options = {
    body: JSON.stringify({
      education_history_id: education_history_id,
      os_educationlevel_id: os_educationLevel_id,
      institution_id: instition_id,
      study_title_id: study_title_id,
      year_completed: year_completed,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateEducationRecordPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryUpdateEducationRecordPATCH', args],
    () => updateEducationRecordPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobzStoryUpdateEducationRecordPATCHES',
        ]),
    }
  );
};

export const updateExistingJobzStoryPATCH = async (
  Constants,
  {
    company_id,
    contract,
    display_salary,
    end_date,
    external_title,
    industry_id,
    internal_title,
    joblevel_id,
    jobtype_id,
    jobzstory_id,
    manages_others,
    nopeoplemanaged_id,
    present_role,
    reason_for_leaving,
    salary,
    start_date,
    teamsize_id,
    top_compamy_contribution,
    top_job_contribution,
    top_team_contribution,
  },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/jobzstory/update_existing`;
  const options = {
    body: JSON.stringify({
      internal_title: internal_title,
      external_title: external_title,
      display_salary: display_salary,
      company_id: company_id,
      contract: contract,
      reason_for_leaving: reason_for_leaving,
      os_industry_id: industry_id,
      salary: salary,
      start_date: start_date,
      end_date: end_date,
      present_role: present_role,
      os_teamsize_id: teamsize_id,
      top_company_contribution: top_compamy_contribution,
      top_job_contribution: top_job_contribution,
      top_team_contribution: top_team_contribution,
      os_jobtype_id: jobtype_id,
      os_joblevel_id: joblevel_id,
      os_nopeoplemanaged_id: nopeoplemanaged_id,
      manages_others: manages_others,
      jobzstory_id: jobzstory_id,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateExistingJobzStoryPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryUpdateExistingJobzStoryPATCH', args],
    () => updateExistingJobzStoryPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobzStoryUpdateExistingJobzStoryPATCHES',
        ]),
    }
  );
};

export const updateStartAndEndDatesInJobzStoryPATCH = async (
  Constants,
  { end_date, jobzStory_id, start_date },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:JJONktLU/jobzStory/updateDates`;
  const options = {
    body: JSON.stringify({
      jobzStory_id: jobzStory_id,
      start_date: start_date,
      end_date: end_date,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateStartAndEndDatesInJobzStoryPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobzStoryUpdateStartAndEndDatesInJobzStoryPATCH', args],
    () => updateStartAndEndDatesInJobzStoryPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobzStoryUpdateStartAndEndDatesInJobzStoryPATCHES',
        ]),
    }
  );
};
