import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoOptionSets$DropdownsApi from '../apis/XanoOptionSets$DropdownsApi.js';
import * as XanoRecruiterApi from '../apis/XanoRecruiterApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import getPickerOptions from '../global-functions/getPickerOptions';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  MultiSelectPicker,
  Picker,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const Onboarding2RecruiterScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [INPUT_openJobs, setINPUT_openJobs] = React.useState('');
  const [MS_jobLevel, setMS_jobLevel] = React.useState([]);
  const [MS_recruiterSpecialty, setMS_recruiterSpecialty] = React.useState([]);
  const [PV_averageOpenings, setPV_averageOpenings] = React.useState('');
  const [PV_averageTimetoFill, setPV_averageTimetoFill] = React.useState('');
  const [PV_teamSize, setPV_teamSize] = React.useState('');
  const [linkedInURLValue, setLinkedInURLValue] = React.useState('');
  const [option_RecruiterSpecialty, setOption_RecruiterSpecialty] =
    React.useState([]);
  const [option_averageOpenings, setOption_averageOpenings] = React.useState(
    []
  );
  const [option_averageTimetoFill, setOption_averageTimetoFill] =
    React.useState([]);
  const [option_recruitingLevel, setOption_recruitingLevel] = React.useState(
    []
  );
  const [option_teamSize, setOption_teamSize] = React.useState([]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, justifyContent: 'space-between', margin: 20 },
          dimensions.width
        )}
      >
        {/* MAIN CONTAINER */}
        <View>
          {/* Heading Section */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', marginBottom: 10 },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={Images.ElephantLookingLeftJobzMaverickFinal}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { marginBottom: 10 }
                ),
                dimensions.width
              )}
            />
          </View>
          {/* Description */}
          <View>
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Text'].style,
                dimensions.width
              )}
            >
              {
                'This information will help us cater the platform more directly to how you want to use it.'
              }
            </Text>
          </View>
          {/* BODY CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              { gap: 10, marginTop: 10 },
              dimensions.width
            )}
          >
            {/* Block Heading */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['h2'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['h2'].style, {
                  marginBottom: 10,
                }),
                dimensions.width
              )}
            >
              {'Tell us even a liitle bit more . . .'}
            </Text>
            {/* LinkedIn URL */}
            <View>
              {/* LABEL LinkedIn URL */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['label'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['label'].style,
                  dimensions.width
                )}
              >
                {'Your LinkedIn Profile URL'}
              </Text>
              {/* LinkedIn URL */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newLinkedInURLValue => {
                  try {
                    setLinkedInURLValue(newLinkedInURLValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Enter a value...'}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    {
                      borderRadius: 100,
                      paddingBottom: 10,
                      paddingLeft: 16,
                      paddingTop: 10,
                    }
                  ),
                  dimensions.width
                )}
                value={linkedInURLValue}
              />
            </View>
            {/* Open Jobs */}
            <View>
              {/* LABEL Open Jobs */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['label'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['label'].style,
                  dimensions.width
                )}
              >
                {'Number of Current Open Jobs'}
              </Text>
              {/* Open Jobs */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newOpenJobsValue => {
                  try {
                    setINPUT_openJobs(newOpenJobsValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                placeholder={'Enter a value...'}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    {
                      borderRadius: 100,
                      paddingBottom: 10,
                      paddingLeft: 16,
                      paddingTop: 10,
                    }
                  ),
                  dimensions.width
                )}
                value={INPUT_openJobs}
              />
            </View>
            {/* Dropdown Option Block */}
            <View>
              <XanoOptionSets$DropdownsApi.FetchOPTIONSETForRecruiterProfileGET
                handlers={{
                  onData: fetchData => {
                    try {
                      const averageOpenings = getPickerOptions(
                        fetchData?.dd_averageNoJobs,
                        'average_no_jobs'
                      );
                      setOption_averageOpenings(averageOpenings);
                      const averageTiemtoFill = getPickerOptions(
                        fetchData?.dd_averageTimeToFill,
                        'average_time_to_fill'
                      );
                      setOption_averageTimetoFill(averageTiemtoFill);
                      const recruiterSpecialty = getPickerOptions(
                        fetchData?.dd_recruitingSpecialty,
                        'speciality'
                      );
                      setOption_RecruiterSpecialty(recruiterSpecialty);
                      const recruiterLevel = getPickerOptions(
                        fetchData?.dd_jobLevel,
                        'job_level'
                      );
                      setOption_recruitingLevel(recruiterLevel);
                      const teamSize = getPickerOptions(
                        fetchData?.dd_teamSize,
                        'teamSize'
                      );
                      setOption_teamSize(teamSize);
                    } catch (err) {
                      console.error(err);
                    }
                  },
                }}
              >
                {({
                  loading,
                  error,
                  data,
                  refetchOPTIONSETForRecruiterProfile,
                }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* Average Openings */}
                      <View>
                        {/* LABEL Average Openings */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['label'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['label'].style,
                            dimensions.width
                          )}
                        >
                          {'Average Openings'}
                        </Text>
                        <Picker
                          autoDismissKeyboard={true}
                          dropDownBackgroundColor={theme.colors.background}
                          dropDownBorderColor={theme.colors.divider}
                          dropDownBorderRadius={8}
                          dropDownBorderWidth={1}
                          dropDownTextColor={theme.colors.strong}
                          iconSize={24}
                          leftIconMode={'inset'}
                          onValueChange={newPickerValue => {
                            try {
                              setPV_averageOpenings(newPickerValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          selectedIconColor={theme.colors.strong}
                          selectedIconName={'Feather/check'}
                          selectedIconSize={20}
                          type={'solid'}
                          {...GlobalStyles.PickerStyles(theme)['picker'].props}
                          mode={'dropdown-modal'}
                          options={option_averageOpenings}
                          placeholder={'Select an option'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.PickerStyles(theme)['picker'].style,
                            dimensions.width
                          )}
                          value={PV_averageOpenings}
                        />
                      </View>
                      {/* Average Time to Fill */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 8 },
                          dimensions.width
                        )}
                      >
                        {/* LABEL Average Time to Fill */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['label'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['label'].style,
                            dimensions.width
                          )}
                        >
                          {'Average Time to Fill\n'}
                        </Text>
                        <Picker
                          autoDismissKeyboard={true}
                          dropDownBackgroundColor={theme.colors.background}
                          dropDownBorderColor={theme.colors.divider}
                          dropDownBorderRadius={8}
                          dropDownBorderWidth={1}
                          dropDownTextColor={theme.colors.strong}
                          iconSize={24}
                          leftIconMode={'inset'}
                          onValueChange={newPickerValue => {
                            try {
                              setPV_averageTimetoFill(newPickerValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          placeholder={'Select an option'}
                          selectedIconColor={theme.colors.strong}
                          selectedIconName={'Feather/check'}
                          selectedIconSize={20}
                          type={'solid'}
                          {...GlobalStyles.PickerStyles(theme)['picker'].props}
                          mode={'dropdown-modal'}
                          options={option_averageTimetoFill}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.PickerStyles(theme)['picker'].style,
                            dimensions.width
                          )}
                          value={PV_averageTimetoFill}
                        />
                      </View>
                      {/* Recruiting Specialty */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 8, zIndex: 99 },
                          dimensions.width
                        )}
                      >
                        {/* LABEL Recruiting Specialty */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['label'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['label'].style,
                            dimensions.width
                          )}
                        >
                          {'Recruiting Specialty'}
                        </Text>
                        <MultiSelectPicker
                          autoDismissKeyboard={true}
                          dropDownBackgroundColor={theme.colors.background}
                          dropDownBorderColor={theme.colors.divider}
                          dropDownBorderRadius={8}
                          dropDownBorderWidth={1}
                          dropDownTextColor={theme.colors.strong}
                          iconSize={24}
                          leftIconMode={'inset'}
                          onValueChange={newMultiSelectPickerValue => {
                            try {
                              setMS_recruiterSpecialty(
                                newMultiSelectPickerValue
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          placeholder={'Select an option'}
                          selectedIconColor={theme.colors.strong}
                          selectedIconName={'Feather/check'}
                          selectedIconSize={20}
                          type={'solid'}
                          {...GlobalStyles.MultiSelectPickerStyles(theme)[
                            'multi_select_Picker'
                          ].props}
                          options={option_RecruiterSpecialty}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.MultiSelectPickerStyles(theme)[
                              'multi_select_Picker'
                            ].style,
                            dimensions.width
                          )}
                          value={MS_recruiterSpecialty}
                        />
                      </View>
                      {/* Job Level */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: 8 },
                          dimensions.width
                        )}
                      >
                        {/* LABEL Job Level */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['label'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['label'].style,
                            dimensions.width
                          )}
                        >
                          {'Job Level'}
                        </Text>
                        <MultiSelectPicker
                          autoDismissKeyboard={true}
                          dropDownBackgroundColor={theme.colors.background}
                          dropDownBorderColor={theme.colors.divider}
                          dropDownBorderRadius={8}
                          dropDownBorderWidth={1}
                          dropDownTextColor={theme.colors.strong}
                          iconSize={24}
                          leftIconMode={'inset'}
                          onValueChange={newMultiSelectPickerValue => {
                            try {
                              setMS_jobLevel(newMultiSelectPickerValue);
                              /* hidden 'Conditional Stop' action */
                              console.log(newMultiSelectPickerValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          placeholder={'Select an option'}
                          selectedIconColor={theme.colors.strong}
                          selectedIconName={'Feather/check'}
                          selectedIconSize={20}
                          type={'solid'}
                          {...GlobalStyles.MultiSelectPickerStyles(theme)[
                            'multi_select_Picker'
                          ].props}
                          options={option_recruitingLevel}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.MultiSelectPickerStyles(theme)[
                              'multi_select_Picker'
                            ].style,
                            dimensions.width
                          )}
                          value={MS_jobLevel}
                        />
                      </View>
                    </>
                  );
                }}
              </XanoOptionSets$DropdownsApi.FetchOPTIONSETForRecruiterProfileGET>
            </View>
          </View>
        </View>
        {/* Next Step */}
        <View
          {...GlobalStyles.ViewStyles(theme)['bottomButton'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ViewStyles(theme)['bottomButton'].style,
              { alignContent: 'center' }
            ),
            dimensions.width
          )}
        >
          {/* Button_Next */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  (
                    await XanoRecruiterApi.oNBOARDINGRecruiterUpdateOnePATCH(
                      Constants,
                      {
                        averageTimetoFill: PV_averageTimetoFill,
                        average_no_of_openings: PV_averageOpenings,
                        joblevelIDS: MS_jobLevel,
                        linkedinURL: linkedInURLValue,
                        open_jobs: INPUT_openJobs,
                        specialtyIDS: MS_recruiterSpecialty,
                      }
                    )
                  )?.json;
                  navigation.navigate('Onboarding3RecruiterScreen', {
                    recruiter_ID: Constants['USER_ID'],
                  });
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['Secondary'],
                borderRadius: 100,
                fontFamily: 'Poppins_700Bold',
                maxWidth: [
                  { minWidth: Breakpoints.Laptop, value: 300 },
                  { minWidth: Breakpoints.Mobile, value: 300 },
                ],
                minWidth: [
                  { minWidth: Breakpoints.Laptop, value: 300 },
                  { minWidth: Breakpoints.Mobile, value: 300 },
                ],
              },
              dimensions.width
            )}
            title={'Next'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(Onboarding2RecruiterScreen);
