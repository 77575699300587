import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoSubscriptionsApi from '../apis/XanoSubscriptionsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, Icon, ScreenContainer, withTheme } from '@draftbit/ui';
import * as WebBrowser from 'expo-web-browser';
import { Image, ScrollView, Text, View } from 'react-native';

const PlanOptionsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [BB_stripePlanID, setBB_stripePlanID] = React.useState(
    'price_1P5bFUI4B63iBsXjs3N46QWa'
  );
  const [cc_m_stripePlanID, setCc_m_stripePlanID] = React.useState(
    'price_1OsbfAI4B63iBsXj2rL1HR7s'
  );

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['White'] },
        dimensions.width
      )}
    >
      <ScrollView
        bounces={true}
        horizontal={false}
        showsVerticalScrollIndicator={true}
        showsHorizontalScrollIndicator={false}
        style={StyleSheet.applyWidth({ flexGrow: 1 }, dimensions.width)}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              paddingBottom: 32,
              paddingLeft: 32,
              paddingRight: 32,
              paddingTop: 32,
            },
            dimensions.width
          )}
        >
          {/* app-logo */}
          <Image
            resizeMode={'contain'}
            source={Images.PurpleFavicon}
            style={StyleSheet.applyWidth(
              { height: 42, marginBottom: 32, width: 42 },
              dimensions.width
            )}
          />
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['h2'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['h2'].style, {
                textAlign: 'center',
                typography: theme.typography.headline3,
              }),
              dimensions.width
            )}
          >
            {'Choose a Plan'}
          </Text>
          {/* Baristas Brew */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background,
                borderBottomWidth: 1,
                borderColor: theme.colors.divider,
                borderLeftWidth: 1,
                borderRadius: theme.roundness,
                borderRightWidth: 1,
                borderTopWidth: 1,
                justifyContent: 'center',
                marginBottom: 12,
                marginTop: 24,
                paddingBottom: 24,
                paddingLeft: 24,
                paddingRight: 24,
                paddingTop: 24,
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Border Block */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  maxWidth: '80%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Column Left */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 34, marginRight: 14, width: 34 },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['icon_secondary'].props}
                  name={'MaterialCommunityIcons/arrow-right-drop-circle'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['icon_secondary'].style,
                    dimensions.width
                  )}
                />
              </View>
              {/* Column Right */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingRight: 34 },
                  dimensions.width
                )}
              >
                {/* Heading */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['small_text'].props}
                  ellipsizeMode={'tail'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['small_text'].style,
                      { typography: theme.typography.headline6 }
                    ),
                    dimensions.width
                  )}
                  textBreakStrategy={'highQuality'}
                >
                  {'Start Free'}
                </Text>
                {/* Subheading */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['small_text'].props}
                  ellipsizeMode={'tail'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['small_text'].style,
                      { typography: theme.typography.subtitle1 }
                    ),
                    dimensions.width
                  )}
                  textBreakStrategy={'highQuality'}
                >
                  {"Take a sip of the Barista's Brew"}
                </Text>
                {/* features-list */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 10 },
                    dimensions.width
                  )}
                >
                  {/* feature-item */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        marginBottom: 2,
                        marginTop: 2,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 24, marginRight: 8, width: 24 },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        {...GlobalStyles.IconStyles(theme)['icon_secondary']
                          .props}
                        name={'Entypo/dot-single'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.IconStyles(theme)['icon_secondary']
                            .style,
                          dimensions.width
                        )}
                      />
                    </View>

                    <View>
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['small_text'].props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['small_text'].style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {'JobzStory'}
                      </Text>
                    </View>
                  </View>
                  {/* feature-item */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        marginBottom: 2,
                        marginTop: 2,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 24, marginRight: 8, width: 24 },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        {...GlobalStyles.IconStyles(theme)['icon_secondary']
                          .props}
                        name={'Entypo/dot-single'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.IconStyles(theme)['icon_secondary']
                            .style,
                          dimensions.width
                        )}
                      />
                    </View>

                    <View>
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['small_text'].props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['small_text'].style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {'JobzZone'}
                      </Text>
                    </View>
                  </View>
                  {/* feature-item */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        marginBottom: 2,
                        marginTop: 2,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 24, marginRight: 8, width: 24 },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        {...GlobalStyles.IconStyles(theme)['icon_secondary']
                          .props}
                        name={'Entypo/dot-single'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.IconStyles(theme)['icon_secondary']
                            .style,
                          dimensions.width
                        )}
                      />
                    </View>

                    <View>
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['small_text'].props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['small_text'].style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {'Career Academy'}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {/* BUTTON CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flex: 1, paddingTop: 20 },
                dimensions.width
              )}
            >
              {/* Baristas Brew */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const bbSubscriptionResult = (
                        await XanoSubscriptionsApi.createBaristasBrewSubscriptionPOST(
                          Constants,
                          { user_id: props.route?.params?.user_id ?? '' }
                        )
                      )?.json;
                      navigation.navigate('LoginScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button_Secondary'].props}
                color={theme.colors.null}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ButtonStyles(theme)['Button_Secondary'].style,
                  dimensions.width
                )}
                title={"Barista's Brew"}
                type={'solid'}
              >
                {'Sign Up Free'}
              </Button>
            </View>
          </View>
          {/* Cafe Connoisseur */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.background,
                borderBottomWidth: 1,
                borderColor: theme.colors.divider,
                borderLeftWidth: 1,
                borderRadius: theme.roundness,
                borderRightWidth: 1,
                borderTopWidth: 1,
                justifyContent: 'center',
                marginBottom: 24,
                marginTop: 24,
                paddingBottom: 24,
                paddingLeft: 24,
                paddingRight: 24,
                paddingTop: 24,
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Border Block */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  marginBottom: 24,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Column Left */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 34, marginRight: 14, width: 34 },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...GlobalStyles.IconStyles(theme)['icon_secondary'].props}
                  name={'MaterialCommunityIcons/plus-circle'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['icon_secondary'].style,
                    dimensions.width
                  )}
                />
              </View>
              {/* Column Right */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingRight: 34 },
                  dimensions.width
                )}
              >
                {/* Heading */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['small_text'].props}
                  ellipsizeMode={'tail'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['small_text'].style,
                      {
                        fontFamily: 'Poppins_600SemiBold',
                        typography: theme.typography.headline6,
                      }
                    ),
                    dimensions.width
                  )}
                  textBreakStrategy={'highQuality'}
                >
                  {'Upgrade (less than $1 a day)'}
                </Text>
                {/* Subheading */}
                <Text
                  accessible={true}
                  ellipsizeMode={'tail'}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.strong,
                      typography: theme.typography.subtitle1,
                    },
                    dimensions.width
                  )}
                  textBreakStrategy={'highQuality'}
                >
                  {'Unlock additional modules'}
                </Text>
                {/* features-list */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: 10 },
                    dimensions.width
                  )}
                >
                  {/* feature-item */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        marginBottom: 2,
                        marginTop: 2,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 24, marginRight: 8, width: 24 },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        {...GlobalStyles.IconStyles(theme)['icon_secondary']
                          .props}
                        name={'Entypo/dot-single'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.IconStyles(theme)['icon_secondary']
                            .style,
                          dimensions.width
                        )}
                      />
                    </View>

                    <View>
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['small_text'].props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['small_text'].style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {'JobzForum'}
                      </Text>
                    </View>
                  </View>
                  {/* feature-item */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        marginBottom: 2,
                        marginTop: 2,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 24, marginRight: 8, width: 24 },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        {...GlobalStyles.IconStyles(theme)['icon_secondary']
                          .props}
                        name={'Entypo/dot-single'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.IconStyles(theme)['icon_secondary']
                            .style,
                          dimensions.width
                        )}
                      />
                    </View>

                    <View>
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['small_text'].props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['small_text'].style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {'JobzConnect'}
                      </Text>
                    </View>
                  </View>
                  {/* feature-item */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        marginBottom: 2,
                        marginTop: 2,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 24, marginRight: 8, width: 24 },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        {...GlobalStyles.IconStyles(theme)['icon_secondary']
                          .props}
                        name={'Entypo/dot-single'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.IconStyles(theme)['icon_secondary']
                            .style,
                          dimensions.width
                        )}
                      />
                    </View>

                    <View>
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['small_text'].props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['small_text'].style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {'JobzChat'}
                      </Text>
                    </View>
                  </View>
                  {/* feature-item */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                        marginBottom: 2,
                        marginTop: 2,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 24, marginRight: 8, width: 24 },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        {...GlobalStyles.IconStyles(theme)['icon_secondary']
                          .props}
                        name={'Entypo/dot-single'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.IconStyles(theme)['icon_secondary']
                            .style,
                          dimensions.width
                        )}
                      />
                    </View>

                    <View>
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['small_text'].props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['small_text'].style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {'JobzR8ter'}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {/* BUTTON CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'center',
                  flex: 1,
                  flexDirection: 'column',
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              {/* Button Cafe Connoisseur */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      await WebBrowser.openBrowserAsync(
                        'https://buy.stripe.com/00geXb2hm7N2gFi4gj'
                      );
                      navigation.navigate('LoginScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button_Secondary'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ButtonStyles(theme)['Button_Secondary'].style,
                  dimensions.width
                )}
                title={'Cafe Connoisseur'}
                type={'solid'}
              >
                {'Try Premium'}
              </Button>

              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['small_text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['small_text'].style,
                    {
                      alignSelf: 'center',
                      fontSize: 10,
                      marginTop: 8,
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'This will open up a new browser window.'}
              </Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

export default withTheme(PlanOptionsScreen);
