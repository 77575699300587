import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzStoryApi from '../apis/XanoJobzStoryApi.js';
import * as XanoOptionSets$DropdownsApi from '../apis/XanoOptionSets$DropdownsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  DatePicker,
  Icon,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  Switch,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const JobzStoryAddScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [ID_company, setID_company] = React.useState(0);
  const [datepicker_end_date, setDatepicker_end_date] = React.useState('');
  const [datepicker_start_date, setDatepicker_start_date] = React.useState('');
  const [input_CompanyName, setInput_CompanyName] = React.useState('');
  const [input_ExternalTitle, setInput_ExternalTitle] = React.useState('');
  const [input_InternalTitle, setInput_InternalTitle] = React.useState('');
  const [iseditingCompany, setIseditingCompany] = React.useState(false);
  const [labeI_InternalTitle, setLabeI_InternalTitle] = React.useState(
    'What is your role called inside your company'
  );
  const [label_ExternalTitle, setLabel_ExternalTitle] = React.useState(
    'What is your role called outside your company'
  );
  const [label_FindCompanyName, setLabel_FindCompanyName] =
    React.useState('Find your company');
  const [label_FindLocation, setLabel_FindLocation] =
    React.useState('Find your Location');
  const [searchCompanyValue, setSearchCompanyValue] = React.useState('');
  const [sv_contract, setSv_contract] = React.useState(false);
  const [sv_displaySalary, setSv_displaySalary] = React.useState(false);
  const [sv_manageOthers, setSv_manageOthers] = React.useState(false);
  const [sv_presentRole, setSv_presentRole] = React.useState(false);
  // This creates the drop down list for companies
  const customPickerOption = data => {
    return data.map(item => item.company_name + ', ' + item.location);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      /* hidden 'Set Variable' action */
      if (
        (props.route?.params?.NewCompanyResponse ?? null)?.company_name?.length
      ) {
        setSearchCompanyValue(
          (props.route?.params?.NewCompanyResponse ?? null)?.company_name
        );
        setID_company((props.route?.params?.NewCompanyResponse ?? null)?.id);
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={true}>
      {/* SCREEN CONTAINER */}
      <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
        {/* Heading Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Primary 4'],
              justifyContent: 'space-around',
            },
            dimensions.width
          )}
        >
          {/* Heading  */}
          <View
            style={StyleSheet.applyWidth(
              { margin: 20, marginBottom: 20 },
              dimensions.width
            )}
          >
            {/* Heading */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['h2'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['h2'].style, {
                  alignSelf: 'flex-start',
                }),
                dimensions.width
              )}
            >
              {'Add to your jobzStory'}
            </Text>
            {/* description */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Text'].style,
                dimensions.width
              )}
            >
              {'Complete the information to add to your jobzStory.'}
            </Text>
          </View>
        </View>
        {/* Body Container */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, justifyContent: 'space-between' },
            dimensions.width
          )}
        >
          {/* Main Container */}
          <View
            style={StyleSheet.applyWidth(
              { gap: 10, margin: 20 },
              dimensions.width
            )}
          >
            {/* Input Internal Title */}
            <View>
              {/* LABEL Internal Title */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['label'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['label'].style,
                  dimensions.width
                )}
              >
                {'Internal Title*'}
              </Text>
              {/* Input Internal Title */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newInputInternalTitleValue => {
                  try {
                    setInput_InternalTitle(newInputInternalTitleValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['InputField'].props}
                placeholder={labeI_InternalTitle.toString()}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['InputField'].style,
                  dimensions.width
                )}
                value={input_InternalTitle}
              />
            </View>
            {/* Input External Title */}
            <View>
              {/* LABEL External Title */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['label'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['label'].style,
                  dimensions.width
                )}
              >
                {'External Title'}
              </Text>
              {/* Input External Title */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newInputExternalTitleValue => {
                  try {
                    setInput_ExternalTitle(newInputExternalTitleValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['InputField'].props}
                placeholder={label_ExternalTitle.toString()}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['InputField'].style,
                  dimensions.width
                )}
                value={input_ExternalTitle}
              />
            </View>
            {/* Input Company Name */}
            <View>
              {/* Company Add Block */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                {/* LABEL Company Name */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['label'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['label'].style,
                    dimensions.width
                  )}
                >
                  {'Company*'}
                </Text>

                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('AddNewCompanyScreen', {
                        newCompanyResponse: searchCompanyValue,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Add Company */}
                  <Icon
                    size={24}
                    {...GlobalStyles.IconStyles(theme)['icon_secondary'].props}
                    name={'AntDesign/plus'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['icon_secondary'].style,
                      dimensions.width
                    )}
                  />
                </Pressable>
              </View>
              {/* Find Company */}
              <View>
                {/* Input Company Name */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newInputCompanyNameValue => {
                    try {
                      const value274JaIaZ = newInputCompanyNameValue;
                      setSearchCompanyValue(value274JaIaZ);
                      const newCompanyResult = value274JaIaZ;
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeTextDelayed={newInputCompanyNameValue => {
                    try {
                      if (newInputCompanyNameValue?.length > 0) {
                      } else {
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onFocus={() => {
                    try {
                      setIseditingCompany(true);
                      setSearchCompanyValue('');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['InputField'].props}
                  placeholder={label_FindCompanyName.toString()}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['InputField'].style,
                      { borderRadius: 18 }
                    ),
                    dimensions.width
                  )}
                  value={searchCompanyValue}
                />
                <>
                  {!(searchCompanyValue?.length && iseditingCompany) ? null : (
                    <XanoOptionSets$DropdownsApi.FetchDropDownCompanyGET
                      search={searchCompanyValue}
                    >
                      {({ loading, error, data, refetchDropDownCompany }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* MESSAGE Add Your Company */}
                            <>
                              {fetchData?.dd_company?.length ? null : (
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {
                                    'You will need to add your company, click on +'
                                  }
                                </Text>
                              )}
                            </>
                            <>
                              {!(searchCompanyValue?.length > 2) ? null : (
                                <SimpleStyleFlatList
                                  data={fetchData?.dd_company}
                                  horizontal={false}
                                  inverted={false}
                                  keyExtractor={(listData, index) =>
                                    listData?.id ??
                                    listData?.uuid ??
                                    index.toString()
                                  }
                                  keyboardShouldPersistTaps={'never'}
                                  listKey={'qpbROBdJ'}
                                  nestedScrollEnabled={false}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  renderItem={({ item, index }) => {
                                    const listData = item;
                                    return (
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            const value7yuazUFR =
                                              listData?.company_name;
                                            setSearchCompanyValue(
                                              value7yuazUFR
                                            );
                                            const newCompanyResult =
                                              value7yuazUFR;
                                            setInput_CompanyName(
                                              props.route?.params
                                                ?.NewCompanyResponse ?? null
                                            );
                                            setID_company(listData?.id);
                                            setIseditingCompany(false);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        {/* Company Name to Select */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.company_name}
                                          {', '}
                                          {listData?.Location}
                                        </Text>
                                      </Pressable>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                />
                              )}
                            </>
                          </>
                        );
                      }}
                    </XanoOptionSets$DropdownsApi.FetchDropDownCompanyGET>
                  )}
                </>
              </View>
            </View>
            {/* LABEL Contract Role */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
                dimensions.width
              )}
            >
              {/* LABEL Contract Role */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['label'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['label'].style,
                  dimensions.width
                )}
              >
                {'Contract Role'}
              </Text>
              {/* Contract Role */}
              <Switch
                onValueChange={newContractRoleValue => {
                  try {
                    setSv_contract(newContractRoleValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                value={sv_contract}
              />
            </View>
            {/* Present Role */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
                dimensions.width
              )}
            >
              {/* LABEL Present Role */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['label'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['label'].style,
                  dimensions.width
                )}
              >
                {'Present Role*'}
              </Text>
              {/* Present Role */}
              <Switch
                onValueChange={newPresentRoleValue => {
                  try {
                    const value3rC9s14t = newPresentRoleValue;
                    setSv_presentRole(value3rC9s14t);
                    const sv_presentRoleTrue = value3rC9s14t;
                  } catch (err) {
                    console.error(err);
                  }
                }}
                value={sv_presentRole}
              />
            </View>
            {/* Date Block */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 1, flexDirection: 'row', justifyContent: 'flex-start' },
                dimensions.width
              )}
            >
              {/* Start Date */}
              <View>
                {/* LABEL Start Date */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['label'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['label'].style,
                    dimensions.width
                  )}
                >
                  {'Start Date*'}
                </Text>
                <DatePicker
                  autoDismissKeyboard={true}
                  disabled={false}
                  hideLabel={false}
                  label={'Date'}
                  leftIconMode={'inset'}
                  mode={'date'}
                  onDateChange={newDatePickerValue => {
                    try {
                      setDatepicker_start_date(newDatePickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  type={'solid'}
                  {...GlobalStyles.DatePickerStyles(theme)['date_picker'].props}
                  date={datepicker_start_date}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DatePickerStyles(theme)['date_picker'].style,
                    dimensions.width
                  )}
                />
              </View>
              {/* End Date */}
              <>
                {sv_presentRole === true ? null : (
                  <View>
                    {/* LABEL End Date  */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['label'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['label'].style,
                        dimensions.width
                      )}
                    >
                      {'End Date'}
                    </Text>
                    <DatePicker
                      autoDismissKeyboard={true}
                      disabled={false}
                      hideLabel={false}
                      label={'Date'}
                      leftIconMode={'inset'}
                      mode={'date'}
                      onDateChange={newDatePickerValue => {
                        try {
                          setDatepicker_end_date(newDatePickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      type={'solid'}
                      {...GlobalStyles.DatePickerStyles(theme)['date_picker']
                        .props}
                      date={datepicker_end_date}
                      minimumDate={datepicker_start_date}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.DatePickerStyles(theme)['date_picker']
                          .style,
                        dimensions.width
                      )}
                    />
                  </View>
                )}
              </>
            </View>
          </View>
        </View>
        {/* Bottom Button */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              margin: 20,
            },
            dimensions.width
          )}
        >
          {/* Button_Next */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  const jobzStoryResult = (
                    await XanoJobzStoryApi.createNewJobzStoryPOST(Constants, {
                      company: ID_company,
                      contract: sv_contract,
                      end_date: datepicker_end_date,
                      external_title: input_ExternalTitle,
                      internal_title: input_InternalTitle,
                      present_role: sv_presentRole,
                      start_date: datepicker_start_date,
                    })
                  )?.json;
                  navigation.navigate('JobzStoryAddOneScreen', {
                    jobzStory_id: jobzStoryResult,
                  });
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['Button_Secondary'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ButtonStyles(theme)['Button_Secondary'].style,
              dimensions.width
            )}
            title={'Next'}
          />
          {/* Button Cancel */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
            {...GlobalStyles.ButtonStyles(theme)['button_CLEAR'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ButtonStyles(theme)['button_CLEAR'].style,
              dimensions.width
            )}
            title={'Cancel'}
          />
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzStoryAddScreen);
