import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzConnectApi from '../apis/XanoJobzConnectApi.js';
import * as XanoUsersApi from '../apis/XanoUsersApi.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import convertTime from '../global-functions/convertTime';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Switch,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const JobzConnectJobseekerUserProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [SV_jobzstories, setSV_jobzstories] = React.useState(false);
  const [cell, setCell] = React.useState('');
  const [city, setCity] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [photoUrl, setPhotoUrl] = React.useState('');
  const [showCell, setShowCell] = React.useState(false);
  const [showEmail, setShowEmail] = React.useState(false);
  const [state, setState] = React.useState('');
  const [switchValue, setSwitchValue] = React.useState(false);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { flex: 1, justifyContent: 'space-evenly' },
        dimensions.width
      )}
    >
      {/* back navigation */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
          },
          dimensions.width
        )}
      >
        {/* Back */}
        <IconButton
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          icon={'AntDesign/arrowleft'}
        />
      </View>
      {/* SCREEN CONTAINER */}
      <View
        style={StyleSheet.applyWidth(
          {
            flex: 1,
            justifyContent: [
              { minWidth: Breakpoints.Mobile, value: 'space-between' },
              { minWidth: Breakpoints.Tablet, value: 'space-between' },
            ],
          },
          dimensions.width
        )}
      >
        {/* Heading */}
        <View>
          {/* Heading */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['h2'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['h2'].style, {
                marginBottom: 10,
                marginLeft: 20,
                marginRight: 20,
              }),
              dimensions.width
            )}
          >
            {'jobzProfile'}
          </Text>
          {/* Image Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: 'center',
                flex: 1,
                marginBottom: 10,
              },
              dimensions.width
            )}
          >
            <Image
              {...GlobalStyles.ImageStyles(theme)['match_image'].props}
              resizeMode={'cover'}
              source={{ uri: `${photoUrl}` }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['match_image'].style,
                  { borderRadius: 1000, height: 80, width: 80 }
                ),
                dimensions.width
              )}
            />
            {/* Demo User */}
            <>
              {!'.demo' ? null : (
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'DEMO USER'}
                </Text>
              )}
            </>
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', gap: 4 },
                dimensions.width
              )}
            >
              {/* First Name */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['18 Extra Bold'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['18 Extra Bold'].style,
                  dimensions.width
                )}
              >
                {firstName}
              </Text>
              {/* Last Name */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['18 Extra Bold'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['18 Extra Bold'].style,
                  dimensions.width
                )}
              >
                {lastName}
              </Text>
            </View>
            {/* Loocation */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['Text'].style,
                dimensions.width
              )}
            >
              {city}
              {', '}
              {state}
            </Text>
          </View>
        </View>
        {/* SETTING DATA CONTAINER */}
        <View
          {...GlobalStyles.ViewStyles(theme)['outline_block'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ViewStyles(theme)['outline_block'].style,
              { flex: null, marginBottom: null }
            ),
            dimensions.width
          )}
        >
          <XanoUsersApi.FetchGetUserSettingsGET
            handlers={{
              onData: fetchData => {
                try {
                  setShowEmail(fetchData?.show_email);
                  setShowCell(fetchData?.show_cell);
                  /* hidden 'Log to Console' action */
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            userId={17}
          >
            {({ loading, error, data, refetchGetUserSettings }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Display Email */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, flexDirection: 'row', gap: 40 },
                      dimensions.width
                    )}
                  >
                    {/* LABEL CONTAINER */}
                    <View
                      style={StyleSheet.applyWidth(
                        { minWidth: 50 },
                        dimensions.width
                      )}
                    >
                      {/* LABEL EMAIL */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Email'}
                      </Text>
                    </View>
                    {/* email */}
                    <>
                      {!showEmail ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { flex: 1 }
                            ),
                            dimensions.width
                          )}
                        >
                          {email}
                        </Text>
                      )}
                    </>
                    {/* email hidden */}
                    <>
                      {showEmail ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { flex: 1 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'hidden'}
                        </Text>
                      )}
                    </>
                  </View>
                  {/* Display Cell */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, flexDirection: 'row', gap: 40 },
                      dimensions.width
                    )}
                  >
                    {/* LABEL CONTAINER */}
                    <View
                      style={StyleSheet.applyWidth(
                        { minWidth: 50 },
                        dimensions.width
                      )}
                    >
                      {/* LABEL CELL */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Cell'}
                      </Text>
                    </View>
                    {/* cell */}
                    <>
                      {!showCell ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { flex: 1 }
                            ),
                            dimensions.width
                          )}
                        >
                          {cell}
                        </Text>
                      )}
                    </>
                    {/* cell hidden */}
                    <>
                      {!(showCell === false) ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { flex: 1 }
                            ),
                            dimensions.width
                          )}
                        >
                          {'hidden'}
                        </Text>
                      )}
                    </>
                  </View>
                </>
              );
            }}
          </XanoUsersApi.FetchGetUserSettingsGET>
        </View>
        {/* SHOW JOBZSTORIES */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', gap: 10, margin: 20 },
            dimensions.width
          )}
        >
          <Switch
            onValueChange={newSwitchValue => {
              const switchValue = newSwitchValue;
              try {
                setSV_jobzstories(newSwitchValue);
              } catch (err) {
                console.error(err);
              }
            }}
            value={SV_jobzstories}
          />
          {/* Show JobzStories */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Text'].style,
              dimensions.width
            )}
          >
            {'jobzStories'}
          </Text>
        </View>
        {/* jobzStories */}
        <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            style={StyleSheet.applyWidth(
              {
                flex: 1,
                maxHeight: [
                  { minWidth: Breakpoints.Mobile, value: 360 },
                  { minWidth: Breakpoints.Tablet, value: 900 },
                  { minWidth: Breakpoints.Laptop, value: 800 },
                ],
              },
              dimensions.width
            )}
          >
            <XanoJobzConnectApi.FetchGetSingleJobseekerWithJobzstoriesGET
              handlers={{
                onData: fetchData => {
                  try {
                    /* hidden 'Log to Console' action */
                    setFirstName(fetchData?.first_name);
                    setLastName(fetchData?.last_name);
                    setPhotoUrl(fetchData?._image_of_user?.image?.url);
                    setCity(fetchData?._jobseeker_of_user?._location?.city);
                    setState(
                      fetchData?._jobseeker_of_user?._location?._os_states_usa
                        ?.state
                    );
                    setEmail(fetchData?.email);
                    setCell(fetchData?.cell);
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
              user_id={props.route?.params?.ID_selectedUser ?? 17}
            >
              {({
                loading,
                error,
                data,
                refetchGetSingleJobseekerWithJobzstories,
              }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <SimpleStyleFlatList
                    data={fetchData?._jobzstory_of_user}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ?? listData?.uuid ?? index.toString()
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'bJv7bMxH'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* Jobseeker Data */}
                          <>
                            {!SV_jobzstories ? null : (
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'outline_block'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.ViewStyles(theme)[
                                    'outline_block'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {/* Company Name */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['h2']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['h2'].style,
                                    dimensions.width
                                  )}
                                >
                                  {listData?._company?.company_name}
                                </Text>
                                {/* Industry */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {
                                    listData?._jobseeker_of_user?._os_industry
                                      ?.industry
                                  }
                                </Text>
                                {/* Internal Job Title */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flexDirection: 'row' },
                                    dimensions.width
                                  )}
                                >
                                  {/* Job Title */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['h3']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['h3']
                                          .style,
                                        { flex: 1, fontSize: null }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.internal_title}
                                  </Text>
                                  {/* Internal */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['h3']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['h3']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {'(internal) '}
                                  </Text>
                                </View>
                                {/* External Job Title  */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flex: 1,
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Job Title */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['h3']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['h3']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.external_title}
                                  </Text>
                                  {/* External */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['h3']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['h3']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {'(external) '}
                                  </Text>
                                </View>
                                {/* Start and  Dates */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flexDirection: 'row' },
                                    dimensions.width
                                  )}
                                >
                                  {/* Start Date */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {convertTime(listData?.start_date)}
                                    {' • '}
                                  </Text>
                                  {/* End Date */}
                                  <>
                                    {!listData?.end_date ? null : (
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          dimensions.width
                                        )}
                                      >
                                        {convertTime(listData?.end_date)}
                                      </Text>
                                    )}
                                  </>
                                </View>
                                {/* Horizontal Line */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor: theme.colors['Tab_Divider'],
                                      borderTopWidth: 2,
                                      marginBottom: 8,
                                      marginTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                />
                                {/* Contributions */}
                                <View>
                                  {/* LABEL Top Job */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Top Job Contribution'}
                                  </Text>
                                  {/* Top Job Contribution */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'h3 bold'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['h3 bold']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.top_job_contribution}
                                  </Text>
                                  {/* LABEL Top Team */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Top Team Contribution'}
                                  </Text>
                                  {/* Top Team Contribution */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'h3 bold'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['h3 bold']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.top_team_contribution}
                                  </Text>
                                  {/* LABEL Top Company */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Top Job Contribution'}
                                  </Text>
                                  {/* Top Company Contribution */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'h3 bold'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['h3 bold']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.top_company_contribution}
                                  </Text>
                                </View>
                                {/* Horizontal Line */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor: theme.colors['Tab_Divider'],
                                      borderTopWidth: 2,
                                      marginBottom: 8,
                                      marginTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                />
                                {/* Extra Information */}
                                <View>
                                  {/* Manages Others */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* LABEL Manages Others */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Manages Others '}
                                    </Text>
                                    {/* Yes or No */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'h3 bold'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'h3 bold'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {' '}
                                      {listData?.manages_others ? 'Yes' : 'No'}
                                    </Text>
                                  </View>
                                  {/* No of People Managed */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flex: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* LABEL No of People Managed */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {'No of People Managed'}
                                    </Text>
                                    {/* No of People Managed */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'h3 bold'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'h3 bold'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        listData?._os_nopeoplemanaged
                                          ?.no_of_people_managed
                                      }
                                    </Text>
                                  </View>
                                  {/* LABEL Reason for Leaving */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Reason for Leaving'}
                                  </Text>
                                  {/* Reason for Leaving */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'h3 bold'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)['h3 bold']
                                        .style,
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.reason_for_leaving}
                                  </Text>
                                </View>
                              </View>
                            )}
                          </>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  />
                );
              }}
            </XanoJobzConnectApi.FetchGetSingleJobseekerWithJobzstoriesGET>
          </SimpleStyleScrollView>
        </View>
      </View>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(JobzConnectJobseekerUserProfileScreen);
