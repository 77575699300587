import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { IconButton, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';

const NavigationBottomBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  // Changes the bottom navigation bar when icon selected.
  const dynamicColor = (selected, val) => {
    return selected == val ? '#ffffff' : '#3a4d8f';
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: { minWidth: Breakpoints.BigScreen, value: 'center' },
          backgroundColor: theme.colors['Secondary'],
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          paddingBottom: 8,
          paddingTop: 8,
        },
        dimensions.width
      )}
    >
      {/* Kitchen */}
      <IconButton
        onPress={() => {
          try {
            setGlobalVariableValue({
              key: 'SELECTED',
              value: 'kitchen',
            });
            if (navigation.canGoBack()) {
              navigation.popToTop();
            }
            navigation.replace('KitchenJobseekerScreen');
          } catch (err) {
            console.error(err);
          }
        }}
        size={32}
        color={dynamicColor('kitchen', Constants['SELECTED'])}
        icon={'Ionicons/ios-home-outline'}
      />
      {/* jobzForum */}
      <IconButton
        onPress={() => {
          try {
            setGlobalVariableValue({
              key: 'SELECTED',
              value: 'forum',
            });
            if (navigation.canGoBack()) {
              navigation.popToTop();
            }
            navigation.replace('JobzForumScreen');
          } catch (err) {
            console.error(err);
          }
        }}
        size={32}
        color={dynamicColor('forum', Constants['SELECTED'])}
        icon={'Feather/coffee'}
      />
      {/* jobzConnect */}
      <IconButton
        onPress={() => {
          try {
            setGlobalVariableValue({
              key: 'SELECTED',
              value: 'connect',
            });
            if (navigation.canGoBack()) {
              navigation.popToTop();
            }
            navigation.replace('JobzConnectScreen');
          } catch (err) {
            console.error(err);
          }
        }}
        size={32}
        color={dynamicColor('connect', Constants['SELECTED'])}
        icon={'Feather/globe'}
      />
      {/* jobzZone */}
      <IconButton
        onPress={() => {
          try {
            setGlobalVariableValue({
              key: 'SELECTED',
              value: 'zone',
            });
            if (navigation.canGoBack()) {
              navigation.popToTop();
            }
            navigation.replace('JobzZoneScreen');
          } catch (err) {
            console.error(err);
          }
        }}
        size={32}
        color={dynamicColor('zone', Constants['SELECTED'])}
        icon={'AntDesign/calendar'}
      />
      {/* jobzChat */}
      <IconButton
        onPress={() => {
          try {
            setGlobalVariableValue({
              key: 'SELECTED',
              value: 'chat',
            });
            if (navigation.canGoBack()) {
              navigation.popToTop();
            }
            navigation.replace('JobzChatScreen');
          } catch (err) {
            console.error(err);
          }
        }}
        size={32}
        color={dynamicColor('chat', Constants['SELECTED'])}
        icon={'Ionicons/ios-chatbubble-ellipses-outline'}
      />
    </View>
  );
};

export default withTheme(NavigationBottomBlock);
