import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  IconButton,
  ScreenContainer,
  SimpleStyleScrollView,
  TabView,
  TabViewItem,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';

const JobzForumScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [nav_active, setNav_active] = React.useState(false);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          alignContent: 'center',
          alignItems: { minWidth: Breakpoints.BigScreen, value: 'center' },
          flex: 1,
          justifyContent: 'flex-start',
        },
        dimensions.width
      )}
    >
      {/* back navigation */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
          },
          dimensions.width
        )}
      >
        {/* Back */}
        <IconButton
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          icon={'AntDesign/arrowleft'}
        />
      </View>
      {/* Heading */}
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginBottom: 20,
            marginLeft: 20,
            marginRight: 20,
          },
          dimensions.width
        )}
      >
        {/* jobzForum */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['h2'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['h2'].style,
            dimensions.width
          )}
        >
          {'jobzForum'}
        </Text>
        {/* description */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              alignSelf: 'flex-start',
              marginTop: 10,
            }),
            dimensions.width
          )}
        >
          {
            'The schedule for the forums can be accessed by clicking on the day of the week. '
          }
        </Text>
      </View>
      {/* Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            flex: { minWidth: Breakpoints.Tablet, value: 1 },
            marginBottom: 20,
            marginLeft: 20,
            marginRight: 20,
          },
          dimensions.width
        )}
      >
        <TabView
          activeColor={theme.colors.primary}
          iconPosition={'top'}
          indicatorColor={theme.colors.primary}
          initialTabIndex={0}
          keyboardDismissMode={'auto'}
          pressColor={theme.colors.primary}
          scrollEnabled={false}
          swipeEnabled={true}
          tabBarPosition={'top'}
          tabsBackgroundColor={theme.colors.background}
        >
          {/* Tab View Item Wednesday */}
          <TabViewItem
            {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
              dimensions.width
            )}
            title={'Wed'}
          >
            {/* Topic and Time */}
            <View>
              {/* Topic */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', marginTop: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'Topic: '}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontFamily: 'Poppins_500Medium' }
                    ),
                    dimensions.width
                  )}
                >
                  {"What's up Wednesday"}
                </Text>
              </View>
              {/* Time */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', marginBottom: 10, marginTop: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'Time: '}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontFamily: 'Poppins_500Medium' }
                    ),
                    dimensions.width
                  )}
                >
                  {'5:00 to 6:00 pm PST'}
                </Text>
              </View>
            </View>
            {/* Daily Wrapper */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: [
                    { minWidth: Breakpoints.Laptop, value: 1 },
                    { minWidth: Breakpoints.Mobile, value: 1 },
                  ],
                  flexDirection: [
                    { minWidth: Breakpoints.Laptop, value: 'row' },
                    { minWidth: Breakpoints.Mobile, value: 'row' },
                  ],
                },
                dimensions.width
              )}
            >
              <SimpleStyleScrollView
                bounces={true}
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: [
                      { minWidth: Breakpoints.Laptop, value: 'center' },
                      { minWidth: Breakpoints.Mobile, value: 'center' },
                    ],
                    flex: 1,
                    minHeight: 420,
                  },
                  dimensions.width
                )}
              >
                <WebView
                  allowFileAccessFromFileURLs={false}
                  allowUniversalAccessFromFileURLs={false}
                  cacheEnabled={true}
                  incognito={false}
                  javaScriptCanOpenWindowsAutomatically={false}
                  javaScriptEnabled={true}
                  mediaPlaybackRequiresUserAction={false}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  startInLoadingState={false}
                  {...GlobalStyles.WebViewStyles(theme)['Web View'].props}
                  source={{ uri: 'https://jobzcafe.daily.co/Wednesday_Chat_1' }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.WebViewStyles(theme)['Web View'].style,
                      {
                        borderWidth: 0,
                        flex: { minWidth: Breakpoints.Laptop, value: null },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </SimpleStyleScrollView>
            </View>
          </TabViewItem>
        </TabView>
      </View>

      <View
        style={StyleSheet.applyWidth(
          {
            flex: [
              { minWidth: Breakpoints.Tablet, value: 1 },
              { minWidth: Breakpoints.Mobile, value: 1 },
            ],
            marginLeft: 20,
            marginRight: 20,
            paddingBottom: 10,
          },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'].style,
            dimensions.width
          )}
        >
          {
            "Please note that while JobzCafe™️ DOESNT record any session, nonetheless we are not able to guarantee that a recording isn't made by a user, however this does violate our terms of use."
          }
        </Text>
      </View>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(JobzForumScreen);
