import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const getAllRecruitersGET = async (
  Constants,
  { search },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['search'] = search !== undefined ? renderParam(search) : '';
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:opwHj0w7/Get_all_Recruiters${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetAllRecruitersGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoRecruiterGetAllRecruitersGET', args],
    () => getAllRecruitersGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xanoRecruiterGetAllRecruitersGETS']),
    }
  );
};

export const FetchGetAllRecruitersGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  search,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllRecruitersGET(
    { search },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllRecruiters: refetch });
};

export const oNBOARDINGCreateNewRecruiterPOST = async (
  Constants,
  { company_id, currently_hiring, phone, recruiter_status, title },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:opwHj0w7/recruiter/new`;
  const options = {
    body: JSON.stringify({
      currently_hiring: currently_hiring,
      phone: phone,
      company_id: company_id,
      title: title,
      status: recruiter_status,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useONBOARDINGCreateNewRecruiterPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoRecruiterONBOARDINGCreateNewRecruiterPOST', args],
    () => oNBOARDINGCreateNewRecruiterPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoRecruiterONBOARDINGCreateNewRecruiterPOSTS',
        ]),
    }
  );
};

export const FetchONBOARDINGCreateNewRecruiterPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  company_id,
  currently_hiring,
  phone,
  recruiter_status,
  title,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useONBOARDINGCreateNewRecruiterPOST(
    { company_id, currently_hiring, phone, recruiter_status, title },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchONBOARDINGCreateNewRecruiter: refetch,
  });
};

export const oNBOARDINGRecruiterUpdateOnePATCH = async (
  Constants,
  {
    averageTimetoFill,
    average_no_of_openings,
    joblevelIDS,
    linkedinURL,
    open_jobs,
    specialtyIDS,
  },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:opwHj0w7/recruiter/update_one`;
  const options = {
    body: JSON.stringify({
      open_jobs: open_jobs,
      os_averagenojobs_id: average_no_of_openings,
      os_averagetimetofill_id: averageTimetoFill,
      os_joblevel_id: joblevelIDS,
      os_recruitingspecialty_id: specialtyIDS,
      linkedinURL: linkedinURL,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useONBOARDINGRecruiterUpdateOnePATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoRecruiterONBOARDINGRecruiterUpdateOnePATCH', args],
    () => oNBOARDINGRecruiterUpdateOnePATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoRecruiterONBOARDINGRecruiterUpdateOnePATCHES',
        ]),
    }
  );
};

export const oNBOARDINGUploadProfilePicturePATCH = async (
  Constants,
  { content },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:opwHj0w7/recruiter/upload_image`;
  const options = {
    body: JSON.stringify({ image_type: 'profile', content: content }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useONBOARDINGUploadProfilePicturePATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoRecruiterONBOARDINGUploadProfilePicturePATCH', args],
    () => oNBOARDINGUploadProfilePicturePATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoRecruiterONBOARDINGUploadProfilePicturePATCHES',
        ]),
    }
  );
};
