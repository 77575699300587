import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzChatApi from '../apis/XanoJobzChatApi.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import SidebarMenuBlock from '../components/SidebarMenuBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import convertTime from '../global-functions/convertTime';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  SimpleStyleSwipeableList,
  SwipeableItem,
  SwipeableItemButton,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const ZJobzChatDetailScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [ID_chatroom, setID_chatroom] = React.useState(0);
  const [show_chats_received, setShow_chats_received] = React.useState(false);
  const [show_chats_sent, setShow_chats_sent] = React.useState(false);
  const [show_connects_favorered, setShow_connects_favorered] =
    React.useState(false);
  const [show_screenContainer, setShow_screenContainer] = React.useState(true);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setShow_chats_received(true);
      setShow_chats_sent(false);
      setGlobalVariableValue({
        key: 'CONNECT',
        value: 'chat',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
    >
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, marginTop: 20, minWidth: '100%' },
          dimensions.width
        )}
      >
        {/* SCREEN CONTAINER */}
        <>
          {!(show_screenContainer === true) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  justifyContent: [
                    { minWidth: Breakpoints.Mobile, value: 'space-between' },
                    { minWidth: Breakpoints.Tablet, value: 'flex-start' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Heading */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 20 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['h2'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['h2'].style,
                    dimensions.width
                  )}
                >
                  {'JobzChat'}
                </Text>
              </View>
              {/* TABS */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20, paddingBottom: 10 },
                  dimensions.width
                )}
              >
                {/* TABS Recieved Selected */}
                <>
                  {!(show_chats_received === true) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Left Column */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)['tab selected']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['tab selected']
                              .style,
                            { paddingBottom: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              setShow_chats_received(true);
                              setShow_chats_sent(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Recieved */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['text selected']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['text selected']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Recieved'}
                          </Text>
                        </Pressable>
                      </View>
                      {/* Right Column */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)['tab unselected']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['tab unselected']
                              .style,
                            { marginRight: null, paddingBottom: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              setShow_chats_sent(true);
                              setShow_chats_received(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Sent */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'text unselected'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['text unselected']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Sent'}
                          </Text>
                        </Pressable>
                      </View>
                    </View>
                  )}
                </>
                {/* TABS Sent Selected */}
                <>
                  {!(show_chats_sent === true) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Left Column */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)['tab unselected']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['tab unselected']
                              .style,
                            { alignContent: 'center', paddingBottom: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              setShow_chats_sent(false);
                              setShow_chats_received(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Recieved */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'text unselected'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['text unselected']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Recieved'}
                          </Text>
                        </Pressable>
                      </View>
                      {/* Right Column */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)['tab selected']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['tab selected']
                              .style,
                            { marginRight: null, paddingBottom: 10 }
                          ),
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              setShow_chats_sent(true);
                              setShow_chats_received(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Sent */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)[
                              'text unselected'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['text unselected']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Sent'}
                          </Text>
                        </Pressable>
                      </View>
                    </View>
                  )}
                </>
              </View>
              {/* Chat Container */}
              <View>
                <SimpleStyleScrollView
                  bounces={true}
                  horizontal={false}
                  keyboardShouldPersistTaps={'never'}
                  nestedScrollEnabled={false}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  style={StyleSheet.applyWidth(
                    { marginLeft: 10, maxHeight: 590, minHeight: 590 },
                    dimensions.width
                  )}
                >
                  {/* Headings */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'column',
                        marginLeft: 20,
                        marginRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Chats Recieved */}
                    <>
                      {!(show_chats_received === true) ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { marginBottom: 10, marginRight: 20 }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            'These are the people who have requested to chat with you . . . '
                          }
                        </Text>
                      )}
                    </>
                    {/* Connects Sent */}
                    <>
                      {!(show_chats_sent === true) ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { marginBottom: 10, marginRight: 20 }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            'These are the people who you have sent chats . . . '
                          }
                        </Text>
                      )}
                    </>
                  </View>
                  {/* RECIEVED */}
                  <View>
                    <XanoJobzChatApi.FetchGetAllChatsRecievedGET
                      handlers={{
                        onData: fetchData => {
                          try {
                            setID_chatroom(fetchData?.id);
                          } catch (err) {
                            console.error(err);
                          }
                        },
                      }}
                    >
                      {({
                        loading,
                        error,
                        data,
                        refetchGetAllChatsRecieved,
                      }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* Swipeable List Recieved */}
                            <>
                              {!(show_chats_received === true) ? null : (
                                <SimpleStyleSwipeableList
                                  data={fetchData}
                                  disableScrollWhenSwiping={true}
                                  estimatedItemSize={50}
                                  horizontal={false}
                                  inverted={false}
                                  keyExtractor={(
                                    swipeableListRecievedData,
                                    index
                                  ) =>
                                    swipeableListRecievedData?.id ??
                                    swipeableListRecievedData?.uuid ??
                                    index.toString()
                                  }
                                  keyboardShouldPersistTaps={'never'}
                                  listComponent={'FlatList'}
                                  listKey={'mn5MgPl9'}
                                  nestedScrollEnabled={false}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  renderItem={({ item, index }) => {
                                    const swipeableListRecievedData = item;
                                    return (
                                      <SwipeableItem
                                        closeOnPress={true}
                                        friction={20}
                                        swipeActivationPercentage={80}
                                        swipeToClosePercent={50}
                                        swipeToOpenPercent={50}
                                        {...GlobalStyles.SwipeableItemStyles(
                                          theme
                                        )['Swipeable Item'].props}
                                        disableRightSwipe={false}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SwipeableItemStyles(
                                            theme
                                          )['Swipeable Item'].style,
                                          dimensions.width
                                        )}
                                      >
                                        {/* Recieved Main Container */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { marginTop: 0 },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Recieved Container */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'flex-start',
                                                flex: 1,
                                                flexDirection: 'row',
                                                marginBottom: 10,
                                                marginLeft: 20,
                                                marginRight: 20,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Left Column */}
                                            <View>
                                              <Pressable
                                                onPress={() => {
                                                  try {
                                                    if (
                                                      swipeableListRecievedData
                                                        ?._user
                                                        ?._os_externalrole
                                                        ?.id === 1
                                                    ) {
                                                      navigation.navigate(
                                                        'JobzConnectJobseekerUserProfileScreen',
                                                        {
                                                          ID_selectedUser:
                                                            swipeableListRecievedData?.user_id,
                                                        }
                                                      );
                                                    } else {
                                                      navigation.navigate(
                                                        'JobzConnectRecruiterUserProfileScreen',
                                                        {
                                                          ID_selectedUser:
                                                            swipeableListRecievedData
                                                              ?._user?.id,
                                                        }
                                                      );
                                                    }
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                              >
                                                <Image
                                                  resizeMode={'cover'}
                                                  {...GlobalStyles.ImageStyles(
                                                    theme
                                                  )['Image'].props}
                                                  source={{
                                                    uri: `${swipeableListRecievedData?._user?._image_of_user?.image?.url}`,
                                                  }}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.ImageStyles(
                                                      theme
                                                    )['Image'].style,
                                                    dimensions.width
                                                  )}
                                                />
                                              </Pressable>
                                            </View>
                                            {/* Right Column */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flex: 1,
                                                  flexDirection: 'column',
                                                  paddingLeft: 20,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Pressable
                                                onPress={() => {
                                                  try {
                                                    /* 'If/Else' action requires configuration: select If Condition */
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                              >
                                                {/* Name */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { flexDirection: 'row' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* First Name */}
                                                  <Text
                                                    accessible={true}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['h2'].props}
                                                    style={StyleSheet.applyWidth(
                                                      StyleSheet.compose(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['h2'].style,
                                                        { marginRight: 4 }
                                                      ),
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      swipeableListRecievedData
                                                        ?._user?.first_name
                                                    }
                                                  </Text>
                                                  {/* Last Name */}
                                                  <Text
                                                    accessible={true}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['h2'].props}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['h2'].style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      swipeableListRecievedData
                                                        ?._user?.last_name
                                                    }
                                                  </Text>
                                                </View>
                                              </Pressable>
                                              {/* Demo User */}
                                              <View>
                                                {/* Demo */}
                                                <>
                                                  {!swipeableListRecievedData
                                                    ?._user?.demo ? null : (
                                                    <Text
                                                      accessible={true}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'DEMO USER'}
                                                    </Text>
                                                  )}
                                                </>
                                              </View>
                                              {/* Role */}
                                              <View>
                                                {/* Role */}
                                                <Text
                                                  accessible={true}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['h3'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['h3'].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    swipeableListRecievedData
                                                      ?._user?._os_externalrole
                                                      ?.external_role
                                                  }
                                                </Text>
                                              </View>
                                              {/* LABEL Received Date */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    flexDirection: 'row',
                                                    gap: 8,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* First Received Date */}
                                                <Text
                                                  accessible={true}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'First Recieved:'}
                                                </Text>
                                                {/* First Recieved */}
                                                <View>
                                                  {/* Recieved */}
                                                  <Text
                                                    accessible={true}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['h3 bold'].props}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['h3 bold'].style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {convertTime(
                                                      swipeableListRecievedData?.created_at
                                                    )}
                                                  </Text>
                                                </View>
                                              </View>
                                            </View>
                                          </View>
                                        </View>
                                        {/* Swipeable Item Button Chat */}
                                        <SwipeableItemButton
                                          closeOnPress={true}
                                          onPress={() => {
                                            try {
                                              navigation.navigate(
                                                'JobzChatChatsScreen',
                                                {
                                                  ID_chatroom:
                                                    swipeableListRecievedData?.id,
                                                }
                                              );
                                              setShow_chats_sent(false);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          revealSwipeDirection={'left'}
                                          backgroundColor={
                                            theme.colors['Secondary']
                                          }
                                          icon={
                                            'Ionicons/md-chatbubble-ellipses-outline'
                                          }
                                          title={'Chat'}
                                        />
                                      </SwipeableItem>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                  style={StyleSheet.applyWidth(
                                    { flex: 1 },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                          </>
                        );
                      }}
                    </XanoJobzChatApi.FetchGetAllChatsRecievedGET>
                  </View>
                  {/* SENT */}
                  <View>
                    <XanoJobzChatApi.FetchGetAllChatsSentGET
                      handlers={{
                        onData: fetchData => {
                          try {
                            setID_chatroom(fetchData?.id);
                          } catch (err) {
                            console.error(err);
                          }
                        },
                      }}
                    >
                      {({ loading, error, data, refetchGetAllChatsSent }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* Swipeable List Sent */}
                            <>
                              {!(show_chats_sent === true) ? null : (
                                <SimpleStyleSwipeableList
                                  data={fetchData}
                                  disableScrollWhenSwiping={true}
                                  estimatedItemSize={50}
                                  horizontal={false}
                                  inverted={false}
                                  keyExtractor={(
                                    swipeableListSentData,
                                    index
                                  ) =>
                                    swipeableListSentData?.id ??
                                    swipeableListSentData?.uuid ??
                                    index.toString()
                                  }
                                  keyboardShouldPersistTaps={'never'}
                                  listComponent={'FlatList'}
                                  listKey={'eyovQrgN'}
                                  nestedScrollEnabled={false}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  renderItem={({ item, index }) => {
                                    const swipeableListSentData = item;
                                    return (
                                      <SwipeableItem
                                        closeOnPress={true}
                                        friction={20}
                                        swipeActivationPercentage={80}
                                        swipeToClosePercent={50}
                                        swipeToOpenPercent={50}
                                        {...GlobalStyles.SwipeableItemStyles(
                                          theme
                                        )['Swipeable Item'].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SwipeableItemStyles(
                                            theme
                                          )['Swipeable Item'].style,
                                          dimensions.width
                                        )}
                                      >
                                        {/* Sent Main Container */}
                                        <>
                                          {!(
                                            show_chats_sent === true
                                          ) ? null : (
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { flex: 1 },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Sent Container */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    flexDirection: 'row',
                                                    marginBottom: 10,
                                                    marginLeft: 20,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Left Column */}
                                                <View>
                                                  <Pressable
                                                    onPress={() => {
                                                      try {
                                                        if (
                                                          swipeableListSentData
                                                            ?._user
                                                            ?._os_externalrole
                                                            ?.id === 1
                                                        ) {
                                                          navigation.navigate(
                                                            'JobzConnectJobseekerUserProfileScreen',
                                                            {
                                                              ID_selectedUser:
                                                                swipeableListSentData?.recipient_id,
                                                            }
                                                          );
                                                        } else {
                                                          navigation.navigate(
                                                            'JobzConnectRecruiterUserProfileScreen',
                                                            {
                                                              ID_selectedUser:
                                                                swipeableListSentData?.recipient_id,
                                                            }
                                                          );
                                                        }
                                                      } catch (err) {
                                                        console.error(err);
                                                      }
                                                    }}
                                                  >
                                                    <Image
                                                      resizeMode={'cover'}
                                                      {...GlobalStyles.ImageStyles(
                                                        theme
                                                      )['Image'].props}
                                                      source={{
                                                        uri: `${swipeableListSentData?._user?._image_of_user?.image?.url}`,
                                                      }}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.ImageStyles(
                                                          theme
                                                        )['Image'].style,
                                                        dimensions.width
                                                      )}
                                                    />
                                                  </Pressable>
                                                </View>
                                                {/* Right Column */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      flex: 1,
                                                      flexDirection: 'column',
                                                      paddingLeft: 20,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Pressable
                                                    onPress={() => {
                                                      try {
                                                        /* 'If/Else' action requires configuration: select If Condition */
                                                      } catch (err) {
                                                        console.error(err);
                                                      }
                                                    }}
                                                  >
                                                    {/* Name */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          flexDirection: 'row',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* First Name */}
                                                      <Text
                                                        accessible={true}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['h2'].props}
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )['h2'].style,
                                                            { marginRight: 4 }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          swipeableListSentData
                                                            ?._user?.first_name
                                                        }
                                                      </Text>
                                                      {/* Last Name */}
                                                      <Text
                                                        accessible={true}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['h2'].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['h2'].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          swipeableListSentData
                                                            ?._user?.last_name
                                                        }
                                                      </Text>
                                                    </View>
                                                  </Pressable>
                                                  {/* Demo User */}
                                                  <View>
                                                    <>
                                                      {!swipeableListSentData
                                                        ?._recipient
                                                        ?.demo ? null : (
                                                        <Text
                                                          accessible={true}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )['Text'].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {'DEMO USER'}
                                                        </Text>
                                                      )}
                                                    </>
                                                  </View>
                                                  {/* Role */}
                                                  <View>
                                                    {/* Role */}
                                                    <Text
                                                      accessible={true}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['h3'].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['h3'].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        swipeableListSentData
                                                          ?._user
                                                          ?._os_externalrole
                                                          ?.external_role
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* LABEL Sent Date */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        flexDirection: 'row',
                                                        gap: 8,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Received Date */}
                                                    <Text
                                                      accessible={true}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['Text'].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Recieved Date:'}
                                                    </Text>
                                                    {/* Recieved Date */}
                                                    <View>
                                                      {/* Sent Date */}
                                                      <Text
                                                        accessible={true}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['h3 bold'].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['h3 bold'].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {convertTime(
                                                          swipeableListSentData?.created_at
                                                        )}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </View>
                                              </View>
                                            </View>
                                          )}
                                        </>
                                        {/* Swipeable Item Button Chat */}
                                        <SwipeableItemButton
                                          closeOnPress={true}
                                          onPress={() => {
                                            try {
                                              navigation.navigate(
                                                'JobzChatChatsScreen',
                                                {
                                                  ID_chatroom:
                                                    swipeableListSentData?.id,
                                                }
                                              );
                                              setShow_chats_sent(false);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          revealSwipeDirection={'left'}
                                          backgroundColor={
                                            theme.colors['Secondary']
                                          }
                                          icon={
                                            'Ionicons/chatbubble-ellipses-outline'
                                          }
                                          title={'Chat'}
                                        />
                                      </SwipeableItem>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                  style={StyleSheet.applyWidth(
                                    { flex: 1 },
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                          </>
                        );
                      }}
                    </XanoJobzChatApi.FetchGetAllChatsSentGET>
                  </View>
                </SimpleStyleScrollView>
              </View>
            </View>
          )}
        </>
      </View>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(ZJobzChatDetailScreen);
