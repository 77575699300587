import React from 'react';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { IconButton, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';

const BackNavigationBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 10,
        },
        dimensions.width
      )}
    >
      {/* Back */}
      <IconButton
        onPress={() => {
          try {
            navigation.goBack();
          } catch (err) {
            console.error(err);
          }
        }}
        size={32}
        icon={'AntDesign/arrowleft'}
      />
    </View>
  );
};

export default withTheme(BackNavigationBlock);
