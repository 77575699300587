import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const createNewJobzR8terPOST = async (
  Constants,
  {
    company_id,
    q1_response,
    q1_score,
    q2_response,
    q2_score,
    q3_response,
    q3_score,
    q4_response,
    q4_score,
    q5_response,
    q5_score,
    recruier_id,
    user_id,
  },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:m88KfGO9/jobzR8ter/rating`;
  const options = {
    body: JSON.stringify({
      user_id_jobseeker: user_id,
      user_id_recruiter: recruier_id,
      company_id: company_id,
      q1_response: q1_response,
      q1_score: q1_score,
      q2_response: q2_response,
      q2_score: q2_score,
      q3_response: q3_response,
      q3_score: q3_score,
      q4_response: q4_response,
      q4_score: q4_score,
      q5_response: q5_response,
      q5_score: q5_score,
      qualities: [],
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateNewJobzR8terPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xANOJobzR8terCreateNewJobzR8terPOST', args],
    () => createNewJobzR8terPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xANOJobzR8terCreateNewJobzR8terPOSTS']),
    }
  );
};

export const FetchCreateNewJobzR8terPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  company_id,
  q1_response,
  q1_score,
  q2_response,
  q2_score,
  q3_response,
  q3_score,
  q4_response,
  q4_score,
  q5_response,
  q5_score,
  recruier_id,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCreateNewJobzR8terPOST(
    {
      company_id,
      q1_response,
      q1_score,
      q2_response,
      q2_score,
      q3_response,
      q3_score,
      q4_response,
      q4_score,
      q5_response,
      q5_score,
      recruier_id,
      user_id,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateNewJobzR8ter: refetch });
};

export const getAllRatingsGET = async (Constants, _args, handlers = {}) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:m88KfGO9/jobzR8ter/get_ratings_for_user`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetAllRatingsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xANOJobzR8terGetAllRatingsGET', args],
    () => getAllRatingsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xANOJobzR8terGetAllRatingsGETS']),
    }
  );
};

export const FetchGetAllRatingsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllRatingsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAllRatings: refetch });
};

export const getQualitiesGET = async (Constants, _args, handlers = {}) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:m88KfGO9/get_qualities`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetQualitiesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xANOJobzR8terGetQualitiesGET', args],
    () => getQualitiesGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xANOJobzR8terGetQualitiesGETS']),
    }
  );
};

export const FetchGetQualitiesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetQualitiesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetQualities: refetch });
};

export const getQuestionsGET = async (Constants, _args, handlers = {}) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:m88KfGO9/jobzR8ter/get_questions`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetQuestionsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xANOJobzR8terGetQuestionsGET', args],
    () => getQuestionsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['xANOJobzR8terGetQuestionsGETS']),
    }
  );
};

export const FetchGetQuestionsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetQuestionsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetQuestions: refetch });
};

export const getRatingsForLoggedInRecruiterGET = async (
  Constants,
  { userId },
  handlers = {}
) => {
  const paramsDict = {};
  if (userId !== undefined) {
    paramsDict['user_id'] = renderParam(userId);
  }
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:m88KfGO9/jobzR8ter/get_ratings_for_recruiter${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetRatingsForLoggedInRecruiterGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xANOJobzR8terGetRatingsForLoggedInRecruiterGET', args],
    () => getRatingsForLoggedInRecruiterGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xANOJobzR8terGetRatingsForLoggedInRecruiterGETS',
        ]),
    }
  );
};

export const FetchGetRatingsForLoggedInRecruiterGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetRatingsForLoggedInRecruiterGET(
    { userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetRatingsForLoggedInRecruiter: refetch,
  });
};
