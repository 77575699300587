import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XANOJobzR8terApi from '../apis/XANOJobzR8terApi.js';
import * as XanoRecruiterApi from '../apis/XanoRecruiterApi.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  Switch,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const JobzR8terScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [ID_recruiter, setID_recruiter] = React.useState(0);
  const [LABEL_recruiterEmail, setLABEL_recruiterEmail] = React.useState(
    'Enter a Company Email'
  );
  const [SV_Q1, setSV_Q1] = React.useState(false);
  const [SV_Q2, setSV_Q2] = React.useState(false);
  const [SV_Q3, setSV_Q3] = React.useState(false);
  const [SV_Q4, setSV_Q4] = React.useState(false);
  const [SV_Q5, setSV_Q5] = React.useState(false);
  const [q1_score, setQ1_score] = React.useState(0);
  const [q2_score, setQ2_score] = React.useState(0);
  const [q3_score, setQ3_score] = React.useState(0);
  const [q4_score, setQ4_score] = React.useState(0);
  const [q5_score, setQ5_score] = React.useState(0);
  const [searchRecruiterValuie, setSearchRecruiterValuie] = React.useState('');
  const [show_continue, setShow_continue] = React.useState(false);
  const [show_findRecruiter, setShow_findRecruiter] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [switchValue, setSwitchValue] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'CONNECT',
        value: 'recieved',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
    >
      {/* back navigation */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
          },
          dimensions.width
        )}
      >
        {/* Back */}
        <IconButton
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          icon={'AntDesign/arrowleft'}
        />
      </View>

      <View
        style={StyleSheet.applyWidth(
          { flex: 1, minWidth: '100%' },
          dimensions.width
        )}
      >
        {/* SCREEN CONTAINER */}
        <View
          style={StyleSheet.applyWidth(
            {
              justifyContent: [
                { minWidth: Breakpoints.Mobile, value: 'space-between' },
                { minWidth: Breakpoints.Tablet, value: 'flex-start' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Heading */}
          <View
            style={StyleSheet.applyWidth(
              {
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 20,
                marginLeft: 20,
                marginRight: 20,
              },
              dimensions.width
            )}
          >
            {/* jobR8ter */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['h2'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['h2'].style,
                dimensions.width
              )}
            >
              {'jobzR8ter'}
            </Text>
            {/* Find Recruiter */}
            <IconButton
              onPress={() => {
                try {
                  setShow_findRecruiter(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'MaterialCommunityIcons/account-search-outline'}
            />
          </View>
          {/* MAIN CONTAINER */}
          <View
            style={StyleSheet.applyWidth(
              { marginLeft: 20, marginRight: 20 },
              dimensions.width
            )}
          >
            {/* Description */}
            <View style={StyleSheet.applyWidth({ gap: 10 }, dimensions.width)}>
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  'We ask a few questions about your interaction with the recruiter you wish to rate. By rating a recruiter, you are agreeing to the fact that you did interact personally with this recruiter and all information you provide is an accurate account of your interaction.'
                }
              </Text>
              {/* Current Recruiter Reviewed */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {'You are currently rating '}
                {null} {null}
                {',\n'}
              </Text>
            </View>
            {/* RATING CONTAINER */}
            <View>
              <XANOJobzR8terApi.FetchGetQuestionsGET>
                {({ loading, error, data, refetchGetQuestions }) => {
                  const fetchData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <SimpleStyleFlatList
                      data={fetchData?.questions}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listData, index) => listData?.id}
                      keyboardShouldPersistTaps={'never'}
                      listKey={'bjEUsWyV'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                gap: 20,
                                paddingBottom: 10,
                                paddingTop: 10,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                dimensions.width
                              )}
                            >
                              {listData?.question}
                            </Text>
                            <Switch
                              onValueChange={newSwitchValue => {
                                const switchValue = newSwitchValue;
                                try {
                                  setSwitchValue(switchValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              value={switchValue}
                            />
                          </View>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  );
                }}
              </XANOJobzR8terApi.FetchGetQuestionsGET>
              {/* BUTTON CONTAINER */}
              <View
                {...GlobalStyles.ViewStyles(theme)['bottomButton'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['bottomButton'].style,
                  dimensions.width
                )}
              >
                {/* Button Next */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        (
                          await XANOJobzR8terApi.createNewJobzR8terPOST(
                            Constants,
                            {
                              company_id: 45,
                              q1_response: SV_Q1,
                              q1_score: q1_score,
                              q2_response: SV_Q2,
                              q2_score: q2_score,
                              q3_response: SV_Q3,
                              q3_score: q3_score,
                              q4_response: SV_Q4,
                              q4_score: q4_score,
                              q5_response: SV_Q5,
                              q5_score: q5_score,
                              recruier_id: ID_recruiter,
                              user_id: props.route?.params?.USER_ID ?? null,
                            }
                          )
                        )?.json;
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ButtonStyles(theme)['Button_Secondary'].style,
                    dimensions.width
                  )}
                  title={'Next'}
                />
                {/* Button Cancel */}
                <Button
                  iconPosition={'left'}
                  {...GlobalStyles.ButtonStyles(theme)['Button_Cancel'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ButtonStyles(theme)['Button_Cancel'].style,
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(JobzR8terScreen);
