import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoUsersApi from '../apis/XanoUsersApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CommonPackages from '../custom-files/CommonPackages';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  LinearGradient,
  Link,
  ScreenContainer,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';

const LoginScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [INPUT_firstName, setINPUT_firstName] = React.useState('');
  const [INPUT_lastName, setINPUT_lastName] = React.useState('');
  const [INPUT_password, setINPUT_password] = React.useState('');
  const [PLACEHOLDER_firstName, setPLACEHOLDER_firstName] = React.useState('');
  const [PLACEHOLDER_lastName, setPLACEHOLDER_lastName] = React.useState('');
  const [emailValue, setEmailValue] = React.useState('');
  const [passwordValue, setPasswordValue] = React.useState('');
  // This is the login status
  const getStatus = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('status');
  };
  React.useEffect(() => {
    async function initRevenueCat() {
      const Purchases = CommonPackages.Purchases;
      if (Platform.OS == 'android') {
        await Purchases.configure({
          apiKey: 'goog_oMDLzgcmmvFWsxYzZvwQzWlaJvT',
        });
      } else {
        await Purchases.configure({
          apiKey: 'appl_uzJCdDEWfiJwAdJEWLVqDQAUgzl',
        });
      }
    }

    initRevenueCat();
  }, []);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!Constants['AUTH_HEADER']) {
        return;
      }
      getStatus();
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasBottomSafeArea={false}
      hasLeftSafeArea={false}
      hasRightSafeArea={false}
      hasSafeArea={false}
      hasTopSafeArea={false}
    >
      <LinearGradient
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        color1={theme.colors.darker_pink}
        color2={theme.colors.primary}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              flex: 1,
              justifyContent: 'center',
              marginLeft: 30,
              marginRight: 30,
              paddingTop: 30,
            },
            dimensions.width
          )}
        >
          {/* Header */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            {/* Title */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['h1_inverse'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['h1_inverse'].style,
                  { fontFamily: 'Poppins_600SemiBold', fontSize: 36 }
                ),
                dimensions.width
              )}
            >
              {'Welcome Back!'}
            </Text>
            {/* Subtitle */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['h3 inverse'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['h3 inverse'].style,
                dimensions.width
              )}
            >
              {'Sign in to your account to continue'}
            </Text>
          </View>
          {/* Login Form */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: { minWidth: Breakpoints.Laptop, value: 'center' },
                alignSelf: [
                  { minWidth: Breakpoints.Desktop, value: 'center' },
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                ],
                marginTop: 24,
              },
              dimensions.width
            )}
          >
            {/* Error Message */}
            <>
              {!Constants['error_message'] ? null : (
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.error,
                      fontSize: 12,
                      marginBottom: 16,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {Constants['error_message']}
                </Text>
              )}
            </>
            {/* Email Input */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newEmailInputValue => {
                try {
                  setEmailValue(newEmailInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['input_text_white'].props}
              keyboardType={'email-address'}
              placeholder={'Email'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['input_text_white'].style,
                  { borderWidth: 1 }
                ),
                dimensions.width
              )}
              textContentType={'emailAddress'}
              value={emailValue}
            />
            <Spacer left={8} right={8} bottom={12} top={12} />
            {/* Password Input */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newPasswordInputValue => {
                try {
                  setPasswordValue(newPasswordInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['input_text_white'].props}
              placeholder={'Password'}
              secureTextEntry={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['input_text_white'].style,
                  {
                    borderBottomWidth: 1,
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                  }
                ),
                dimensions.width
              )}
              value={passwordValue}
            />
            {/* Reset Password */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', paddingTop: 10 },
                dimensions.width
              )}
            >
              <Link
                accessible={true}
                onPress={() => {
                  try {
                    navigation.navigate('PasswordResetScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.LinkStyles(theme)['link_inverse'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.LinkStyles(theme)['link_inverse'].style,
                    { color: theme.colors['Light Inverse'] }
                  ),
                  dimensions.width
                )}
                title={'Forgot your password?'}
              />
            </View>
            <Spacer left={8} right={8} bottom={24} top={24} />
            {/* LOGIN CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.Tablet, value: 'center' },
                    { minWidth: Breakpoints.Mobile, value: 'center' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Sign In Button */}
              <>
                {Constants['is_loading'] ? null : (
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setGlobalVariableValue({
                            key: 'is_loading',
                            value: true,
                          });
                          const response = (
                            await XanoUsersApi.loginPOST(Constants, {
                              email: emailValue,
                              password: passwordValue,
                            })
                          )?.json;
                          const authToken = response?.authToken;
                          const message = response?.message;
                          setGlobalVariableValue({
                            key: 'error_message',
                            value: message,
                          });
                          setGlobalVariableValue({
                            key: 'is_loading',
                            value: false,
                          });
                          if (!authToken) {
                            return;
                          }
                          const idResult = response?.id;
                          const first_name = response?.first_name;
                          const last_name = response?.lastt_name;
                          const primary_role = response?.primary_role;
                          const email = response?.email;
                          setGlobalVariableValue({
                            key: 'AUTH_HEADER',
                            value: 'Bearer ' + authToken,
                          });
                          setGlobalVariableValue({
                            key: 'USER_ID',
                            value: idResult,
                          });
                          setGlobalVariableValue({
                            key: 'FIRST_NAME',
                            value: first_name,
                          });
                          setGlobalVariableValue({
                            key: 'LAST_NAME',
                            value: last_name,
                          });
                          setGlobalVariableValue({
                            key: 'PRIMARY_ROLE',
                            value: primary_role,
                          });
                          setGlobalVariableValue({
                            key: 'USER_EMAIL',
                            value: email,
                          });
                          setEmailValue('');
                          setPasswordValue('');
                          setGlobalVariableValue({
                            key: 'error_message',
                            value: '',
                          });
                          console.log(response);
                          if (response?.onboarded) {
                            navigation.navigate('KitchenJobseekerScreen');
                          } else {
                            if (primary_role === 1) {
                              if (navigation.canGoBack()) {
                                navigation.popToTop();
                              }
                              navigation.replace('Onboarding1JobseekerScreen', {
                                user_ID: Constants['USER_ID'],
                              });
                            }
                            if (primary_role === 2) {
                              if (navigation.canGoBack()) {
                                navigation.popToTop();
                              }
                              navigation.replace('Onboarding1RecruiterScreen', {
                                user_ID: Constants['USER_ID'],
                              });
                            }
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                      .props}
                    disabled={Constants['is_loading']}
                    loading={Constants['is_loading']}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button_Secondary']
                          .style,
                        {
                          fontFamily: 'Poppins_700Bold',
                          paddingBottom: 16,
                          paddingTop: 16,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Sign in'}
                  >
                    {'Sign Up'}
                  </Button>
                )}
              </>
            </View>
            <Spacer left={8} right={8} bottom={16} top={16} />
            {/* SIGNUP CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginBottom: 12,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  { fontFamily: 'Poppins_400Regular' },
                  dimensions.width
                )}
              >
                {'New to Jobz Cafe™️ '}
              </Text>
              <Spacer bottom={8} top={8} left={2} right={2} />
              {/* Sign Up Link */}
              <Link
                accessible={true}
                onPress={() => {
                  try {
                    navigation.navigate('SignUpJobseekerScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.LinkStyles(theme)['link_inverse'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.LinkStyles(theme)['link_inverse'].style,
                  dimensions.width
                )}
                title={'Sign up!'}
              />
            </View>
            {/* Recruiter */}
            <View>
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    navigation.navigate('SignUpRecrutierScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['button_inverse'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['button_inverse'].style,
                    { borderRadius: null, borderWidth: null }
                  ),
                  dimensions.width
                )}
                title={'Recruiter Sign up'}
              />
            </View>
          </View>
        </View>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(LoginScreen);
