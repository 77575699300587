import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoOptionSets$DropdownsApi from '../apis/XanoOptionSets$DropdownsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  IconButton,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';

const AddNewStudyTitleScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [INPUT_StudyTitle, setINPUT_StudyTitle] = React.useState('');
  const [PLACEHOLDER_StudyTilte, setPLACEHOLDER_StudyTilte] =
    React.useState('');

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* NEW Study Title Block */}
      <View>
        {/* ADD NEW STUDY TITLE RECORD */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Strong Inverse'],
              flex: 1,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* back navigation */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
              },
              dimensions.width
            )}
          >
            {/* Back */}
            <IconButton
              onPress={() => {
                try {
                  navigation.navigate('JobzStoryEducationAddScreen', {
                    ID_studyTitle: INPUT_StudyTitle,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'AntDesign/arrowleft'}
            />
          </View>
          {/* HEADING Add Your Study Title */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['h2'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['h2'].style, {
                marginLeft: 20,
                marginTop: 10,
              }),
              dimensions.width
            )}
          >
            {'Add Your Study Title'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              { marginLeft: 20, marginRight: 20, marginTop: 20 },
              dimensions.width
            )}
          >
            {/* LABEL Add Study Title */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['h3'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['h3'].style,
                dimensions.width
              )}
            >
              {'Study Title'}
            </Text>
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newTextInputValue => {
                try {
                  setINPUT_StudyTitle(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              placeholder={PLACEHOLDER_StudyTilte.toString()}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  { marginTop: 8 }
                ),
                dimensions.width
              )}
              value={INPUT_StudyTitle}
            />
          </View>
          {/* BUTTON SAVE STUDY TITLE */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                margin: 20,
              },
              dimensions.width
            )}
          >
            {/* Button_Save Study Title */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    (
                      await XanoOptionSets$DropdownsApi.addANewStudyTitlePOST(
                        Constants,
                        { study_title: INPUT_StudyTitle }
                      )
                    )?.json;
                    navigation.navigate('JobzStoryEducationAddScreen');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button_Secondary'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button_Secondary'].style,
                  { fontFamily: 'Poppins_700Bold', height: null, maxWidth: 60 }
                ),
                dimensions.width
              )}
              title={'Save'}
            />
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(AddNewStudyTitleScreen);
