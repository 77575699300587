import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { ScreenContainer, Touchable, withTheme } from '@draftbit/ui';
import { Image, Text, View } from 'react-native';

const JobzTalkScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      {/* Top Image */}
      <Image
        resizeMode={'cover'}
        source={Images.SplashScreenWithoutMascot}
        style={StyleSheet.applyWidth(
          { height: '55%', width: '100%' },
          dimensions.width
        )}
      />
      {/* Blue Round View */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: theme.colors['Primary'],
            borderTopLeftRadius: 40,
            borderTopRightRadius: 40,
            bottom: 0,
            height: '50%',
            left: 0,
            paddingLeft: 20,
            paddingRight: 20,
            position: 'absolute',
            right: 0,
          },
          dimensions.width
        )}
      >
        {/* Title */}
        <Text
          accessible={true}
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Background'],
              fontFamily: 'Inter_500Medium',
              fontSize: 26,
              marginTop: 60,
              textAlign: 'center',
            },
            dimensions.width
          )}
        >
          {'jobzTalk - Coming Soon'}
        </Text>
        {/* Description */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: theme.colors['White'],
              fontSize: 12,
              marginTop: 15,
              opacity: 0.5,
              textAlign: 'center',
            }),
            dimensions.width
          )}
        >
          {
            "You, the job seeker, stepping into the spotlight for a podcast-style interview, right from your device. It's your chance to showcase your skills and personality, creating a lasting impression on recruiters."
          }
        </Text>
        {/* Next Btn */}
        <View
          style={StyleSheet.applyWidth(
            {
              flex: 1,
              justifyContent: 'center',
              marginBottom: 40,
              marginTop: 20,
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('KitchenJobseekerScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Image
              resizeMode={'cover'}
              source={Images.Ob2Button}
              style={StyleSheet.applyWidth(
                { height: 88, width: 88 },
                dimensions.width
              )}
            />
          </Touchable>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzTalkScreen);
