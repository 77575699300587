import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  AccordionGroup,
  Icon,
  IconButton,
  Link,
  Pressable,
  withTheme,
} from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { Text, View } from 'react-native';

const SidebarMenuBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [show_billing, setShow_billing] = React.useState(false);
  const [show_menuContainer, setShow_menuContainer] = React.useState(false);
  const [show_menuOpen, setShow_menuOpen] = React.useState(true);

  return (
    <View
      style={StyleSheet.applyWidth(
        { flex: 1, overflow: 'hidden', top: 0, zIndex: 999 },
        dimensions.width
      )}
    >
      {/* Menu Icon */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: 'flex-end',
            marginRight: 20,
            marginTop: 10,
            position: 'absolute',
            right: 0,
            top: 0,
          },
          dimensions.width
        )}
      >
        {/* Icon Button Menu Open */}
        <>
          {!(show_menuOpen === true) ? null : (
            <IconButton
              onPress={() => {
                try {
                  setShow_menuContainer(true);
                  setShow_menuOpen(false);
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'MaterialCommunityIcons/menu-open'}
            />
          )}
        </>
        {/* Icon Button Menu Close */}
        <>
          {!(show_menuOpen === false) ? null : (
            <IconButton
              onPress={() => {
                try {
                  setShow_menuContainer(false);
                  setShow_menuOpen(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              size={32}
              icon={'AntDesign/closesquareo'}
            />
          )}
        </>
      </View>
      {/* MENU CONTAINER */}
      <>
        {!(show_menuContainer === true) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'flex-start',
                backgroundColor: theme.colors['Secondary'],
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
                maxWidth: 240,
                minHeight: '100%',
                minWidth: 240,
                position: 'absolute',
                top: 0,
                zIndex: 999,
              },
              dimensions.width
            )}
          >
            {/* ACCORDIAN CONTAINER */}
            <View
              style={StyleSheet.applyWidth(
                { flex: 3, marginLeft: 20, paddingTop: 20 },
                dimensions.width
              )}
            >
              {/* Accordion_Connect */}
              <AccordionGroup
                caretSize={24}
                expanded={false}
                iconSize={24}
                {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
                label={'CONNECT'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
                  dimensions.width
                )}
              >
                {/* MENU CONTAINER */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginLeft: 10 },
                    dimensions.width
                  )}
                >
                  {/* jobzStory Container */}
                  <View>
                    {/* jobzStory */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['menu_item'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['menu_item'].style,
                        dimensions.width
                      )}
                    >
                      {'jobzStory'}
                    </Text>
                    {/* Menu Items */}
                    <View
                      {...GlobalStyles.ViewStyles(theme)['menu_items'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ViewStyles(theme)['menu_items'].style,
                        dimensions.width
                      )}
                    >
                      {/* jobzStory Summary */}
                      <Link
                        accessible={true}
                        onPress={() => {
                          try {
                            navigation.navigate('JobzStorySummaryScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.LinkStyles(theme)['link_inverse']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.LinkStyles(theme)['link_inverse'].style,
                          dimensions.width
                        )}
                        title={'jobzStory Summary'}
                      />
                    </View>
                  </View>
                  {/* jobzZone */}
                  <View>
                    {/* jobzZone */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['menu_item'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['menu_item'].style,
                        dimensions.width
                      )}
                    >
                      {'jobzZone'}
                    </Text>
                    {/* Menu Items */}
                    <View
                      {...GlobalStyles.ViewStyles(theme)['menu_items'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ViewStyles(theme)['menu_items'].style,
                        dimensions.width
                      )}
                    >
                      {/* Search */}
                      <Link
                        accessible={true}
                        onPress={() => {
                          try {
                            navigation.navigate('JobzZoneJobDetailScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.LinkStyles(theme)['link_inverse']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.LinkStyles(theme)['link_inverse'].style,
                          dimensions.width
                        )}
                        title={'Search'}
                      />
                    </View>
                  </View>
                  {/* jobzConnect */}
                  <View>
                    {/* jobzConnect */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['menu_item'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['menu_item'].style,
                        dimensions.width
                      )}
                    >
                      {'jobzConnect'}
                    </Text>
                    {/* Menu Items */}
                    <View
                      {...GlobalStyles.ViewStyles(theme)['menu_items'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ViewStyles(theme)['menu_items'].style,
                        dimensions.width
                      )}
                    >
                      {/* jobzConnect */}
                      <Link
                        accessible={true}
                        onPress={() => {
                          try {
                            navigation.navigate('JobzConnectScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.LinkStyles(theme)['link_inverse']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.LinkStyles(theme)['link_inverse'].style,
                          dimensions.width
                        )}
                        title={'jobzConnect'}
                      />
                    </View>
                  </View>
                </View>
              </AccordionGroup>
              {/* Accordion_Chat */}
              <AccordionGroup
                caretSize={24}
                expanded={false}
                iconSize={24}
                {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
                label={'CHAT'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
                  dimensions.width
                )}
              >
                {/* MENU CONTAINER */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginLeft: 10 },
                    dimensions.width
                  )}
                >
                  {/* jobzChat Container */}
                  <View>
                    {/* jobzChat */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['menu_item'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['menu_item'].style,
                        dimensions.width
                      )}
                    >
                      {'jobzChat'}
                    </Text>
                    {/* Menu Items */}
                    <View
                      {...GlobalStyles.ViewStyles(theme)['menu_items'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ViewStyles(theme)['menu_items'].style,
                        dimensions.width
                      )}
                    >
                      {/* jobzChat */}
                      <Link
                        accessible={true}
                        onPress={() => {
                          try {
                            navigation.navigate('JobzChatScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.LinkStyles(theme)['link_inverse']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.LinkStyles(theme)['link_inverse'].style,
                          dimensions.width
                        )}
                        title={'jobzChats'}
                      />
                    </View>
                  </View>
                  {/* jobzForum */}
                  <View>
                    {/* jobzForum */}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['menu_item'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['menu_item'].style,
                        dimensions.width
                      )}
                    >
                      {'jobzForum'}
                    </Text>
                    {/* Menu Items */}
                    <View
                      {...GlobalStyles.ViewStyles(theme)['menu_items'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ViewStyles(theme)['menu_items'].style,
                        dimensions.width
                      )}
                    >
                      {/* jobzForum */}
                      <Link
                        accessible={true}
                        onPress={() => {
                          try {
                            navigation.navigate('JobzForumScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.LinkStyles(theme)['link_inverse']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.LinkStyles(theme)['link_inverse'].style,
                          dimensions.width
                        )}
                        title={'jobzForum'}
                      />
                    </View>
                  </View>
                </View>
              </AccordionGroup>
              {/* Accordion_Control */}
              <AccordionGroup
                caretSize={24}
                expanded={false}
                iconSize={24}
                {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
                label={'CONTROL'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
                  dimensions.width
                )}
              />
            </View>
            {/* SETTINGS CONTAINER */}
            <View
              {...GlobalStyles.ViewStyles(theme)['settings'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['settings'].style,
                  {
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: 20,
                  }
                ),
                dimensions.width
              )}
            >
              {/* Pressable_Profile */}
              <Pressable
                onPress={() => {
                  try {
                    if (Constants['PRIMARY_ROLE'] === 1) {
                      navigation.navigate('ProfileJobseekerScreen', {
                        user_id: Constants['USER_ID'],
                      });
                    } else {
                      /* 'Navigate' action requires configuration: choose a navigation destination */
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Profile Link */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row', gap: 10 },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    {...GlobalStyles.IconStyles(theme)['menu_Icon'].props}
                    name={'MaterialCommunityIcons/account-circle-outline'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['menu_Icon'].style,
                      dimensions.width
                    )}
                  />
                  {/* Profile */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['text_inverse'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['text_inverse'].style,
                      dimensions.width
                    )}
                  >
                    {'Profile'}
                  </Text>
                </View>
              </Pressable>
              {/* Pressable_Billing */}
              <Pressable
                onPress={() => {
                  const handler = async () => {
                    try {
                      await WebBrowser.openBrowserAsync(
                        'https://billing.stripe.com/p/login/eVa03x2tH9b9afS8ww'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
              >
                {/* Billing Link */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', gap: 10 },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    {...GlobalStyles.IconStyles(theme)['menu_Icon'].props}
                    color={theme.colors['Light Inverse']}
                    name={'MaterialIcons/account-balance'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['menu_Icon'].style,
                      dimensions.width
                    )}
                  />
                  {/* Biling */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['text_inverse'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['text_inverse'].style,
                      dimensions.width
                    )}
                  >
                    {'Billing'}
                  </Text>
                </View>
              </Pressable>
              {/* Pressable_Help */}
              <Pressable
                onPress={() => {
                  try {
                    navigation.navigate('SupportTicketScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Help */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row', gap: 10 },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    {...GlobalStyles.IconStyles(theme)['menu_Icon'].props}
                    name={'AntDesign/customerservice'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['menu_Icon'].style,
                      dimensions.width
                    )}
                  />
                  {/* Help */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['text_inverse'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['text_inverse'].style,
                      dimensions.width
                    )}
                  >
                    {'Help'}
                  </Text>
                </View>
              </Pressable>
              {/* Pressable _Email */}
              <Pressable>
                {/* Email Us */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row', gap: 10 },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    {...GlobalStyles.IconStyles(theme)['menu_Icon'].props}
                    name={'MaterialCommunityIcons/email-send'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['menu_Icon'].style,
                      dimensions.width
                    )}
                  />
                  {/* Email Us */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['text_inverse'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['text_inverse'].style,
                      dimensions.width
                    )}
                  >
                    {'Email Us'}
                  </Text>
                </View>
              </Pressable>
            </View>
          </View>
        )}
      </>
    </View>
  );
};

export default withTheme(SidebarMenuBlock);
