import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzConnectApi from '../apis/XanoJobzConnectApi.js';
import NavigationBottomBlock from '../components/NavigationBottomBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  DeckSwiper,
  DeckSwiperCard,
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const JobzConnectScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [demo_user, setDemo_user] = React.useState('DEMO USER');
  const [jobseeker, setJobseeker] = React.useState('');
  const [primaryRole, setPrimaryRole] = React.useState(false);
  const [recipient_os_external_role, setRecipient_os_external_role] =
    React.useState('');
  const [recruiter, setRecruiter] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const [showRight, setShowRight] = React.useState(false);
  const [showWrong, setShowWrong] = React.useState(false);
  const [show_SwipePile, setShow_SwipePile] = React.useState(true);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'SELECTED',
        value: 'connect',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
    >
      {/* back navigation */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
          },
          dimensions.width
        )}
      >
        {/* Back */}
        <IconButton
          onPress={() => {
            try {
              if (navigation.canGoBack()) {
                navigation.popToTop();
              }
              navigation.replace('JobzConnectScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          icon={'AntDesign/arrowleft'}
        />
      </View>

      <View
        style={StyleSheet.applyWidth(
          { flex: 1, justifyContent: 'space-between' },
          dimensions.width
        )}
      >
        {/* SCREEN CONTAINER */}
        <View>
          {/* Container */}
          <View
            style={StyleSheet.applyWidth({ paddingTop: 20 }, dimensions.width)}
          >
            {/* Heading */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 20,
                  marginLeft: 20,
                  marginRight: 20,
                },
                dimensions.width
              )}
            >
              {/* jobConnect */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['h2'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['h2'].style,
                  dimensions.width
                )}
              >
                {'jobzConnect'}
              </Text>
              {/* Show Connections */}
              <IconButton
                onPress={() => {
                  try {
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('JobzConnectActivityScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'MaterialCommunityIcons/transit-connection-variant'}
              />
            </View>
            {/* Data Container */}
            <>
              {!(show_SwipePile === true) ? null : (
                <View>
                  <XanoJobzConnectApi.FetchGetSwipePlieWithFilterGET
                    handlers={{
                      onData: fetchData => {
                        try {
                          /* hidden 'Extract Key' action */
                          /* hidden 'Extract Key' action */
                          /* hidden 'Set Variable' action */
                          /* hidden 'If/Else' action */
                        } catch (err) {
                          console.error(err);
                        }
                      },
                    }}
                  >
                    {({
                      loading,
                      error,
                      data,
                      refetchGetSwipePlieWithFilter,
                    }) => {
                      const fetchData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {/* Deck Container */}
                          <View>
                            <DeckSwiper
                              data={fetchData?.filterSwipePile}
                              horizontalEnabled={true}
                              keyExtractor={(deckSwiperData, index) =>
                                deckSwiperData?.id ??
                                deckSwiperData?.uuid ??
                                index.toString()
                              }
                              listKey={'BssDDlxH'}
                              onSwipedLeft={swipedIndex => {
                                const handler = async () => {
                                  try {
                                    (
                                      await XanoJobzConnectApi.createNewUnMatchPOST(
                                        Constants,
                                        {
                                          initator: Constants['USER_ID'],
                                          recipient: swipedIndex?.id,
                                          unmatched_by_initator: false,
                                          unmatched_by_recipient: true,
                                        }
                                      )
                                    )?.json;
                                    setShow_SwipePile(false);
                                    setShowWrong(true);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              onSwipedRight={swipedIndex => {
                                const handler = async () => {
                                  try {
                                    (
                                      await XanoJobzConnectApi.createNewMatchPOST(
                                        Constants,
                                        {
                                          initiator_role:
                                            Constants['PRIMARY_ROLE'],
                                          recipient: swipedIndex?.id,
                                          recipient_role:
                                            swipedIndex?.primary_role,
                                        }
                                      )
                                    )?.json;
                                    setShowRight(true);
                                    setShow_SwipePile(false);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              renderItem={({ item, index }) => {
                                const deckSwiperData = item;
                                return (
                                  <DeckSwiperCard
                                    {...GlobalStyles.DeckSwiperCardStyles(
                                      theme
                                    )['Deck Swiper Card'].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.DeckSwiperCardStyles(
                                          theme
                                        )['Deck Swiper Card'].style,
                                        {
                                          alignItems: 'flex-start',
                                          borderWidth: 0,
                                          padding: null,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Image
                                      {...GlobalStyles.ImageStyles(theme)[
                                        'match_image'
                                      ].props}
                                      resizeMode={'cover'}
                                      source={{
                                        uri: `${deckSwiperData?._image_of_user?.image?.url}`,
                                      }}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ImageStyles(theme)[
                                            'match_image'
                                          ].style,
                                          {
                                            borderRadius: null,
                                            height: {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 600,
                                            },
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    />
                                    {/* Personal Information Block */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'column',
                                          paddingLeft: 20,
                                          paddingRight: 20,
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Demo User */}
                                      <View>
                                        <>
                                          {!deckSwiperData?.demo ? null : (
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'DEMO USER'}
                                            </Text>
                                          )}
                                        </>
                                      </View>

                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            flex: 1,
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Column Left */}
                                        <View>
                                          <Pressable
                                            onPress={() => {
                                              try {
                                                if (
                                                  deckSwiperData?.primary_role ===
                                                  1
                                                ) {
                                                  navigation.navigate(
                                                    'JobzConnectJobseekerUserProfileScreen',
                                                    {
                                                      ID_selectedUser:
                                                        deckSwiperData?.id,
                                                    }
                                                  );
                                                } else {
                                                  navigation.navigate(
                                                    'JobzConnectRecruiterUserProfileScreen',
                                                    {
                                                      ID_selectedUser:
                                                        deckSwiperData?.id,
                                                    }
                                                  );
                                                }
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                          >
                                            {/* Names */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { flexDirection: 'row' },
                                                dimensions.width
                                              )}
                                            >
                                              {/* First Name */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['18 Extra Bold'].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['18 Extra Bold'].style,
                                                    { marginRight: 4 }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {deckSwiperData?.first_name}
                                              </Text>
                                              {/* Last Name */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['18 Extra Bold'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['18 Extra Bold'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {deckSwiperData?.last_name}
                                              </Text>
                                            </View>
                                          </Pressable>
                                        </View>
                                        {/* Column Right */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { alignItems: 'flex-end' },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Role */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'h2'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'h2'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              deckSwiperData?._os_externalrole
                                                ?.external_role
                                            }
                                          </Text>
                                          {/* Status of Jobseeker */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              deckSwiperData?._jobseeker_of_user
                                                ?._os_jobseekerstatus?.status
                                            }
                                          </Text>
                                          {/* Status of Recruiter */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              deckSwiperData?._recruiter_of_user
                                                ?._os_recruiterstatus?.status
                                            }
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                    {/* jobseeker_Other Information */}
                                    <>
                                      {!(
                                        deckSwiperData?.primary_role === 1
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              paddingLeft: 20,
                                              paddingRight: 20,
                                              paddingTop: 10,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Inner */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'flex-start',
                                                backgroundColor:
                                                  theme.colors['Primary 3'],
                                                borderColor:
                                                  theme.colors['Primary 4'],
                                                borderRadius: 10,
                                                borderWidth: 1,
                                                flex: 1,
                                                paddingBottom: 4,
                                                paddingLeft: 4,
                                                paddingRight: 4,
                                                paddingTop: 4,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Jobtype */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flexDirection: 'row',
                                                  gap: 10,
                                                  justifyContent: 'flex-start',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* label */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'Job Type:'}
                                              </Text>
                                              {/* Industry */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['h3 bold'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h3 bold'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  deckSwiperData
                                                    ?._jobseeker_of_user
                                                    ?._os_jobtype?.job_type
                                                }
                                              </Text>
                                            </View>
                                            {/* Industry */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flexDirection: 'row',
                                                  gap: 10,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Label */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'Industry:'}
                                              </Text>
                                              {/* Industry */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['h3 bold'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h3 bold'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  deckSwiperData
                                                    ?._jobseeker_of_user
                                                    ?._os_industry?.industry
                                                }
                                              </Text>
                                            </View>
                                            {/* Company Size */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flexDirection: 'row',
                                                  gap: 10,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Company Size */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'Company Size:'}
                                              </Text>
                                              {/* Headcount */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['h3 bold'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h3 bold'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  deckSwiperData
                                                    ?._jobseeker_of_user
                                                    ?._os_headcount?.headcount
                                                }
                                              </Text>
                                            </View>
                                            {/* Job Site */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flexDirection: 'row',
                                                  gap: 10,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Job Site */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'Job Site:'}
                                              </Text>
                                              {/* Job Site */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['h3 bold'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h3 bold'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  deckSwiperData
                                                    ?._jobseeker_of_user
                                                    ?._os_jobsite?.job_site
                                                }
                                              </Text>
                                            </View>
                                          </View>
                                        </View>
                                      )}
                                    </>
                                    {/* Recruiter_Other Information */}
                                    <>
                                      {!(
                                        deckSwiperData?.primary_role === 2
                                      ) ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              paddingLeft: 20,
                                              paddingRight: 20,
                                              paddingTop: 10,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Inner */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'flex-start',
                                                backgroundColor:
                                                  theme.colors['Primary 3'],
                                                borderColor:
                                                  theme.colors['Primary 4'],
                                                borderRadius: 10,
                                                borderWidth: 1,
                                                flex: 1,
                                                paddingBottom: 4,
                                                paddingLeft: 4,
                                                paddingRight: 4,
                                                paddingTop: 4,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Company */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flexDirection: 'row',
                                                  gap: 10,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Company */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'Company:'}
                                              </Text>
                                              {/* Job Site */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['h3 bold'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h3 bold'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  deckSwiperData
                                                    ?._recruiter_of_user
                                                    ?._company?.company_name
                                                }
                                              </Text>
                                            </View>
                                            {/* Average No Jobs */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flexDirection: 'row',
                                                  gap: 10,
                                                  justifyContent: 'flex-start',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Average No of Jobs */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'Average No Jobs:'}
                                              </Text>
                                              {/* AverageNoJobs */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['h3 bold'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h3 bold'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  deckSwiperData
                                                    ?._recruiter_of_user
                                                    ?._os_averagenojobs
                                                    ?.average_no_jobs
                                                }
                                              </Text>
                                            </View>
                                            {/* Average TIme to Fill */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flexDirection: 'row',
                                                  gap: 10,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Average Time to Fill */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'Average Time to Fill:'}
                                              </Text>
                                              {/* Average Time to Fill */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['h3 bold'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['h3 bold'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  deckSwiperData
                                                    ?._recruiter_of_user
                                                    ?._os_averagetimetofill
                                                    ?.average_time_to_fill
                                                }
                                              </Text>
                                            </View>
                                            {/* Specialty */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flexDirection: 'row',
                                                  gap: 10,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Specialty */}
                                              <Text
                                                accessible={true}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['Text'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'Specialty:'}
                                              </Text>
                                              {/* sublist */}
                                              <SimpleStyleFlatList
                                                data={
                                                  deckSwiperData
                                                    ?._recruiter_of_user
                                                    ?.os_recruitingspecialty_id
                                                }
                                                horizontal={false}
                                                inverted={false}
                                                keyExtractor={(
                                                  sublistData,
                                                  index
                                                ) =>
                                                  sublistData?.id ??
                                                  sublistData?.uuid ??
                                                  index.toString()
                                                }
                                                keyboardShouldPersistTaps={
                                                  'never'
                                                }
                                                listKey={JSON.stringify(
                                                  deckSwiperData
                                                    ?._recruiter_of_user
                                                    ?.os_recruitingspecialty_id
                                                )}
                                                nestedScrollEnabled={false}
                                                numColumns={1}
                                                onEndReachedThreshold={0.5}
                                                renderItem={({
                                                  item,
                                                  index,
                                                }) => {
                                                  const sublistData = item;
                                                  return (
                                                    <>
                                                      {/* Specilaty */}
                                                      <Text
                                                        accessible={true}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['h3 bold'].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['h3 bold'].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          sublistData?.speciality
                                                        }
                                                      </Text>
                                                    </>
                                                  );
                                                }}
                                                showsHorizontalScrollIndicator={
                                                  true
                                                }
                                                showsVerticalScrollIndicator={
                                                  true
                                                }
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    flexDirection: 'column',
                                                    gap: 0,
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                            </View>
                                          </View>
                                        </View>
                                      )}
                                    </>
                                    {/* MATCH BUTTONS */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flex: 1,
                                          flexDirection: 'row',
                                          gap: 20,
                                          justifyContent: [
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: 'center',
                                            },
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'center',
                                            },
                                          ],
                                          marginTop: 10,
                                          paddingTop: 20,
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* No Buttion */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignContent: 'flex-end',
                                            alignItems: {
                                              minWidth: Breakpoints.Tablet,
                                              value: 'flex-end',
                                            },
                                            alignSelf: {
                                              minWidth: Breakpoints.Tablet,
                                              value: 'flex-end',
                                            },
                                            flex: {
                                              minWidth: Breakpoints.Tablet,
                                              value: 1,
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Pressable No Match */}
                                        <Pressable
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                (
                                                  await XanoJobzConnectApi.createNewUnMatchPOST(
                                                    Constants,
                                                    {
                                                      initator: 2,
                                                      recipient:
                                                        deckSwiperData?.id,
                                                      unmatched_by_initator: false,
                                                      unmatched_by_recipient: true,
                                                    }
                                                  )
                                                )?.json;
                                                setShowWrong(true);
                                                setShow_SwipePile(false);
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                        >
                                          {/* NO ICON */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                alignSelf: 'center',
                                                backgroundColor:
                                                  theme.colors['Error'],
                                                borderRadius: 100,
                                                justifyContent: 'center',
                                                maxHeight: 36,
                                                maxWidth: 36,
                                                minHeight: 36,
                                                minWidth: 36,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* NO MATCH */}
                                            <Icon
                                              size={24}
                                              color={theme.colors['White']}
                                              name={'AntDesign/close'}
                                            />
                                          </View>
                                        </Pressable>
                                      </View>
                                      {/* Yes Button */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            flex: {
                                              minWidth: Breakpoints.Tablet,
                                              value: 1,
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Pressable Yes Match */}
                                        <Pressable
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                (
                                                  await XanoJobzConnectApi.createNewMatchPOST(
                                                    Constants,
                                                    {
                                                      initiator_role:
                                                        Constants[
                                                          'PRIMARY_ROLE'
                                                        ],
                                                      recipient:
                                                        deckSwiperData?.id,
                                                      recipient_role:
                                                        deckSwiperData?.primary_role,
                                                    }
                                                  )
                                                )?.json;
                                                setShowRight(true);
                                                setShow_SwipePile(false);
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                        >
                                          {/* YES ICON */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                backgroundColor:
                                                  theme.colors[
                                                    'Community_Primary'
                                                  ],
                                                borderRadius: 100,
                                                justifyContent: 'center',
                                                maxHeight: 36,
                                                maxWidth: 36,
                                                minHeight: 36,
                                                minWidth: 36,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* MATCH */}
                                            <Icon
                                              size={24}
                                              color={theme.colors['White']}
                                              name={'AntDesign/check'}
                                            />
                                          </View>
                                        </Pressable>
                                      </View>
                                    </View>
                                  </DeckSwiperCard>
                                );
                              }}
                              startCardIndex={0}
                              visibleCardCount={1}
                              {...GlobalStyles.DeckSwiperStyles(theme)[
                                'Deck Swiper'
                              ].props}
                              infiniteSwiping={true}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.DeckSwiperStyles(theme)[
                                  'Deck Swiper'
                                ].style,
                                dimensions.width
                              )}
                              verticalEnabled={false}
                            />
                          </View>
                        </>
                      );
                    }}
                  </XanoJobzConnectApi.FetchGetSwipePlieWithFilterGET>
                </View>
              )}
            </>
            {/* Display Messages */}
            <View
              style={StyleSheet.applyWidth(
                { marginLeft: 20, marginRight: 20, marginTop: 20 },
                dimensions.width
              )}
            >
              {/* MATCH */}
              <>
                {!(showRight === true) ? null : (
                  <View>
                    <>
                      {!(showRight === true) ? null : (
                        <Pressable
                          onPress={() => {
                            try {
                              setShowRight(false);
                              setShow_SwipePile(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Match */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderColor: theme.colors['Primary 4'],
                                borderRadius: 50,
                                borderWidth: 1,
                                flex: 1,
                                flexDirection: 'row',
                                gap: 20,
                                justifyContent: 'center',
                                paddingBottom: 10,
                                paddingTop: 10,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              {...GlobalStyles.IconStyles(theme)[
                                'icon_secondary'
                              ].props}
                              color={theme.colors['Community_Primary']}
                              name={'AntDesign/checkcircle'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.IconStyles(theme)['icon_secondary']
                                  .style,
                                dimensions.width
                              )}
                            />
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)[
                                'success_code_button'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'success_code_button'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'All done! Connection Sent!'}
                            </Text>
                          </View>
                        </Pressable>
                      )}
                    </>
                  </View>
                )}
              </>
              {/* UNMATCH */}
              <>
                {!(showWrong === true) ? null : (
                  <View>
                    {/* Pressable  */}
                    <Pressable
                      onPress={() => {
                        try {
                          setShowWrong(false);
                          setShow_SwipePile(true);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* No Match */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderColor: theme.colors['Primary'],
                            borderRadius: 50,
                            borderWidth: 1,
                            flex: 1,
                            flexDirection: 'row',
                            gap: 20,
                            justifyContent: 'center',
                            paddingBottom: 10,
                            paddingTop: 10,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          {...GlobalStyles.IconStyles(theme)['icon_secondary']
                            .props}
                          color={theme.colors['Error']}
                          name={'AntDesign/closecircle'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.IconStyles(theme)['icon_secondary']
                              .style,
                            dimensions.width
                          )}
                        />
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)[
                            'success_code_button'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)[
                              'success_code_button'
                            ].style,
                            dimensions.width
                          )}
                        >
                          {'All done! Connection Blocked!'}
                        </Text>
                      </View>
                    </Pressable>
                  </View>
                )}
              </>
            </View>
          </View>
        </View>
      </View>
      <NavigationBottomBlock />
    </ScreenContainer>
  );
};

export default withTheme(JobzConnectScreen);
