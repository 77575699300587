import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobzStoryApi from '../apis/XanoJobzStoryApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Pressable,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { Text, View } from 'react-native';

const JobzStoryAddTwoScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [input_reasonForLeaving, setInput_reasonForLeaving] =
    React.useState('');
  const [input_topCompanyContribution, setInput_topCompanyContribution] =
    React.useState('');
  const [input_topJobContribution, setInput_topJobContribution] =
    React.useState('');
  const [input_topTeamContribution, setInput_topTeamContribution] =
    React.useState('');
  const [label_reasonForLeaving, setLabel_reasonForLeaving] = React.useState(
    "What made you want to leave? If you don't provide a reason, the recruiter will simply make up their own and usually it isnt favorable to you. Please remember to be as positive here as you can be."
  );
  const [label_topCompanyContribution, setLabel_topCompanyContribution] =
    React.useState('How did you make the company better?');
  const [label_topJobContribution, setLabel_topJobContribution] =
    React.useState('How did you make the job better?');
  const [label_topTeamContribution, setLabel_topTeamContribution] =
    React.useState('How did you make the team better?');
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer hasSafeArea={false} scrollable={true}>
      {/* SCREEN CONTAINER */}
      <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
        {/* Heading Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Primary 4'],
              justifyContent: 'space-around',
            },
            dimensions.width
          )}
        >
          {/* Heading  */}
          <View
            style={StyleSheet.applyWidth(
              { margin: 20, marginBottom: 20 },
              dimensions.width
            )}
          >
            {/* Heading */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['h2'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['h2'].style, {
                  alignSelf: 'flex-start',
                }),
                dimensions.width
              )}
            >
              {'Finish your jobzStory'}
            </Text>
            {/* description */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['small_text'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['small_text'].style,
                dimensions.width
              )}
            >
              {'This information will set up apart from other candidates.'}
            </Text>
          </View>
        </View>
        {/* Body Container */}
        <View>
          {/* Body */}
          <View
            style={StyleSheet.applyWidth(
              { gap: 10, margin: 20 },
              dimensions.width
            )}
          >
            {/* Top Job Contribution */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['h3 bold'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['h3 bold'].style,
                  dimensions.width
                )}
              >
                {'Top Job Contribution'}
              </Text>
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputValue => {
                  try {
                    setInput_topJobContribution(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={label_topJobContribution.toString()}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    { borderRadius: 18, marginTop: 8 }
                  ),
                  dimensions.width
                )}
                value={input_topJobContribution}
              />
            </View>
            {/* Top Team Contribution */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['h3 bold'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['h3 bold'].style,
                  dimensions.width
                )}
              >
                {'Top Team Contribution'}
              </Text>
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputValue => {
                  try {
                    setInput_topTeamContribution(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={label_topTeamContribution.toString()}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    { borderRadius: 18, marginTop: 8 }
                  ),
                  dimensions.width
                )}
                value={input_topTeamContribution}
              />
            </View>
            {/* Top Company Contribution */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['h3 bold'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['h3 bold'].style,
                  dimensions.width
                )}
              >
                {'Top Company Contribution'}
              </Text>
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputValue => {
                  try {
                    setInput_topCompanyContribution(newTextInputValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                placeholder={label_topCompanyContribution.toString()}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                    { borderRadius: 18, marginTop: 8 }
                  ),
                  dimensions.width
                )}
                value={input_topCompanyContribution}
              />
            </View>
            {/* Reason for Leaving */}
            <View>
              {/* Label */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['h3 bold'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['h3 bold'].style,
                    { marginBottom: 10 }
                  ),
                  dimensions.width
                )}
              >
                {'Reason For Leaving'}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'column' },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  multiline={true}
                  onChangeText={newTextAreaValue => {
                    const textInputValue = newTextAreaValue;
                    try {
                      setTextInputValue(textInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Area'].props}
                  numberOfLines={5}
                  placeholder={label_reasonForLeaving}
                  placeholderTextColor={theme.colors['Primary']}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['Text Area'].style,
                    dimensions.width
                  )}
                  value={textInputValue}
                />
              </View>
            </View>
          </View>
        </View>
        {/* Bottom Button */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'flex-end',
              alignItems: 'center',
              alignSelf: 'center',
              flexDirection: { minWidth: Breakpoints.Desktop, value: 'row' },
              justifyContent: {
                minWidth: Breakpoints.Desktop,
                value: 'center',
              },
              marginBottom: 20,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 20,
              maxWidth: 300,
              minWidth: 300,
            },
            dimensions.width
          )}
        >
          <Pressable
            style={StyleSheet.applyWidth(
              { minWidth: '100%' },
              dimensions.width
            )}
          >
            {/* Button_Next */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    const jobzStoryResult = (
                      await XanoJobzStoryApi.jOBZSTORYUpdateTwoPATCH(
                        Constants,
                        {
                          jobzstory_id:
                            props.route?.params?.jobzStory_id ?? null,
                          reason_for_leaving: input_reasonForLeaving,
                          top_company_contribution:
                            input_topCompanyContribution,
                          top_job_contribution: input_topJobContribution,
                          top_team_contribution: input_topTeamContribution,
                        }
                      )
                    )?.json;
                    navigation.navigate('JobzStorySummaryScreen');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Secondary'],
                  borderRadius: 100,
                  fontFamily: 'Poppins_700Bold',
                  maxWidth: { minWidth: Breakpoints.Laptop, value: 300 },
                  minWidth: { minWidth: Breakpoints.Laptop, value: 300 },
                },
                dimensions.width
              )}
              title={'Next'}
            />
            {/* Button Cancel */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                try {
                  navigation.navigate('JobzStorySummaryScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['button_CLEAR'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ButtonStyles(theme)['button_CLEAR'].style,
                dimensions.width
              )}
              title={'Cancel'}
            />
          </Pressable>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(JobzStoryAddTwoScreen);
