import React from 'react';

// This is used for all drop down menus to select the option set from the backend and display the drop down list.
const getPickerOptions = (data, type) => {
  const options = data.map(item => ({ label: item[type], value: item.id }));
  console.log('pickerOptions', options);
  return options;
};

export default getPickerOptions;
