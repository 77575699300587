import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const getAJobseekerProfileGET = async (
  Constants,
  { userId },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['user_id'] = userId !== undefined ? renderParam(userId) : '';
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:c4CKpG3F/jobseeker/profile${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetAJobseekerProfileGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobseekerGetAJobseekerProfileGET', args],
    () => getAJobseekerProfileGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobseekerGetAJobseekerProfileGETS',
        ]),
    }
  );
};

export const FetchGetAJobseekerProfileGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  userId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAJobseekerProfileGET(
    { userId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetAJobseekerProfile: refetch,
  });
};

export const oNBOARDINGCreateNewJobseekerPOST = async (
  Constants,
  { actively_looking, currently_employed, user_id },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:c4CKpG3F/jobseeker/new`;
  const options = {
    body: JSON.stringify({
      user_id: user_id,
      actively_looking: actively_looking,
      currently_employed: currently_employed,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useONBOARDINGCreateNewJobseekerPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobseekerONBOARDINGCreateNewJobseekerPOST', args],
    () => oNBOARDINGCreateNewJobseekerPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobseekerONBOARDINGCreateNewJobseekerPOSTS',
        ]),
    }
  );
};

export const FetchONBOARDINGCreateNewJobseekerPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  actively_looking,
  currently_employed,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useONBOARDINGCreateNewJobseekerPOST(
    { actively_looking, currently_employed, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchONBOARDINGCreateNewJobseeker: refetch,
  });
};

export const oNBOARDINGJobseekerUpdateOnePATCH = async (
  Constants,
  { desiredSalary, jobSite, jobType, linkedinURL },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:c4CKpG3F/jobseeker/update_one`;
  const options = {
    body: JSON.stringify({
      os_desiredsalary_id: desiredSalary,
      os_jobsite_id: jobSite,
      os_jobtype_id: jobType,
      linkedin_URL: linkedinURL,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useONBOARDINGJobseekerUpdateOnePATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobseekerONBOARDINGJobseekerUpdateOnePATCH', args],
    () => oNBOARDINGJobseekerUpdateOnePATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobseekerONBOARDINGJobseekerUpdateOnePATCHES',
        ]),
    }
  );
};

export const oNBOARDINGUpdateStatusPATCH = async (
  Constants,
  { user_id },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:c4CKpG3F/jobseeker/onboarding_status`;
  const options = {
    body: JSON.stringify({ user_id: user_id, onboarding_status: true }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useONBOARDINGUpdateStatusPATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobseekerONBOARDINGUpdateStatusPATCH', args],
    () => oNBOARDINGUpdateStatusPATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobseekerONBOARDINGUpdateStatusPATCHES',
        ]),
    }
  );
};

export const oNBOARDINGUploadProfilePicturePATCH = async (
  Constants,
  { content },
  handlers = {}
) => {
  const url = `https://xgf1-tqh0-okdv.n7d.xano.io/api:c4CKpG3F/jobseeker/upload_image`;
  const options = {
    body: JSON.stringify({ image_type: 'profile', content: content }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_HEADER'],
      'Content-Type': 'application/json',
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useONBOARDINGUploadProfilePicturePATCH = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoJobseekerONBOARDINGUploadProfilePicturePATCH', args],
    () => oNBOARDINGUploadProfilePicturePATCH(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries([
          'xanoJobseekerONBOARDINGUploadProfilePicturePATCHES',
        ]),
    }
  );
};
