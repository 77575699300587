export default {
  SplashScreen50: require('../assets/images/SplashScreen50.png'),
  SplashScreenWithoutMascot: require('../assets/images/SplashScreenWithoutMascot.png'),
  ElephantLookingRightJobzMavrickFinal: require('../assets/images/ElephantLookingRightJobzMavrickFinal.png'),
  ElephantLookingLeftJobzMaverickFinal: require('../assets/images/ElephantLookingLeftJobzMaverickFinal.png'),
  JobzStory: require('../assets/images/JobzStory.png'),
  JobzZone: require('../assets/images/JobzZone.png'),
  JobzVault: require('../assets/images/JobzVault.png'),
  JobzCafe: require('../assets/images/JobzCafe.png'),
  JobzChat: require('../assets/images/JobzChat.png'),
  JobzConnect: require('../assets/images/JobzConnect.png'),
  JobzCafeRater: require('../assets/images/JobzCafeRater.png'),
  JobzTalk: require('../assets/images/JobzTalk.png'),
  JobzTarget: require('../assets/images/JobzTarget.png'),
  JobzTrust: require('../assets/images/JobzTrust.png'),
  JobzPrep: require('../assets/images/JobzPrep.png'),
  JobzFunnel: require('../assets/images/JobzFunnel.png'),
  MalikSkydsgaardIgkwHPbVk8Unsplash: require('../assets/images/MalikSkydsgaardIgkwHPbVk8Unsplash.jpg'),
  LolaRoseZcHseJqAeEcUnsplash: require('../assets/images/LolaRoseZcHseJqAeEcUnsplash.jpg'),
  User: require('../assets/images/User.png'),
  Like: require('../assets/images/Like.png'),
  Superlike: require('../assets/images/Superlike.png'),
  Dislike: require('../assets/images/Dislike.png'),
  Info: require('../assets/images/Info.png'),
  Down: require('../assets/images/Down.png'),
  Onboarding2: require('../assets/images/Onboarding2.png'),
  Ob2Button: require('../assets/images/Ob2Button.png'),
  Image: require('../assets/images/Image.png'),
  Camera: require('../assets/images/Camera.png'),
  Done: require('../assets/images/Done.png'),
  SplashScreen: require('../assets/images/SplashScreen.png'),
  DraftbitAppLogo: require('../assets/images/DraftbitAppLogo.png'),
  PurpleFavicon: require('../assets/images/PurpleFavicon.png'),
  LogoTransparentInWhite1928x183: require('../assets/images/LogoTransparentInWhite1928x183.png'),
};
