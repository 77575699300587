import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  AccordionGroup,
  IconButton,
  Link,
  Pressable,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { Image, Text, View } from 'react-native';

const ZSideNavBarScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [show_menuContainer, setShow_menuContainer] = React.useState(false);
  const [show_menuOpen, setShow_menuOpen] = React.useState(true);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* Sidebar Menu */}
      <View>
        {/* Menu Icon */}
        <View
          style={StyleSheet.applyWidth(
            { alignSelf: 'flex-end', flex: 1, marginRight: 20, marginTop: 10 },
            dimensions.width
          )}
        >
          {/* Icon Button Menu Open */}
          <>
            {!(show_menuOpen === true) ? null : (
              <IconButton
                onPress={() => {
                  try {
                    setShow_menuContainer(true);
                    setShow_menuOpen(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'MaterialCommunityIcons/menu-open'}
              />
            )}
          </>
          {/* Icon Button Menu Close */}
          <>
            {!(show_menuOpen === false) ? null : (
              <IconButton
                onPress={() => {
                  try {
                    setShow_menuContainer(false);
                    setShow_menuOpen(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                icon={'AntDesign/closesquareo'}
              />
            )}
          </>
        </View>
        {/* MENU CONTAINER */}
        <>
          {!(show_menuContainer === true) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'flex-end',
                  backgroundColor: theme.colors['Primary 2'],
                  height: '100%',
                  maxWidth: 240,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 20, paddingTop: 20 },
                  dimensions.width
                )}
              >
                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate('KitchenJobseekerScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={Images.PurpleFavicon}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        { height: 30, width: 30 }
                      ),
                      dimensions.width
                    )}
                  />
                </Pressable>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { marginLeft: 20, paddingTop: 20 },
                  dimensions.width
                )}
              >
                {/* Accordion_Connect */}
                <AccordionGroup
                  caretSize={24}
                  expanded={false}
                  iconSize={24}
                  {...GlobalStyles.AccordionGroupStyles(theme)['Accordion']
                    .props}
                  caretColor={theme.colors['Primary']}
                  closedColor={theme.colors['Primary']}
                  label={'CONNECT'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 20 },
                      dimensions.width
                    )}
                  >
                    {/* jobzStory Container */}
                    <View>
                      {/* jobzStory */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['menu_item'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['menu_item'].style,
                          dimensions.width
                        )}
                      >
                        {'jobzStory'}
                      </Text>

                      <View>
                        {/* New jobzStory */}
                        <Link
                          accessible={true}
                          onPress={() => {
                            try {
                              navigation.navigate('JobzStoryAddScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.LinkStyles(theme)['Link'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.LinkStyles(theme)['Link'].style,
                            dimensions.width
                          )}
                          title={'Add new jobzStory'}
                        />
                        {/* New Education */}
                        <Link
                          accessible={true}
                          onPress={() => {
                            try {
                              navigation.navigate(
                                'JobzStoryEducationAddScreen'
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.LinkStyles(theme)['Link'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.LinkStyles(theme)['Link'].style,
                            dimensions.width
                          )}
                          title={'Add New Education'}
                        />
                      </View>
                    </View>
                  </View>
                </AccordionGroup>
                {/* Accordion_Chat */}
                <AccordionGroup
                  caretSize={24}
                  expanded={false}
                  iconSize={24}
                  {...GlobalStyles.AccordionGroupStyles(theme)['Accordion']
                    .props}
                  caretColor={theme.colors['Primary']}
                  closedColor={theme.colors['Primary']}
                  label={'CHAT'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 20 },
                      dimensions.width
                    )}
                  >
                    {/* jobzForum Container */}
                    <View>
                      {/* jobzForum */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['menu_item'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['menu_item'].style,
                          dimensions.width
                        )}
                      >
                        {'jobzForum'}
                      </Text>
                    </View>
                  </View>
                </AccordionGroup>
                {/* Accordion_Control */}
                <AccordionGroup
                  caretSize={24}
                  expanded={false}
                  iconSize={24}
                  {...GlobalStyles.AccordionGroupStyles(theme)['Accordion']
                    .props}
                  caretColor={theme.colors['Primary']}
                  closedColor={theme.colors['Primary']}
                  label={'CONTROL'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: 20 },
                      dimensions.width
                    )}
                  >
                    {/* jobzFunnel Container */}
                    <View>
                      {/* jobzFunnel */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['menu_item'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['menu_item'].style,
                            { color: theme.colors['Primary'] }
                          ),
                          dimensions.width
                        )}
                      >
                        {'jobzFunnel'}
                      </Text>
                    </View>
                  </View>
                </AccordionGroup>
              </View>
            </View>
          )}
        </>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ZSideNavBarScreen);
