import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import MascotBlock from '../components/MascotBlock';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { IconButton, ScreenContainer, WebView, withTheme } from '@draftbit/ui';
import { Text, View } from 'react-native';

const JobzMaverickScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* back navigation */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
          },
          dimensions.width
        )}
      >
        {/* Back */}
        <IconButton
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          size={32}
          icon={'AntDesign/arrowleft'}
        />
      </View>
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center' },
          dimensions.width
        )}
      >
        <MascotBlock />
      </View>

      <View
        style={StyleSheet.applyWidth(
          { borderWidth: 0, margin: 20 },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['Text'].style,
            dimensions.width
          )}
        >
          {
            'If you need to chat with us, you can use our chat and it will direct you to the best person.  We usually can get back to you within a short period of time, however this is within regular business hours.\n\nClick on the Chat Bubble to get started.'
          }
        </Text>
      </View>
      <WebView
        allowFileAccessFromFileURLs={false}
        allowUniversalAccessFromFileURLs={false}
        cacheEnabled={true}
        incognito={false}
        javaScriptCanOpenWindowsAutomatically={false}
        javaScriptEnabled={true}
        mediaPlaybackRequiresUserAction={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        startInLoadingState={false}
        source={{
          html: '<script>\n  var o_options = {\n    domain: \'jobzcafe.outseta.com\',\n    load: \'chat\'\n  };\n</script>\n<script src="https://cdn.outseta.com/outseta.min.js"\n        data-options="o_options">\n</script>',
        }}
        style={StyleSheet.applyWidth(
          { borderWidth: 0, minHeight: 480 },
          dimensions.width
        )}
      />
    </ScreenContainer>
  );
};

export default withTheme(JobzMaverickScreen);
