import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoJobseekerApi from '../apis/XanoJobseekerApi.js';
import * as XanoOptionSets$DropdownsApi from '../apis/XanoOptionSets$DropdownsApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import getPickerOptions from '../global-functions/getPickerOptions';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Picker,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, StatusBar, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const Onboarding2JobseekerScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [linkedInURLValue, setLinkedInURLValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      const jobseekerResult = props.route?.params?.jobseeker_ID ?? null;

      const entry = StatusBar.pushStackEntry?.({ barStyle: 'light-content' });
      return () => StatusBar.popStackEntry?.(entry);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
    >
      <View style={StyleSheet.applyWidth({ margin: 20 }, dimensions.width)}>
        {/* Heading Section */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginBottom: 10 },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={Images.ElephantLookingLeftJobzMaverickFinal}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                { marginBottom: 10 }
              ),
              dimensions.width
            )}
          />
        </View>
        {/* Description */}
        <View>
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Text'].style,
              dimensions.width
            )}
          >
            {
              'This information will help us cater the platform more directly to how you want to use it.'
            }
          </Text>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { gap: 10, marginTop: 10 },
            dimensions.width
          )}
        >
          {/* Block Heading */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['h2'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['h2'].style, {
                marginBottom: 10,
              }),
              dimensions.width
            )}
          >
            {'Tell us even a liitle bit more . . .'}
          </Text>
          {/* LinkedIn URL */}
          <View>
            {/* LABEL LinkedIn URL */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['label'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['label'].style,
                dimensions.width
              )}
            >
              {'Your LinkedIn Profile URL'}
            </Text>
            {/* LinkedIn URL */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newLinkedInURLValue => {
                try {
                  setLinkedInURLValue(newLinkedInURLValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Enter a value...'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                  {
                    borderRadius: 100,
                    paddingBottom: 10,
                    paddingLeft: 16,
                    paddingTop: 10,
                  }
                ),
                dimensions.width
              )}
              value={linkedInURLValue}
            />
          </View>
          {/* Dropdown Option Block */}
          <View>
            <XanoOptionSets$DropdownsApi.FetchOPTIONSETGetJobSearchPickerOptionsGET
              handlers={{
                onData: fetchData => {
                  try {
                    const desiredSalaryResult = getPickerOptions(
                      fetchData?.dd_desiredSalary,
                      'desired_salary'
                    );
                    setGlobalVariableValue({
                      key: 'DESIRED_SALARY_OPTIONS',
                      value: desiredSalaryResult,
                    });
                    const jobTypeResult = getPickerOptions(
                      fetchData?.dd_jobType,
                      'job_type'
                    );
                    setGlobalVariableValue({
                      key: 'JOB_TYPE_OPTIONS',
                      value: jobTypeResult,
                    });
                    const jobSiteResult = getPickerOptions(
                      fetchData?.dd_jobSite,
                      'job_site'
                    );
                    setGlobalVariableValue({
                      key: 'JOB_SITE_OPTIONS',
                      value: jobSiteResult,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
            >
              {({
                loading,
                error,
                data,
                refetchOPTIONSETGetJobSearchPickerOptions,
              }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* Desired Salary */}
                    <View>
                      {/* LABEL Desired Salary */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Desired Salary'}
                      </Text>
                      <Picker
                        autoDismissKeyboard={true}
                        dropDownBackgroundColor={theme.colors.background}
                        dropDownBorderColor={theme.colors.divider}
                        dropDownBorderRadius={8}
                        dropDownBorderWidth={1}
                        dropDownTextColor={theme.colors.strong}
                        iconSize={24}
                        leftIconMode={'inset'}
                        onValueChange={newPickerValue => {
                          try {
                            const desiredSalaryResult = setGlobalVariableValue({
                              key: 'PV_DESIRED_SALARY',
                              value: newPickerValue,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        selectedIconColor={theme.colors.strong}
                        selectedIconName={'Feather/check'}
                        selectedIconSize={20}
                        type={'solid'}
                        {...GlobalStyles.PickerStyles(theme)['picker'].props}
                        mode={'dropdown-modal'}
                        options={Constants['DESIRED_SALARY_OPTIONS']}
                        placeholder={'Select an option'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.PickerStyles(theme)['picker'].style,
                          dimensions.width
                        )}
                        value={Constants['PV_DESIRED_SALARY']}
                      />
                    </View>
                    {/* Job Type */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 8 },
                        dimensions.width
                      )}
                    >
                      {/* LABEL Job Type Preference */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Job Type Preference'}
                      </Text>
                      <Picker
                        autoDismissKeyboard={true}
                        dropDownBackgroundColor={theme.colors.background}
                        dropDownBorderColor={theme.colors.divider}
                        dropDownBorderRadius={8}
                        dropDownBorderWidth={1}
                        dropDownTextColor={theme.colors.strong}
                        iconSize={24}
                        leftIconMode={'inset'}
                        onValueChange={newPickerValue => {
                          try {
                            const jobTypeResult = setGlobalVariableValue({
                              key: 'PV_JOB_TYPE',
                              value: newPickerValue,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        selectedIconColor={theme.colors.strong}
                        selectedIconName={'Feather/check'}
                        selectedIconSize={20}
                        type={'solid'}
                        {...GlobalStyles.PickerStyles(theme)['picker'].props}
                        mode={'dropdown-modal'}
                        options={Constants['JOB_TYPE_OPTIONS']}
                        placeholder={'Select an option'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.PickerStyles(theme)['picker'].style,
                          dimensions.width
                        )}
                        value={Constants['PV_JOB_TYPE']}
                      />
                    </View>
                    {/* Job Site */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 8 },
                        dimensions.width
                      )}
                    >
                      {/* LABEL Job Site */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Job SIte Preference\n'}
                      </Text>
                      <Picker
                        autoDismissKeyboard={true}
                        dropDownBackgroundColor={theme.colors.background}
                        dropDownBorderColor={theme.colors.divider}
                        dropDownBorderRadius={8}
                        dropDownBorderWidth={1}
                        dropDownTextColor={theme.colors.strong}
                        iconSize={24}
                        leftIconMode={'inset'}
                        onValueChange={newPickerValue => {
                          try {
                            const jobSiteResult = setGlobalVariableValue({
                              key: 'PV_JOB_SITE',
                              value: newPickerValue,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Select an option'}
                        selectedIconColor={theme.colors.strong}
                        selectedIconName={'Feather/check'}
                        selectedIconSize={20}
                        type={'solid'}
                        {...GlobalStyles.PickerStyles(theme)['picker'].props}
                        mode={'dropdown-modal'}
                        options={Constants['JOB_SITE_OPTIONS']}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.PickerStyles(theme)['picker'].style,
                          dimensions.width
                        )}
                        value={Constants['PV_JOB_SITE']}
                      />
                    </View>
                  </>
                );
              }}
            </XanoOptionSets$DropdownsApi.FetchOPTIONSETGetJobSearchPickerOptionsGET>
          </View>
        </View>
      </View>
      {/* Next Step */}
      <View
        {...GlobalStyles.ViewStyles(theme)['bottomButton'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['bottomButton'].style,
            { alignContent: 'center' }
          ),
          dimensions.width
        )}
      >
        {/* Button_Next */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            try {
              /* hidden 'API Request' action */
              navigation.navigate('Onboarding3JobseekerScreen', {
                jobseekerID: Constants['USER_ID'],
              });
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Secondary'],
              borderRadius: 100,
              fontFamily: 'Poppins_700Bold',
              maxWidth: [
                { minWidth: Breakpoints.Laptop, value: 300 },
                { minWidth: Breakpoints.Mobile, value: 300 },
              ],
              minWidth: [
                { minWidth: Breakpoints.Laptop, value: 300 },
                { minWidth: Breakpoints.Mobile, value: 300 },
              ],
            },
            dimensions.width
          )}
          title={'Next'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(Onboarding2JobseekerScreen);
